Date.prototype.isLeapYear = function() {
    var year = this.getFullYear();
    if((year & 3) != 0) return false;
    return ((year % 100) != 0 || (year % 400) == 0);
};

Date.prototype.getDayOfYear = function() {
    var dayCount = [0, 31, 59, 90, 120, 151, 181, 212, 243, 273, 304, 334];
    var mn = this.getMonth();
    var dn = this.getDate();
    var dayOfYear = dayCount[mn] + dn;
    if(mn > 1 && this.isLeapYear()) dayOfYear++;
    return dayOfYear;
};

// not a very good idea to call localStorage that often
var shouldIgnoreEvent = function(conf) {
//    window.Measurer('shouldIgnoreEvent').start();

    var isCross = localStorage.mode && localStorage.mode === 'cross';
    var chartMovable = conf['chart_touch_movement'] === '"on"' || conf['chart_touch_movement'] === 'on';

//    window.Measurer('shouldIgnoreEvent').stop();

    return !isCross || !chartMovable;
};

var dpr = function(ctx) {
    var dpi = window.devicePixelRatio || 1;
    var backingStoreRatio = 1;

    if(dpi < 1) {
        return 1;
    }

    if(ctx) {
        backingStoreRatio = (
            ctx.webkitBackingStorePixelRatio ||
            ctx.mozBackingStorePixelRatio ||
            ctx.msBackingStorePixelRatio ||
            ctx.oBackingStorePixelRatio ||
            ctx.backingStorePixelRatio || 1
        );
    }

    return dpi / backingStoreRatio;
};

window.dpr = dpr;

// requestAnimationFrame polyfill by Erik Möller. fixes from Paul Irish and Tino Zijdel
(function() {
    var lastTime = 0;
    var vendors = ['ms', 'moz', 'webkit', 'o'];
    for(var x = 0; x < vendors.length && !window.requestAnimationFrame; ++x) {
        window.requestAnimationFrame = window[vendors[x]+'RequestAnimationFrame'];
        window.cancelAnimationFrame = window[vendors[x]+'CancelAnimationFrame']
            || window[vendors[x]+'CancelRequestAnimationFrame'];
    }

    if (!window.requestAnimationFrame)
        window.requestAnimationFrame = function(callback, element) {
            var currTime = new Date().getTime();
            var timeToCall = Math.max(0, 16 - (currTime - lastTime));
            var id = window.setTimeout(function() { callback(currTime + timeToCall); },
                timeToCall);
            lastTime = currTime + timeToCall;
            return id;
        };

    if (!window.cancelAnimationFrame)
        window.cancelAnimationFrame = function(id) {
            clearTimeout(id);
        };
}());

var round3dTime = function(t) {
    if (window.$_3d_format !== "tv") {
        return t - t % 259200;
    }

    // here is an issue: new Date() is using LOCAL time
    var offset = (new Date()).getTimezoneOffset();
    var date = new Date(t * 1000 + offset * 60 * 1000);
    var subDays = (date.getDayOfYear() - 1) % 3;

    // console.log(t, subDays);

    var res = Math.floor((
        t - (subDays * 24 * 60 * 60 * 1000)
    ) / 1000);

    return res - res % 86400;
};

(function() {
    var n = function(n) {
            var t, e, r, o, i;
            for (r = "", e = o = 0, i = n.length; i > o; e = ++o) t = n[e], r += String.fromCharCode(102 ^ t.charCodeAt(0));
            return r
        },
        t = {
            Sf: n("NTVVJTVVJTVVJVHPO"),
            WD: n("	9\n		"),
            JV: n("@"),
            oZ: n("J"),
            Pw: n("E\x00	9"),
            eg: n("E\b	\x00FH\b\b"),
            tf: n("H"),
            hT: n("\n\b"),
            aN: n("3\b\r\b	\bF#	"),
            Ob: n("$"),
            RR: n("F\x00\nH"),
            xN: n("\n\b2	"),
            Pf: n("\n"),
            kF: n("NVJVJWT^JVH^O"),
            FM: n('EV"^P  '),
            vy: n("F	\r"),
            Vi: n(""),
            La: n("5%	\n	"),
            Rm: n("	"),
            sv: n(",\bF F+F'F+F,\bF,\nF'F5F)F(	F\""),
            Iz: n("F"),
            NX: n("1"),
            SC: n("NPRJTSSJPRJVHUO"),
            Uv: n("\x00FF\x00\n"),
            vo: n(" \n\n?"),
            kP: n("32%"),
            jx: n("仙"),
            MA: n("E\n	\b"),
            uz: n("+\b"),
            KV: n("H\n	\\\n"),
            Ay: n("ZXZ\bF\n[D	\n	\\"),
            kV: n("@[Q"),
            jh: n("2	\b)\x00\x00"),
            xw: n("U"),
            dT: n(">%.'(!#"),
            jO: n("\x00\nFTJFEH	FEH\nFEE,5)(H\b\x00NO"),
            US: n('E "^ VV'),
            FF: n("	\b"),
            Pm: n("E"),
            We: n("ZIXZI\nX"),
            VM: n("	\nFWTF"),
            cY: n("BW"),
            Fx: n("FF\b	F\bH"),
            Zi: n("NTSSJTSSJWT^JVH^O"),
            Nn: n("N"),
            vU: n("NTSSJWT^JWT^JVH^O"),
            WN: n("\b6"),
            NO: n(""),
            Ow: n('M"/'),
            mq: n("NW_PJTSSJW_PJVH^O"),
            qo: n("NVJVJVJVH^O"),
            Lq: n('")!#'),
            PU: n("9\x00\n\b"),
            JQ: n("NWT^JVJVJVH^O"),
            JU: n("NVJWT^JWT^JVH^O"),
            Lm: n("'	F2"),
            ks: n("NWT^JVJWT^JVH^O"),
            VQ: n("F\rF\bF"),
            ON: n("E\x00		"),
            Ib: n("\\\x00"),
            YR: n("2?6#JF6)/(25JF)4\"#4JF'>/5JF )4+3*'JF52'235JF> />JF$*)%-9*/+/2JF52#6JF5('6JF2.#+#l6'42/'*JF /(/5.#\"l*/(#'4JF*)!JF$/*)!l*/(#JF0*/(#JF.*/(#JF 0*/(#JF .*/(#JF '4%JF  '(JF .*/(##>JF */(#JF  '(%JF%)*)4"),
            Xf: n("7	N"),
            OC: n("EV^%"),
            yQ: n(""),
            pS: n("WVV"),
            xm: n("\bK	"),
            IP: n("ZXF 	F"),
            bJ: n("ZF\n[	\rX"),
            YJ: n("F\x00\n\nHZIX"),
            Qe: n("%	\b	\nJF+	\b	"),
            vd: n("ZF\n[	\rX*	\bHHHZIX"),
            we: n("F"),
            Sg: n("NTSSJPRJPRJVHTO"),
            ad: n("	\x00\n"),
            Rk: n("\n		"),
            pb: n("	F"),
            AB: n("ZXZX"),
            OJ: n("ZIXZX#\bF"),
            FC: n("'F	F\nFF\bF\bF	ZX"),
            MD: n("!\bF'\n"),
            EC: n(""),
            EX: n("	"),
            Sa: n("\n	\b"),
            rL: n("ZF\n[	\rX?	F	\bAFF\bF	FHZIX"),
            OY: n("W"),
            mS: n("FZ\bF\n[D	\n	\\EPPPDXZ\n\nXN"),
            Eo: n("\x009"),
            qL: n("F\n\x00OZI\n\nXZI\bX"),
            Yp: n("3F	FF	\bH"),
            MI: n("+\b	F'	"),
            Yn: n("Y	\b["),
            GO: n("\r"),
            aC: n("EUP "),
            Yk: n("%\bF"),
            fr: n("FF	H"),
            Yi: n("%\n\rF	\bF\bF	F\x00	FF	\bH"),
            wt: n("*	\bHHH"),
            IM: n('ZIXZX"\bFZIX3\b\nN'),
            Yf: n("FKF"),
            mU: n("⇶F"),
            kd: n("FIF"),
            AH: n("ZIX"),
            cZ: n("	"),
            Yv: n("./52"),
            XZ: n("%	\nF	HF"),
            QD: n("I\x00\x00\nY	\b9\b["),
            JY: n('K"/'),
            vw: n(".	"),
            XV: n("'	%	\b"),
            Xi: n(""),
            XT: n(")\n\n	"),
            mi: n("	"),
            XQ: n("	"),
            zK: n("E $_'__"),
            Ux: n("E#UW'W%"),
            bc: n("H	"),
            OK: n("	\n"),
            OG: n("+'%\""),
            RI: n("H	\r"),
            zd: n("9"),
            eH: n("\x00"),
            MU: n("W"),
            PL: n("\n"),
            De: n("9"),
            Vt: "mousemove touchmove touchstart",
            LT: n("E\b9F\n=\n[;"),
            zy: n("	"),
            jl: n(""),
            BV: n(""),
            PA: n("E"),
            IW: n(""),
            zF: n("11	\r"),
            BZ: n("\b)\x00"),
            Qx: n(""),
            Ru: n(""),
            hU: n("EB\n\\IIE	IE"),
            IE: n("E\b9\b"),
            Xr: n("F\n9\b[D"),
            dL: n("ZIXFZF\n[DF"),
            Xp: n("Z"),
            Tw: n("IX"),
            Ed: n("\n	"),
            QE: n("NM"),
            SQ: n("E%%WRWR"),
            gM: n("E\b9F\n=\n[\n	\n;"),
            TY: n("\nFFF"),
            "if": n("\n"),
            Wz: n("5\n\nFZ\bF\n[\n\n	X"),
            YG: n("E  V"),
            mJ: n(""),
            Xd: n("B	\b\x00"),
            Xc: n("	\b\x00\\"),
            sZ: n("H9"),
            Di: n("%	FF\b\n	\rH"),
            oH: n("\b"),
            WY: n("	\n9\b"),
            RH: n("ZXZX"),
            Np: n("$	"),
            Bu: n("E\r9"),
            Qf: n("'\nJF\b"),
            XM: n("	"),
            NN: n("E\r"),
            Qm: n("\r9\n"),
            Ta: n("C"),
            WP: n("\n\n"),
            Qy: n("9	"),
            Li: n("DX"),
            DU: n("H	\b\b9\n	\b"),
            WL: n("Z\nX"),
            ko: n("ZI\nX"),
            Tu: n("\n"),
            WI: n("ZIX"),
            WH: n("WTF"),
            bO: n("NTSSJTSSJVJVH^O"),
            Id: n("	\b\b"),
            rq: n("\nF"),
            Ac: n("ZI\bXF"),
            kH: n("F\n\nF	FZ\bF\n[\bX"),
            tX: n("F\bFF\n\nFFZ\bF\n[\bX"),
            TL: n("	\b\b"),
            vE: n(""),
            UW: n(""),
            Mh: n("\r"),
            bw: n("E\n99"),
            QL: n("Z\bF\n[XKZI\bX"),
            Wu: n("Z\bF\n[XMZI\bX"),
            Om: n(")$0"),
            BH: n("H	\b\b9FH	\b9\b"),
            Oz: n("9"),
            pG: n("F	F"),
            pB: n("H	\n9"),
            Rj: n("%.'(!#\\F"),
            Ro: n("	9	9\n"),
            Nf: n("9\b"),
            Rd: n(""),
            Rx: n("\r"),
            vY: n("\b	\b"),
            Bt: n("+	\b"),
            WB: n("ZX"),
            sp: n("	\b"),
            Wf: n("Z\nXZX)  5#2\\F"),
            QN: n("H	\b\b9"),
            zA: n("\n"),
            hj: n("\b\n9\r9\n"),
            uO: n("\n\x00"),
            uT: n(")\\F"),
            ty: n(""),
            Ma: n(".F74F%	"),
            Yr: n("F"),
            ow: n('E" ^\'" '),
            WO: n("%\\F"),
            bT: n("F'	"),
            OX: n("T"),
            RP: n("H\n"),
            VR: n("9\b"),
            PX: n("9\n\b"),
            TA: n("\n		9\b\n9\\	"),
            VO: n("\x00F\nF"),
            VN: n("\b"),
            la: n("Z\nF\n[WX"),
            tL: n("\b9"),
            Ry: n(""),
            Ui: n("\r\\F"),
            VI: n("0	\nFF\nF\x00	F%	\bH"),
            CU: n("F	\bAF	\b\bFI\rF\b\x00	H"),
            Rq: n("\b=\b[	9	;"),
            Tg: n("\b9\r"),
            Ff: n(""),
            nr: n("WVF%	\b	\nJF+	\b	"),
            Xl: n("\\F"),
            hK: n("NTSSJTSSJTSSJVH^O"),
            VA: n("ZF\n[X"),
            LN: n("Å"),
            uy: n("ZF\n[X*	\bHHHZIX"),
            rZ: n(""),
            dV: n("ZF\n[	X*	F	F\x00\nXZIX"),
            Pz: n("E	"),
            Fm: n("E9	9"),
            SG: n("IZI\bX"),
            Vs: "/ticker",
            UO: n("E"),
            Ku: n("'\">4"),
            FR: n("4F \n\n"),
            xU: n("F"),
            qQ: n("	\n	"),
            xH: n("F	\b"),
            AA: n("ZI\bXZIX"),
            Ug: n("9"),
            Vj: n("F\x00\nF"),
            WE: n("$FZ\bF\n[\n\n	X"),
            fX: n("E\n	99"),
            Vg: n("I	"),
            ZD: n('"'),
            Ve: n("\b=\b["),
            GL: n(";"),
            Wy: n("F\n\nFFZ\bF\n[X"),
            mr: n("\b	\\"),
            Va: n("ZXB@ZIX"),
            bF: n("0\\F"),
            TI: n("99"),
            fO: n("ZIX"),
            Bf: n(""),
            RM: n("ZI\nX"),
            oj: n(""),
            di: n("	"),
            XO: n("FKF$	\b1	"),
            UR: n("ZX"),
            AG: n("\b\\F"),
            vL: n("'+6*/23\"#\\F"),
            hw: n("E"),
            Qq: n("%B"),
            Ze: n("NTSSJW_PJW_PJVH^O"),
            XP: n("U"),
            fj: n("9"),
            Wi: n("*# 2+)52"),
            ht: n(""),
            FV: n("		\b"),
            RT: n("5.\b"),
            WK: n("9	9"),
            UE: n("E\n9"),
            UD: n("H\n\b\r9"),
            Vm: n("\n	F"),
            XN: n("E	9"),
            RA: n("\n	\b"),
            Jq: n("F\x00FSF	\b"),
            Oi: n("HZX"),
            jF: n("EUUU"),
            er: n("@["),
            Cx: n("!#2"),
            BC: n("!\bF \n\n"),
            Ut: n("F\n"),
            Us: n("F	F"),
            Vz: n("\b\b"),
            Uq: n("\x00\nJF"),
            Up: n("\x00	\bF"),
            dO: n("5/!"),
            LM: n("Ã"),
            my: n("ZIXFZXZIXZIX"),
            ZI: n(""),
            Uk: n("Y	\n["),
            Uj: n("\\F"),
            Tn: n(""),
            Ym: n("@\n\n[$	\b1	"),
            rC: n("KKKKKK"),
            wi: n("FF\n\b\\F"),
            Xu: n("ZF\n[D\b\\PDXlZX5	JF	F	F	\bAF	F$	\b1	HZIXlZ\nXloZX+\bF\b\\ZIXloZX/#F_MJF%	JF \x00	F	F	F	\bF	HZIXloZX4	\bF\b\\ZIXloZX/#FWVMJF%	JF \x00	F	F	F	\bF	HZIXloZX4\r\\ZIXloZX/#F_F	\bAF	F4\nF	ZIXlZIX"),
            nU: n("	FF\n\b\\F"),
            ur: n("FF\n\bF"),
            TD: n("H"),
            FK: n("FF\n\bF"),
            YE: n("ZIX2\\FZF\x00[II\n	\r\bH\b\x00	II"),
            Zr: n("KKF52'235FKK"),
            UC: n("\\\x00"),
            TV: n("E\n99"),
            SK: n("NTSSJTSSJTSSJVHRO"),
            TT: n("E\n9\n	9"),
            TS: n(""),
            Hp: n("E ^V"),
            oS: n("4"),
            Bk: n("Y\n\n[$	\b1	"),
            TO: n(""),
            FH: n("'\">"),
            TM: n("\\"),
            so: n("H	F\n=[	;"),
            wg: n("F⇴"),
            ZJ: n("		\bK	"),
            Lk: n("H		\bK"),
            kC: n("\r9\b"),
            ZF: n("E\n	9"),
            QP: n("F	"),
            Gg: n("	\f"),
            Nj: n("5\rF*\b"),
            PQ: n("4F5	\r"),
            Ye: n("FC"),
            Wm: n(""),
            nG: "mousedown touchstart",
            Zs: n("\n\b"),
            Vl: n("\b"),
            Nq: n("%	"),
            ED: n("V"),
            vG: n("9	"),
            kn: n("E\n99\b"),
            Za: n("$\r	\bF+\r"),
            bS: n("\b"),
            MK: n("!\b"),
            fK: n("	"),
            UN: n("	JF	FFF"),
            Iu: n("EVVV"),
            OO: n("	\b"),
            Zq: n(""),
            No: n(""),
            cy: n("99"),
            nH: n("\b9"),
            Th: n("乡"),
            Gl: n("*	F\n\n	FF\nF\x00\nH"),
            Tf: n("$"),
            Te: n("\b9"),
            Oy: n("FH\n"),
            Fg: n("-"),
            ho: n(""),
            uI: n("NTSSJTSSJVJWO"),
            XS: n("EVVVVVV"),
            XR: n("II"),
            ZV: n("F\f	\bF\x00\n"),
            VB: n("%	\n	"),
            ot: n("\b"),
            Vn: n("\n"),
            Dp: n("E PU"),
            SS: n("ER_%VRU"),
            Sy: n("'F2"),
            kf: n("CF"),
            Gp: n("E9\n"),
            SO: n("E      "),
            yz: n("/6F"),
            RF: n("NVJTVRJVJVHPO"),
            SL: n("NTVRJVJVJVHPO"),
            tT: n("\n"),
            Re: n("ZF\n[	\rX*		F\x00\nHZIX"),
            Ns: n("E'P%##U"),
            hy: n("Y"),
            Ja: n("WVF'\nJF5\b"),
            pj: n("EW Q^$R"),
            gb: n("E$T\" ^'"),
            Mm: n("EPUU"),
            Qb: n("	\b"),
            qI: n("NTSSJPRJPRJVHUO"),
            UB: n(""),
            Sz: n("NTSSJTSSJTSSJVHPO"),
            Mg: n("\x00\b	\b"),
            ka: n('")1('),
            Dk: n("NVJTVRJVJVH^O"),
            Mx: n("	\n"),
            Su: n("NTSSJFTSSJFTSSJFVH^O"),
            Lg: n("5\n\n"),
            Qj: n("\x00	\b"),
            uo: n("E\b"),
            Yd: n("EUU'VT%"),
            pf: n("F\bFF\n\nFFZ\bF\n[X"),
            Hj: n("I"),
            Ak: n("	99"),
            cU: n("9	\b"),
            cj: n("4\n"),
            cS: n("H\n\b\r9	F\b"),
            SB: n("EVV_S#U"),
            bh: n("	"),
            HB: n("H\n	"),
            HS: n("BW"),
            LS: n("ZF\n[\x00K	KX"),
            am: n("H		\b"),
            sC: n("NVJVJTSSJVH^O"),
            hM: n("NTSSJWT^JVJWO"),
            Uu: n("IY	\n["),
            Fl: n("+\bF2"),
            PG: n("E9	"),
            tK: n("E\n	9\x00\x00\n"),
            RX: n("E\n	9"),
            XY: n("FF\b	F	H"),
            XF: n("WW"),
            FL: n("5"),
            ZH: n("NSWJWPVJRRJVH^O"),
            RS: n("6)52"),
            rF: n(".\\F"),
            nt: n("9\x00\x00\b"),
            Qa: n("\r"),
            TQ: n("NE	\bO"),
            RN: "mouseup touchend",
            HX: n("	\b\n"),
            RL: n("\n"),
            mM: n("!\b"),
            kY: n("H"),
            GX: n("I"),
            OM: n("\b"),
            Ml: n("	\b"),
            Px: n("E"),
            RE: n("\b\\\n\\\x00"),
            mu: n("\\\n\\"),
            RC: n(""),
            su: n("	\b"),
            NG: n(""),
            aH: n(""),
            Yx: n("ZIXZIX"),
            sm: n("9	\n"),
            an: n("\x00	"),
            Rv: n("\b=[;"),
            YU: n("5'4"),
            Dr: n("F	\b\\F"),
            Rs: n("4"),
            Sl: n("NVJVJVJVHRO"),
            ZT: n('"'),
            iM: n("M"),
            DT: n("S"),
            Rn: n(""),
            Tt: n(""),
            Av: n("	"),
            WS: n("\n\b9\n"),
            Ue: n("JF\x00FF"),
            AL: n("F	F"),
            Md: n("H	\b\b9	"),
            hR: n("9	"),
            QH: n(""),
            UV: n("	\b"),
            OQ: n(""),
            Rc: n("9"),
            Ww: n("2FFF	\x00F	F\bFFFZ\bF\n[X"),
            EJ: n("\nF"),
            Wt: n("WTF 	\b'	"),
            QO: n("F	\r"),
            sN: n("H"),
            uj: n("E\n9\n	9"),
            th: n("Z\bF\n[X"),
            QU: n(""),
            ef: n("H	\b\b9FH\n9\b"),
            Pn: n("E\rF"),
            Sm: n("NTTQJTPJT^JVH^O"),
            QQ: n("\n"),
            tz: n("\x00\n\n"),
            QR: n("E%%%%VV"),
            hX: n("#F	\bF"),
            Ls: n("	"),
            Me: n("H	\b\x00"),
            Wn: n("F	"),
            Zf: n("NWT^JWT^JTSSJVH^O"),
            QI: n("\b=[	;"),
            hG: n("\x00	"),
            BA: n("E"),
            cG: n("H	\b\b9	\b"),
            yT: n("\b9\n"),
            lI: n("\"'2#\\F"),
            dE: n("	\b99\n\n"),
            Mb: n("\\1"),
            HM: n("\b	)\b"),
            Rg: n("\nF"),
            Hc: n("H	\b\b9\n		"),
            Ll: n("E			\rFH			\r"),
            LL: n("'B"),
            Qv: n("ZF\n[	X"),
            hm: n("	9\n"),
            WV: n("(F\n	\bF\x00H"),
            OZ: "Welcome to BitcoinWisdom.io",
            Qr: n("9\b"),
            hI: n(""),
            QY: n("H"),
            hQ: n("F\\F"),
            Qn: n(""),
            OT: n("๙"),
            NM: n("H	\b9\b"),
            Pa: n("WS"),
            Sw: n("E__V V "),
            QX: n(""),
            Qh: n("\x00\n"),
            fD: n("\b"),
            Pg: n("T"),
            ih: n("H"),
            cX: n("H\b\b"),
            Du: n("F32%"),
            VY: n("\b9\n\b"),
            NS: n(""),
            zN: n("E\n	\bFH\b\b"),
            co: n(""),
            GC: n("45/"),
            MS: n("\b\n9\r"),
            Ky: n(""),
            Tm: n(""),
            MF: n(".\nF2"),
            Ok: n("$/\"4'2/)"),
            km: n("9\x00\x00\b"),
            NV: n("1"),
            ma: n("H\x00	"),
            PO: n("!\bF5	\r"),
            UA: n("=	["),
            PM: n("\r"),
            Ra: n(""),
            oI: n("\n"),
            BO: n("	\b"),
            YN: n("NWT^JWT^JVJVH^O"),
            PH: n("E9	"),
            VL: n("F"),
            Zd: n("NW_PJW_PJTSSJVH^O"),
            PE: n("E\n\x009	"),
            Tl: n("*	\b"),
            oE: n("9\b9	"),
            ep: n("E"),
            OV: n("E\b9"),
            Yo: n("	\b\\"),
            VP: n("F\rF	F"),
            zs: n("9	"),
            SH: n('E "$ P '),
            DB: n("NWVJWVJWVJVH^O"),
            gB: n("F"),
            Tp: n("F	"),
            Ps: n("E\r"),
            Pr: n("E9\b\x00	"),
            oT: n("E\b	9\b\x00	"),
            Pp: n("E\b9\b	9\b\x00	"),
            tr: n("EV'V'V'"),
            PZ: n("\nF	"),
            aE: n("EF"),
            Pl: n("E\rF"),
            RJ: n("EF"),
            LB: n("EP%P"),
            TP: n("?	FAFFFB9\x00F	\bF\x00\x00\bF\x00	FHl2FF\n\nFFF	F\x00\n\nF\bFF\b\n\rH"),
            Nt: n("E\b9"),
            SX: n("E%%%"),
            ZP: n("	2	"),
            Pe: n("#"),
            LC: n("	\n	"),
            Qc: n("U"),
            PV: n("9\n\b"),
            To: n("F	"),
            MP: n("!\bF'"),
            fC: n("NVJVJVJVHPO"),
            Uc: n("\nF\\F"),
            ab: n("R"),
            jj: n("9\x00\n\b"),
            Oj: n("WT"),
            SA: n("NTVRJTVRJTVRJVHPO"),
            XW: n("$\n	"),
            gC: n("99\n"),
            Pu: "/api/user/" + window.$user_id + "/",
            eZ: n("	\n99\n"),
            Wc: n("%	FF\n	\rH"),
            OD: n('+/""*#'),
            OL: n("36"),
            Pt: n("H\n\b\r9\n		"),
            SF: n("E\x00		9	"),
            KG: n("E	9"),
            QZ: n("H"),
            jW: n("W"),
            xt: n("F\x00\n\nH"),
            Mk: n(""),
            Fi: n("F[9\n\b\rX"),
            lB: n("\b"),
            CK: n("52)%.45/"),
            OA: n("+'"),
            kI: n("\\"),
            cQ: n("5	\b"),
            wR: n("9"),
            Ri: n("	\n"),
            Zh: n("NTSSJWT^JTSSJVH^O"),
            UL: n("H\n\b\r99\b\\\x00"),
            Yb: n("IY["),
            NJ: n("E\r"),
            zj: n("$N"),
            ir: n(","),
            UP: n("			\r9	\b9\b"),
            Oo: n("%+ "),
            LO: n("	9"),
            Zl: "$1<g>$2</g>",
            Dl: n("9	9"),
            DS: n("UV"),
            KJ: n("E\b"),
            gg: n(""),
            aU: n("H"),
            ni: n("$2%"),
            Of: n("H	F\n=[;"),
            ou: n("l"),
            DJ: n("5'2)5./"),
            GG: "Coinbase",
            br: n("K"),
            ZE: n("+H!	"),
            Hn: n("\\1"),
            Td: n("+"),
            VV: n(""),
            ud: n(""),
            dZ: n("FF"),
            Bm: n(""),
            NT: n(""),
            Lu: n("P"),
            Xo: n("E\b9	"),
            RK: n("H	"),
            VT: n("\b	"),
            MW: n("\x00"),
            UZ: n("H	\b\b9	"),
            YT: n("\\II"),
            NL: n("E\r"),
            Zv: n(""),
            oR: n("	\b9\b\x00	"),
            oU: n(""),
            bN: n("E\b"),
            ZC: n("?"),
            Xs: n("EB\n\\IIE9	IE"),
            EA: n("\\\n\\\n	"),
            MV: n("\b99\n\n"),
            Xa: n("D"),
            bQ: n("\b\x00\b"),
            UM: n("ZF\n[	XZ\bF\n[XZI\bXFZ\bF\n[	\bXZI\bXZIX"),
            Nz: n("\b9\n"),
            rK: n("F	FF\x00	FF\x00	F"),
            Nx: n("E\b9\b"),
            Nw: n("E\b9\n"),
            PB: n("NPRJTSSJPRJVHTO"),
            YC: n("HHHZIX"),
            MM: n(""),
            SJ: n("F\rF\n\bF"),
            wV: n("/\b\nF \n\n5\bF5"),
            ZN: n("E\n9\n"),
            se: n(";F"),
            NF: n(""),
            vs: n("		"),
            YF: n("⇾"),
            gE: n("⇱"),
            YQ: n("⇴"),
            VW: n('"'),
            XB: n("\n		\b"),
            Et: n("ZX4\nF5F	\b\nF	\rF	\bF/#FWVMJF	JF  ZIX"),
            JG: n(""),
            hr: n("ZIX"),
            em: n("	\r"),
            NR: n("	\b\x00"),
            TX: n(""),
            SW: n("$\r	\b"),
            CQ: n("	9"),
            qx: n("\r\f"),
            tE: n("			\r9	\b"),
            MX: n(""),
            LY: n(""),
            TK: n("*	F\x00\nH"),
            CM: n("+\b	F2"),
            CA: n("E\b9	"),
            TZ: n("O"),
            PT: n("9\x00\n\b"),
            jk: n(").*%"),
            NP: n("\n	\r"),
            ut: n("4F'"),
            Oe: n(""),
            EU: n("JF"),
            Fr: n("99\b"),
            cg: n("NVJWT^JVJVH^O"),
            MJ: n("4"),
            ch: n("5\bF+	\bF2F1F2F F5"),
            jg: n(""),
            sb: n("\b"),
            TC: n("\\"),
            iG: n("(	"),
            ZM: n("\n\b1"),
            VX: n("4F'\n"),
            KU: n("\r"),
            GS: n("E\n9\n\n"),
            ag: n("H	\b9"),
            HF: n("E\b	\x00"),
            YM: n("H	\b\b9"),
            mY: n(""),
            DA: n("IY	\n["),
            Mu: n("\r9"),
            Os: n("\r\f9"),
            wz: n(""),
            IZ: n(""),
            fJ: n("\b"),
            fG: n("\r	\bK	\n	"),
            Ss: n("EUPU"),
            Py: n("E"),
            Lv: n("	"),
            lU: n("'F$\r	\b"),
            Nu: n("\n\b9	"),
            BU: n("\b	\n"),
            LA: n("E PP"),
            UX: "<div class=text>Load failed, retry after 5 seconds.</div><dl class=\"reason\"><dt>Probable Reason</dt><dd>BitcoinWisdom is temporary down</dd><dd>Domain is not bitcoinwisdom.io</dd><dd>Network issue</dd><dl>",
            Xg: n("	"),
            vn: n("\n"),
            SR: n("E9"),
            Po: n("E\n99\bFH	\b"),
            Vk: n("E	9"),
            cr: n("5	F74F%	"),
            Mi: n("		"),
            Sv: n("NTVRJVJVJVH^O"),
            UI: n("="),
            kX: n("	"),
            Vc: n("\b"),
            QM: n("9"),
            gs: n("E\b	9"),
            Gi: n("ZF\n[X"),
            oB: n("\b"),
            qf: n("ZF\n[\x00K	K	\bX"),
            at: n("9\b"),
            cx: n("B"),
            Ho: n("⃊"),
            hY: n("E\b"),
            wj: n("E\r"),
            Qd: n("E\b	"),
            Rf: n("E"),
            WJ: n("ZF\n[\b\bX"),
            LI: n("\b"),
            Rt: n("\x00"),
            TB: n("\f	\b"),
            PY: n(""),
            LE: n("\r"),
            MO: n("E\b9\n	\b"),
            UT: n("ZIXFZF\n[X"),
            CN: n("\b9"),
            UH: n("E___"),
            Wq: n("*\\F"),
            Ly: n("H"),
            av: n("H	\b"),
            dN: n("ZXB@ZIX"),
            wr: n("I\fI\b\nH\f"),
            PI: n("E\b"),
            Uh: n("E\n	9"),
            PN: n("H\b\\\x00"),
            lw: n("H\b"),
            ju: n("NVJFVJFVJFVH^O"),
            Lp: n("H	9	"),
            cR: n("H	"),
            Ln: n("%	"),
            VJ: n("⇶"),
            Nm: n("EPPP"),
            eU: n("H	"),
            Lj: n("$"),
            im: n("H	9"),
            Lh: n("H\x00	9"),
            ku: n("EU_'VUU"),
            ob: n("5\b"),
            Le: n("2	4"),
            MC: n("	"),
            Um: n("/\b\nF4\nKF5"),
            Lb: n("	F	\x00F			\r"),
            VZ: n("H	9"),
            pv: n("\b\\\x00"),
            YV: n("H	"),
            YL: n("	9"),
            UQ: n("\r	\b"),
            uB: n("	\b9	9"),
            xs: n("ZX"),
            bM: n('"'),
            MN: n("9"),
            KR: n("@[W"),
            Nd: n("\n	"),
            qF: n(""),
            KO: n(" \n\n5\b"),
            LG: n(""),
            Ao: n(""),
            KL: n(""),
            KK: n("E\n9	"),
            vS: n('/\b\nF"F"'),
            Kl: n("\n"),
            Uw: n("+'C"),
            Lz: n("\x00\b"),
            KF: n("E	\b\b	\b"),
            KE: n("E\n9	\b\b	\b")
        };
    (function() {
        var performanceSettings = {
            simplified_orderbook_hints: false,
            enable_tradelog_animation: true,
            enable_orderbook_animation: true,
            enable_orderbook_color_animation: true,
            immediate_execution: false,
            sharp_lines: true,
        };
        var n, e, r, o, i, u, l, a, s, c, f, h, d, p, v, b, g, m, F, x, y, w, T, V, k, S, M, _, I, X, Z, J, P, H, B, O, R, E, N, L, U, W, C, Q, A, Y, D, K, z, j, G, q, nt, tt, et, rt, ot, it, ut, lt, at, st, ct, ft, ht, dt, pt, vt, bt, gt, mt, Ft, xt, yt, $t, wt, Tt, Vt, kt, St, Mt, _t, It, Xt, Zt, Jt, Pt, Ht, Bt, Ot, Rt, Et, Nt, Lt, Ut, Wt, Ct, Qt, At, Yt, Dt, Kt, zt, jt, Gt, qt, ne, te, ee, re, oe, ie, ue, le, ae, se, ce, fe, he, de, pe, ve, be, ge, me, Fe, xe, ye, $e, we, Te, Ve, ke, Se, Me, _e, Ie, Xe, Ze, Je, Pe, He, Be, Oe, Re, Ee, Ne, Le, Ue, We, Ce, Qe, Ae, Ye, De, Ke, ze, je, Ge, qe, nr, tr, er, rr, or, ir, ur, lr, ar, sr, cr, fr, hr, dr, pr, vr, br, gr, mr, Fr, xr, yr, $r, wr, Tr, Vr, kr, Sr, Mr, _r, Ir, Xr, Zr, Jr, Pr, Hr, Br, Or, Rr, Er, Nr, Lr, Ur, Wr, Cr, Qr, Ar, Yr, Dr, Kr, zr, jr, Gr, qr, no, to, eo, ro, oo, io, uo, lo, ao = [].slice,
            so = {}.hasOwnProperty,
            co = [].indexOf || function(n) {
                for (var t = 0, e = this.length; e > t; t++)
                    if (t in this && this[t] === n) return t;
                return -1
            };
        var calcPrecision;
        ! function() {
            var n, e, r, o, i;

            $(function() {
                function resize() {
                    var u = $(this);
                    var e = $(".dropdown-data", this);

                    if (e.width() < u.width()) {
                        (u.width() - e.width()) / 2;
                        e.css('min-width', u.width());
                    }

                    var l = 0.5 * (u.outerWidth() - e.outerWidth());
                    var i = u.offset().left + u.outerWidth() - $(window).width();

                    if (i > l) {
                        l = i;
                    }

                    var o = u.offset().left + u.outerWidth() - e.outerWidth();
                    if (l > o) {
                        l = o;
                    }

                    e.css("right", l);
                }

                function u() {
                    var u = $(this);

                    u.addClass("dropdown-hover");
                    resize.call(this);

                    return (n = this);
                }

                function l() {
                    $(this).removeClass("dropdown-hover");

                    return (n = null);
                }

                function a() {
                    var t = this;
                    e = true;

                    if (n) {
                        l.call(n);
                        u.call(this);

                        return;
                    } else if (this.showing) {
                        return;
                    } else {
                        this.showing = true;

                        return setTimeout(function() {
                            if (e) {
                                if (n) {
                                    l.call(n);
                                }

                                u.call(t);
                            }

                            return (t.showing = false);
                        }, 80);
                    }
                }

                function s() {
                    var n = this;
                    e = false;

                    if (this.hiding) {
                        return;
                    } else {
                        this.hiding = true;

                        return setTimeout(function() {
                            if (!e) {
                                l.call(n);
                            }

                            return (n.hiding = false);
                        }, 80);
                    }
                }

                e = false;
                n = null;

                var h = $('.dropdown');
                var c = 0;

                for (var f = h.length; f > c; c++) {
                    r = h[c];

                    o = function() {
                        var n = this;

                        $('.t input', this).keyup(function(evt) {
                            return u.call(n);
                        });

                        $('.t textarea', this).keyup(function(evt) {
                            return u.call(n);
                        });

                        return $('.t', this).click(function(evt) {
                            if (
                                !window.$is_mobile &&
                                (evt.target.tagName.toLowerCase() === 'input' ||
                                    evt.target.tagName.toLowerCase() === 'textarea')
                            ) {
                                return;
                            }

                            if ($('.dropdown-data', n).is(':visible')) {
                                return l.call(n);
                            } else {
                                return u.call(n);
                            }
                        });
                    };

                    var forceResize = function() {
                        return $(this).on('forceResize', function() {
                            resize.call(this);
                        });
                    };

                    o.call(r);
                    forceResize.call(r);
                }

                window.$is_mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                    navigator.userAgent
                );

                if (window.$is_mobile) {
                    return;
                } else {
                    return $('.dropdown').hover(
                        function() {
                            return a.call(this);
                        },
                        function() {
                            return s.call(this);
                        }
                    );
                }
            });
        }(),
        Jr = ye = Kr = me = Xr = Vt = ue = Lr = wr = m = rr = Zr = h = F = pt = Tr = je = Ge = qe = kr = null,
            // Ajax init
            function() {
                var n, e;
                return me = function(n, t) {
                    var e, r, o;
                    return o = !1, r = n, e = function() {
                        function e() {
                            r > 0 ? Kr(16, function() {
                                e(r -= 16)
                            }) : i()
                        }

                        function i() {
                            return t(), o = !1
                        }

                        return r = n, o ? !0 : (o = !0, e(), void 0)
                    }
                },
                me.statuses = {},
                Jr = function() {
                    return console.log.apply(console, arguments)
                },
                ye = function() {
                    return console.log.apply(console, [new Date].concat(ao.call(arguments)))
                },
                Kr = function(n, t) {
                    return setTimeout(t, n)
                },
                Vt = function() {
                    var n;
                    var i;

                    var r = arguments[0];
                    var o =
                        3 <= arguments.length
                            ? ao.call(arguments, 1, (i = arguments.length - 1))
                            : ((i = 1), []);

                    var e = arguments[i++];
                    var a = [];
                    var u = 0;

                    for (var l = e.length; l > u; u++) {
                        n = e[u];

                        if (typeof n === "object" && n.length) {
                            a.push(r.apply(null, ao.call(o).concat(ao.call(n))));
                        } else {
                            a.push(r.apply(null, ao.call(o).concat([n])));
                        }
                    }

                    return a;
                },
                Lr = function(n, e) {
                    if (e == null) {
                        e = 'world';
                    }

                    if (typeof console !== t.bQ && console !== null ? console.time : void 0) {
                        console.time(e);
                        n();
                        return console.timeEnd(e);
                    } else {
                        return n();
                    }
                },
                wr = function(n) {
                    return n[n.length - 1]
                },
                Tr = function(n) {
                    var t;
                    return null != (t = wr(n)) ? t : {}
                },
                ue = function(n) {
                    var t;
                    var r;

                    if (n.length) {
                        return n.slice(0);
                    }

                    var e = {};

                    for (t in n) {
                        if (n.hasOwnProperty(t)) {
                            r = n[t];
                            e[t] = r;
                        }
                    }

                    return e;
                },
                n = 0,
                m = function(t) {
                    var e, r;
                    var o = [];

                    e = r = 0;

                    for (; t >= 0 ? t > r : r > t; t >= 0 ? ++r : --r) {
                        o.push(n++);
                    }

                    return o;
                },
                rr = function() {
                    var o, request;

                    var n = 1 <= arguments.length ? ao.call(arguments, 0) : [];
                    var cb = n.pop();

                    var i = n[0];
                    var r = n[1];

                    if (r == null) {
                        r = {};
                    }

                    if (i[i.length - 1] !== "?" && !r.hasOwnProperty('nonce')) {
                        r.nonce = window.getNonce();
                    }

                    if (typeof XDomainRequest !== t.bQ && XDomainRequest !== null) {
                        i = i.indexOf("?") === -1 ? i + "?" + $.param(r) : i + t.JV + $.param(r);
                        request = new XDomainRequest();

                        request.open("GET", i);

                        request.onload = function() {
                            r = $.parseJSON(request.responseText);

                            if (r) {
                                return cb(null, r);
                            } else {
                                return cb(new Error("parse json failed"), null);
                            }
                        };

                        request.onerror = function() {
                            return cb("error", null);
                        };

                        request.ontimeout = function() {};
                        request.onprogress = function() {};
                        request.timeout = 60000;
                        request.send();

                        return request;
                    } else {
                        o = $.ajax({ url: i, type: "GET", dataType: "json", timeout: 60000, data: r });

                        o.done(function(n) {
                            return cb(null, n);
                        });

                        return o.fail(function(n, r, o) {
                            if (r === "error") {
                                r = "";
                            }
                            var i = o || r || "";
                            return cb(new Error(i), null);
                        });
                    }
                },
                Zr = function(n) {
                    var t;
                    return t = function() {
                        function t(t) {
                            return t ? n(o) : r.apply(null, e)
                        }
                        var e, r, o, i, u;
                        i = arguments[0], e = 3 <= arguments.length ? ao.call(arguments, 1, u = arguments.length - 1) : (u = 1, []), r = arguments[u++], i.apply(null, ao.call(e).concat([function() {
                            t((o = arguments[0], e = 2 <= arguments.length ? ao.call(arguments, 1) : [], o))
                        }]))
                    }
                },
                h = function() {
                    function n() {
                        this.push_cbs = [], this.args = [], this.shift_cbs = []
                    }
                    var t;
                    return n.prototype.push = function() {
                        var n, t, e, r;
                        return n = 2 <= arguments.length ? ao.call(arguments, 0, r = arguments.length - 1) : (r = 0, []), t = arguments[r++], (e = this.shift_cbs.shift()) ? this.process(n, e, t) : (this.push_cbs.push(t), this.args.push(n))
                    }, n.prototype.unshift = function() {
                        var n, t, e, r;
                        return n = 2 <= arguments.length ? ao.call(arguments, 0, r = arguments.length - 1) : (r = 0, []), t = arguments[r++], (e = this.shift_cbs.shift()) ? this.process(n, e, t) : (this.push_cbs.unshift(t), this.args.unshift(n))
                    }, n.prototype.shift = function(n) {
                        var t, e;
                        return (e = this.push_cbs.shift()) ? (t = this.args.shift(), this.process(t, n, e)) : this.shift_cbs.push(n)
                    }, t = 0, n.prototype.process = function(n, e, r) {
                        function o() {
                            return r(), e.apply(null, n)
                        }
                        100 === ++t ? (t = 0, Kr(0, function() {
                            o()
                        })) : o()
                    }, n
                }(),
                e = {},
                je = function() {
                    var n, t, r, o, i;
                    return o = arguments[0], n = 3 <= arguments.length ? ao.call(arguments, 1, i = arguments.length - 1) : (i = 1, []), t = arguments[i++], (r = e[o]) ? r.channel.push(n, t) : void 0
                },
                Ge = function() {
                    var n, t, r, o, i;
                    return o = arguments[0], n = 3 <= arguments.length ? ao.call(arguments, 1, i = arguments.length - 1) : (i = 1, []), t = arguments[i++], (r = e[o]) ? r.channel.unshift(n, t) : void 0
                },
                qe = function(n, t) {
                    var r;
                    return (r = e[n]) ? r.actions.push(t) : (r = e[n] = {
                        actions: [t],
                        channel: new h,
                        running: !1
                    }, function() {
                        function n() {
                            r.channel.shift(function() {
                                function i() {
                                    a++, u()
                                }

                                function u() {
                                    s > a ? (t = c[a], t.apply(null, ao.call(e).concat([function(n) {
                                        i(n)
                                    }]))) : l()
                                }

                                function l() {
                                    n(o)
                                }
                                var a, s, c;
                                e = arguments[0], c = r.actions, a = 0, s = c.length, u()
                            })
                        }
                        var e, o = this;
                        n()
                    }())
                },
                kr = function() {
                    function n() {
                        o(i, function() {
                            return r()
                        })
                    }
                    var e, r, o, i, u, l;
                    return e = 3 <= arguments.length ? ao.call(arguments, 0, l = arguments.length - 2) : (l = 0, []), o = arguments[l++], r = arguments[l++], u = 2000, i = Zr(function(e) {
                        je(t.TA, e, u, function() {
                            Kr(u, function() {
                                return u += 2000, u > 20000 && (u = 20000), n()
                            })
                        })
                    }), n()
                },
                F = Xr, pt = rr, Jr = Jr, ye = ye, Kr = Kr, me = me, Xr = Xr, Vt = Vt, ue = ue, Lr = Lr, wr = wr, m = m, rr = rr, Zr = Zr, h = h, F = F, pt = pt, Tr = Tr, je = je, Ge = Ge, qe = qe, kr = kr, Xr = function() {
                    var n, e, r, o, i, u;
                    if (1 === arguments.length) Xr("", arguments[0]);
                    else {
                        if (i = arguments[0], r = arguments[1], u = typeof window !== t.bQ && null !== window ? window : global)
                            for (o in r) so.call(r, o) && (e = r[o], u[i + o] = e);
                        if (n = typeof module !== t.bQ && null !== module ? module.exports : void 0)
                            for (o in r) so.call(r, o) && (e = r[o], n[o.replace(/^_/, "")] = e)
                    }
                    return this
                }
            }(),
            er = null,
            // WebSocket init
            function() {
                er = function(n, e) {
                    var r, i;

                    if (typeof n === "string") {
                        n = [n];
                    }

                    var o = 0;

                    (i = function() {
                        function i() {
                            if (s.dead) {
                                u();
                            } else {
                                if (
                                    l <
                                    Date.now() - (e.timeout != null ? e.timeout : (e.timeout = 20000))
                                ) {
                                    r(s);

                                    return u();
                                }

                                if (a > 30) {
                                    s.send("ping");
                                    a = 0;
                                }

                                ++a;

                                Kr(1000, function(n) {
                                    i(n);
                                });
                            }
                        }

                        function u() {
                            return null;
                        }

                        var l;
                        var s;
                        var c = n[parseInt(o / 2) % n.length];

                        window.ws = s = new WebSocket(c);

                        s.id = Date.now();
                        s.dead = false;

                        l = Date.now();

                        s.onopen = function(n) {
                            o = 0;

                            if (window.onsocketopen) {
                                window.onsocketopen(s);
                            }

                            if ('hidden' in document) {
                                s.send(document.hidden ? "inactive" : "active");
                            }

                            if (typeof e.onopen === "function") {
                                return e.onopen(s, n);
                            }
                        };

                        s.onmessage = function(n) {
                            var err;

                            if (s && !s.dead) {
                                l = Date.now();

                                try {
                                    if (typeof e.onmessage === "function") {
                                        return e.onmessage(s, n);
                                    }

                                    return;
                                } catch (i) {
                                    err = i;

                                    if (typeof e.onerror === "function") {
                                        e.onerror(s, err.message);
                                    }

                                    return r(s);
                                }
                            }
                        };

                        s.onclose = function() {
                            return r(s);
                        };

                        s.onerror = function(n) {
                            o++;

                            if (typeof e.onerror === "function") {
                                e.onerror(s, n);
                            }

                            return r(s);
                        };

                        var a = 0;

                        i();
                    })();

                    r = function(n) {
                        if (!(n != null ? n.dead : 0)) {
                            n.dead = true;
                            n.close();

                            if (typeof e.ondead === 'function') {
                                e.ondead();
                            }

                            var retryInterval = Math.min(5000, 1000 + 2000 * o);

                            Kr(retryInterval, function() {
                                return i();
                            });
                        }
                    };
                };

                er = er;
            }(),
            r = n = e = null, zt = re = At = jt = Gt = oe = te = qt = St = Zt = Pt = Ht = Lt = Jt = It = Ot = Rt = Xt = _t = Et = Nt = Ut = Bt = Wt = Ct = Mt = Dt = ne = Yt = Qt = Kt = ee = null,
            function() {
                function o(n) {
                    var t;

                    if (n == null) {
                        n = [];
                    }

                    var result = {};
                    result[q] = [];

                    var o = 0;

                    for (var i = n.length; i > o; o++) {
                        t = n[o];

                        result[q][t] = [];
                    }

                    result[j] = [];
                    result[G] = [];

                    return result;
                }

                function i(n) {
                    var t;
                    var o = n[q];
                    var e = 0;
                    for (var r = o.length; r > e; e++) {
                        t = o[e];
                        t.shift();
                    }
                    return n;
                }

                function u(n) {
                    var t;

                    if ((t = n[q][0]) == null) {
                        return;
                    } else {
                        return t.length;
                    }
                }

                function l(n) {
                    var e = n[q];

                    var t = e.length;
                    e[t] = [];

                    return [t, e[t]];
                }

                function a(n, t) {
                    function e(n) {
                        i.push(n);
                        return [n, u[n]];
                    }

                    function r() {
                        var e = V(n, i);
                        o[e](0);
                        return c(n, t, e);
                    }

                    var u = n[q];
                    var o = n[G];
                    var i = [];

                    return [e, r];
                }

                function s(n, t, e) {
                    var r;
                    ((r = n[j])[t] != null ? (r = n[j])[t] : (r[t] = [])).push(e);
                    return e;
                }

                function c(n, t, e) {
                    var r;
                    var u = [];
                    var o = 0;

                    for (var i = t.length; i > o; o++) {
                        r = t[o];

                        s(n, r, e);

                        u.push(e);
                    }

                    return u;
                }

                function f(n, t, e) {
                    s(n, t, e);
                    n[G][e](0);
                    return e;
                }

                function h(n, t, e) {
                    var r;
                    var o;
                    var i = (function() {
                        var n = [];
                        for (r in e) {
                            if (so.call(e, r)) {
                                o = e[r];
                                n.push([r, o]);
                            }
                        }
                        return n;
                    })();
                    return ot(n, t, i);
                }
                function d(n, t, e) {
                    var r;
                    var o;
                    var i = (function() {
                        var n = [];
                        for (r in e) {
                            if (so.call(e, r)) {
                                o = e[r];
                                n.push([r, o]);
                            }
                        }
                        return n;
                    })();
                    return lt(n, t, i);
                }
                function p(n) {
                    return function() {
                        var t, r, o, u, l, a;

                        if (arguments[2].length) {
                            r = arguments[0];
                            a = arguments[1];
                            l = arguments[2];
                            o = arguments[3];
                        } else {
                            r = arguments[0];
                            a = arguments[1];
                            t = arguments[2];
                            u = arguments[3];
                            o = arguments[4];
                            l = [[t, u]];
                        }

                        e = (function() {
                            var i;
                            var s = [];
                            var e = 0;
                            for (var o = l.length; o > e; e++) {
                                i = l[e];
                                t = i[0];
                                u = i[1];
                                n(r, a, t, u);
                                s.push(t);
                            }
                            return s;
                        })();

                        var i = {};

                        if (o == null) {
                            o = true;
                        }

                        if (o) {
                            for (t in e) {
                                if (!i[t] && o && r[j][t] && a >= 0) {
                                    v(r, r[j][t], a);
                                }
                                i[t] = true;
                            }
                        }

                        return e;
                    };
                }

                function v(n, e, r) {
                    var o, u, l;

                    var i = n[G];

                    if (typeof e === t.Vl) {
                        i[e].call(this, r);
                    } else {
                        u = 0;
                        for (l = e.length; l > u; u++) {
                            o = e[u];
                            v(n, o, r);
                        }
                    }

                    return this;
                }

                function b(n, e) {
                    var r;
                    var o = n[q];

                    if (typeof e === t.Vl) {
                        return o[e];
                    }

                    var l = [];
                    var i = 0;

                    for (var u = e.length; u > i; i++) {
                        r = e[i];
                        if (!o[r]) {
                            o[r] = [];
                        }
                        l.push(o[r]);
                    }

                    return l;
                }

                function g(n, t, e) {
                    var l;
                    var r = Yt(n, e);
                    var u = {};
                    var i = (l = 0);

                    for (var a = e.length; a > l; i = ++l) {
                        e[i];
                        u[e[i]] = r[i][t];
                    }

                    return u;
                }

                function F(n, t) {
                    var o = n[G];
                    var i = l(n);

                    var e = i[0];
                    var r = i[1];

                    o[e] = function(e) {
                        return y(n, e, function(n) {
                            return (r[n] = t(n) || 0);
                        });
                    };

                    return e;
                }

                function x(n, t, e) {
                    var r = n[q];

                    return F(n, function(n) {
                        var o;
                        if ((o = r[t][n - e]) == null) {
                            return r[t][n];
                        } else {
                            return o;
                        }
                    });
                }

                function y(n, t, e) {
                    var i;
                    var o = n[q];
                    var r = (i = t);

                    for (var u = o[0].length; u >= t ? u > i : i > u; r = u >= t ? ++i : --i) {
                        e(r);
                    }

                    return null;
                }

                function $(n, t, e) {
                    var i = n[q];
                    var u = i[t];
                    var r = F(n, function(n) {
                        if (n === 0) {
                            return u[n];
                        } else if (e > n) {
                            return (o[n - 1] * n + u[n]) / (n + 1);
                        } else {
                            return (o[n - 1] * e - u[n - e] + u[n]) / e;
                        }
                    });
                    var o = i[r];
                    return r;
                }

                function w(n, t, e) {
                    var i = n[q];
                    var u = i[t];
                    var r = F(n, function(n) {
                        if (n === 0) {
                            return u[n];
                        } else if (e > n) {
                            return o[n - 1] + u[n];
                        } else {
                            return o[n - 1] - u[n - e] + u[n];
                        }
                    });
                    var o = i[r];
                    return r;
                }

                function T(n, t, e) {
                    var r = n[q];
                    var o = r[t];
                    return F(n, function(n) {
                        var u = n + 1;
                        var l = Math.max(u - e, 0);
                        var c = r[t].slice(l, u);
                        var s = 0;
                        var f = 0;
                        for (var d = c.length; d > f; f++) {
                            o = c[f];
                            s += o;
                        }
                        var i = s / c.length;
                        s = 0;
                        var h = 0;
                        for (var p = c.length; p > h; h++) {
                            o = c[h];
                            s += (o - i) * (o - i);
                        }
                        var a = s / c.length;
                        return Math.sqrt(a);
                    });
                }

                function V(n, t) {
                    n[q];
                    var o = n[G];
                    var u = l(n);
                    var e = u[0];
                    u[1];
                    o[e] = function(e) {
                        return v(n, t, e);
                    };
                    return e;
                }

                function k(n, t, e) {
                    var i = n[q];
                    var u = i[t];
                    var r = F(n, function(n) {
                        if (n === 0) {
                            return u[n];
                        } else {
                            return (2 * u[n] + (e - 1) * o[n - 1]) / (e + 1);
                        }
                    });
                    var o = i[r];
                    return r;
                }

                function S(n, t, e, r) {
                    var u = n[q];
                    var l = u[t];
                    var o = F(n, function(n) {
                        if (n === 0) {
                            return l[n];
                        } else {
                            return (r * l[n] + (e - r) * i[n - 1]) / e;
                        }
                    });
                    var i = u[o];
                    return o;
                }

                function M(n, t, e) {
                    var r = n[q];
                    var o = r[t];
                    return F(n, function(n) {
                        var r = Math.max(n - e + 1, 0);
                        var t = n + 1;
                        return Math.min.apply(Math, o.slice(r, t));
                    });
                }

                function _(n, t, e) {
                    var r = n[q];
                    var o = r[t];
                    return F(n, function(n) {
                        var r = Math.max(n - e + 1, 0);
                        var t = n + 1;
                        return Math.max.apply(Math, o.slice(r, t));
                    });
                }

                function I(n, t, e, r, o, i) {
                    if (e == null) {
                        e = 12;
                    }
                    if (r == null) {
                        r = 26;
                    }
                    if (o == null) {
                        o = 9;
                    }
                    if (i == null) {
                        i = 2;
                    }
                    n[q];
                    var y = a(n, [t]);
                    var v = y[0];
                    var h = y[1];
                    var $ = v(k(n, t, e));
                    $[0];
                    var m = $[1];
                    var w = v(k(n, t, r));
                    w[0];
                    var b = w[1];
                    var T = v(
                        F(n, function(n) {
                            return m[n] - b[n];
                        })
                    );
                    var c = T[0];
                    var p = T[1];
                    var V = v(k(n, c, o));
                    var s = V[0];
                    var d = V[1];
                    var S = v(
                        F(n, function(n) {
                            return i * (p[n] - d[n]);
                        })
                    );
                    var f = S[0];

                    S[1];
                    h();

                    return [c, s, f];
                }

                function X(n, t, e, r, o, i) {
                    if (e == null) {
                        e = 14;
                    }
                    if (r == null) {
                        r = 14;
                    }
                    if (o == null) {
                        o = 3;
                    }
                    if (i == null) {
                        i = 3;
                    }
                    var A = n[q];
                    n[G];
                    var Y = a(n, [t]);
                    var B = Y[0];
                    var J = Y[1];
                    var Z = A[t];
                    var et = B(x(n, t, 1));
                    et[0];
                    var E = et[1];
                    var rt = B(
                        F(n, function(n) {
                            return Math.max(Z[n] - E[n], 0);
                        })
                    );
                    var u = rt[0];
                    rt[1];
                    var ot = B(
                        F(n, function(n) {
                            return Math.abs(Z[n] - E[n]);
                        })
                    );
                    var s = ot[0];
                    ot[1];
                    var it = B(S(n, u, e, 1));
                    it[0];
                    var k = it[1];
                    var ut = B(S(n, s, e, 1));
                    ut[0];
                    var X = ut[1];
                    var lt = B(
                        F(n, function(n) {
                            if (X[n] < 1e-12) {
                                return 100;
                            } else {
                                return (k[n] / X[n]) * 100;
                            }
                        })
                    );
                    var T = lt[0];
                    var Q = lt[1];
                    var at = B(M(n, T, r));
                    at[0];
                    var N = at[1];
                    var st = B(_(n, T, r));
                    st[0];
                    var O = st[1];
                    var D = B(
                        F(n, function(n) {
                            return Q[n] - N[n];
                        })
                    );
                    var m = D[0];
                    D[1];
                    var K = B(
                        F(n, function(n) {
                            return O[n] - N[n];
                        })
                    );
                    var w = K[0];
                    K[1];
                    var z = B($(n, m, o));
                    z[0];
                    var L = z[1];
                    var j = B($(n, w, o));
                    j[0];
                    var W = j[1];
                    var nt = B(
                        F(n, function(n) {
                            if (W[n] < 1e-12) {
                                return 100;
                            } else {
                                return (L[n] / W[n]) * 100;
                            }
                        })
                    );
                    var p = nt[0];
                    nt[1];
                    var tt = B($(n, p, i));
                    var h = tt[0];
                    tt[1];
                    J();
                    return [p, h];
                }

                function Z(n, t, e) {
                    var l = t[0];
                    var w = n[q];
                    n[G];
                    var T = a(n, t);
                    var m = T[0];
                    var b = T[1];
                    var v = w[l];
                    var V = m(x(n, l, 1));
                    V[0];
                    var y = V[1];
                    var k = m(
                        F(n, function(n) {
                            return Math.max(v[n] - y[n], 0);
                        })
                    );
                    var r = k[0];
                    k[1];
                    var M = m(
                        F(n, function(n) {
                            return Math.abs(v[n] - y[n]);
                        })
                    );
                    var i = M[0];
                    M[1];
                    var _ = m(S(n, r, e, 1));
                    _[0];
                    var h = _[1];
                    var I = m(S(n, i, e, 1));
                    I[0];
                    var p = I[1];
                    var X = m(
                        F(n, function(n) {
                            if (p[n] === 0) {
                                return 100;
                            } else {
                                return (h[n] / p[n]) * 100;
                            }
                        })
                    );
                    var c = X[0];
                    X[1];
                    b();
                    return [c];
                }

                function J(n, t, e, r, o) {
                    var s = t[0];
                    var d = t[1];
                    var i = t[2];
                    if (e == null) {
                        e = 9;
                    }
                    if (r == null) {
                        r = 3;
                    }
                    if (o == null) {
                        o = 3;
                    }
                    var X = n[q];
                    n[G];
                    var Z = a(n, arguments[1]);
                    var x = Z[0];
                    var b = Z[1];
                    X[d];
                    var v = X[i];
                    X[s];
                    var J = x(M(n, d, e));
                    J[0];
                    var V = J[1];
                    var P = x(_(n, s, e));
                    P[0];
                    var y = P[1];
                    var H = x(
                        F(n, function(n) {
                            if (y[n] - V[n] < 1e-8) {
                                return 100;
                            } else {
                                return ((v[n] - V[n]) / (y[n] - V[n])) * 100;
                            }
                        })
                    );
                    var p = H[0];
                    H[1];
                    var B = x(S(n, p, r, 1));
                    var f = B[0];
                    var T = B[1];
                    var O = x(S(n, f, o, 1));
                    var u = O[0];
                    var g = O[1];
                    var R = x(
                        F(n, function(n) {
                            return 3 * T[n] - 2 * g[n];
                        })
                    );
                    var c = R[0];
                    R[1];
                    b();
                    return [f, u, c];
                }

                function P(n, t, e) {
                    if (e == null) {
                        e = 20;
                    }

                    var i = t[0];
                    var u = t[1];
                    var r = t[2];
                    var f = t[3];
                    var x = n[q];
                    n[G];
                    var y = a(n, t);
                    var v = y[0];
                    var d = y[1];
                    var $ = v(
                        F(n, function(n) {
                            var e = x[i][n];
                            var o = x[u][n];
                            var t = x[r][n];
                            var l = x[f][n];
                            if (e === o) {
                                return 0;
                            } else {
                                return ([t - o - (e - t)] / (e - o)) * l;
                            }
                        })
                    );
                    var l = $[0];
                    $[1];
                    var T = v(w(n, l, e));
                    var s = T[0];
                    T[1];
                    var V = v(w(n, f, e));
                    var c = V[0];
                    V[1];
                    var k = v(
                        F(n, function(n) {
                            return x[s][n] / x[c][n];
                        })
                    );
                    var o = k[0];
                    k[1];
                    d();
                    return [o];
                }

                function H(n, t) {
                    var e = t[0];
                    var i = t[1];

                    var f = n[q];
                    var h = a(n, t);

                    var l = h[0];
                    var u = h[1];

                    var d = l(x(n, e, 1));

                    d[0];

                    var c = d[1];

                    var p = l(
                        F(n, function(n) {
                            var r;
                            var t = (r = s[n - 1]) != null ? r : 0;
                            if (f[e][n] > c[n]) {
                                return t + f[i][n];
                            } else if (f[e][n] < c[n]) {
                                return t - f[i][n];
                            } else {
                                return t;
                            }
                        })
                    );

                    var r = p[0];
                    var s = p[1];

                    u();
                    return [r];
                }

                // calculate SAR
                function B(n, t, e, r, o) {
                    if (e == null) {
                        e = 0.02;
                    }

                    if (r == null) {
                        r = 0.2;
                    }

                    if (o == null) {
                        o = 4;
                    }

                    var u = t[0]; // 5
                    var s = t[1]; // 6
                    //var i = t[2]; // 4

                    var w = n[q];
                    var T = a(n, t);

                    var b = T[0];
                    var d = T[1];

                    var g = w[u];
                    var x = w[s];

                    //w[i];

                    var v = [e];
                    var p = [1];

                    var V = b(M(n, s, o));
                    var k = b(_(n, u, o));

                    //V[0];
                    //k[0];

                    var y = V[1];
                    var m = k[1];

                    var S = b(
                        F(n, function(n) {
                            var o;
                            if (n === 0) {
                                return x[n];
                            }
                            var t = v[n - 1];
                            p[n] = p[n - 1];
                            if (p[n]) {
                                o = $[n - 1] + t * (g[n] - $[n - 1]);

                                v[n] = m[n] > m[n - 1] ? Math.min(t + e, r) : t;

                                if (x[n] < o) {
                                    p[n] = 0;
                                    v[n] = e;
                                    return m[n];
                                }
                            } else if (
                                ((o = $[n - 1] + t * (x[n] - $[n - 1])),
                                    (v[n] = y[n] < y[n - 1] ? Math.min(t + e, r) : t),
                                g[n] > o)
                            ) {
                                p[n] = 1;
                                v[n] = e;
                                return y[n];
                            }
                            return o;
                        })
                    );

                    var f = S[0];
                    var $ = S[1];

                    d();

                    return [f];
                }

                function O(n, t, e) {
                    if (e == null) {
                        e = 20;
                    }
                    var r = t[0];
                    n[q];
                    var b = a(n, t);
                    var f = b[0];
                    var s = b[1];
                    var g = f($(n, r, e));
                    var u = g[0];
                    var h = g[1];
                    var m = f(T(n, r, e));
                    m[0];
                    var d = m[1];
                    var x = f(
                        F(n, function(n) {
                            return h[n] + 2 * d[n];
                        })
                    );
                    var l = x[0];
                    x[1];
                    var y = f(
                        F(n, function(n) {
                            return h[n] - 2 * d[n];
                        })
                    );
                    var o = y[0];
                    y[1];
                    s();
                    return [u, o, l];
                }
                function R(n, t, e, r) {
                    var d = t[0];
                    var v = t[1];
                    var l = t[2];
                    var E = n[q];
                    var U = a(n, t);
                    var Z = U[0];
                    var k = U[1];
                    var V = E[l];
                    var J = E[d];
                    var H = E[v];
                    var D = Z(
                        F(n, function(n) {
                            return J[n] - J[n - 1];
                        })
                    );
                    var p = D[0];
                    var P = D[1];
                    var K = Z(
                        F(n, function(n) {
                            return H[n - 1] - H[n];
                        })
                    );
                    var b = K[0];
                    var B = K[1];
                    var z = Z(
                        F(n, function(n) {
                            if (P[n] > 0 && P[n] > B[n]) {
                                return P[n];
                            } else {
                                return 0;
                            }
                        })
                    );
                    var h = z[0];
                    var X = z[1];
                    var j = Z(
                        F(n, function(n) {
                            if (B[n] > 0 && B[n] > P[n]) {
                                return B[n];
                            } else {
                                return 0;
                            }
                        })
                    );
                    var c = j[0];
                    var _ = j[1];
                    var G = Z(
                        F(n, function(n) {
                            var e;
                            var t = (e = V[n - 1]) != null ? e : V[n];
                            return Math.max(J[n] - H[n], Math.abs(J[n] - t), Math.abs(H[n] - t));
                        })
                    );

                    var y = G[0];
                    var L = G[1];

                    var nt = Z(S(n, h, e, 1));

                    var f = nt[0];
                    var I = nt[1];

                    var tt = Z(S(n, c, e, 1));

                    var s = tt[0];
                    var M = tt[1];

                    var et = Z(S(n, y, e, 1));

                    var x = et[0];
                    var N = et[1];

                    var W = Z(
                        F(n, function(n) {
                            return (100 * I[n]) / N[n];
                        })
                    );

                    var m = W[0];
                    var R = W[1];

                    var C = Z(
                        F(n, function(n) {
                            return (100 * M[n]) / N[n];
                        })
                    );

                    var g = C[0];
                    var O = C[1];

                    var Q = Z(
                        F(n, function(n) {
                            return (Math.abs(O[n] - R[n]) / (O[n] + R[n])) * 100;
                        })
                    );

                    var u = Q[0];
                    var w = Q[1];

                    var A = Z(S(n, u, r, 1));

                    var o = A[0];
                    var $ = A[1];

                    var Y = Z(
                        F(n, function(n) {
                            var t = $[n - r] || $[n];
                            return ($[n] + t) / 2;
                        })
                    );

                    var i = Y[0];
                    var T = Y[1];

                    k();

                    return [m, g, o, i];
                }
                function E(n, t) {
                    var f;

                    var i = t[0];
                    var r = t[1];
                    var o = t[2];
                    var e = t[3];

                    var d = n[q];
                    var c = n[G];
                    var b = l(n);

                    var u = b[0];
                    var a = b[1];

                    c[u] = function(t) {
                        return y(n, t, function(n) {
                            a[n] = parseFloat(((d[r][n] + d[o][n] + d[e][n]) / 3).toFixed(8));
                            return a[n];
                        });
                    };
                    c[u](0);

                    var g = arguments[1];
                    var p = 0;

                    for (var v = g.length; v > p; p++) {
                        f = g[p];
                        s(n, f, u);
                    }

                    return [u];
                }

                function N(n, t) {
                    var f;
                    var i = t[0];
                    var r = t[1];
                    var o = t[2];
                    var e = t[3];

                    var d = n[q];
                    var c = n[G];
                    var b = l(n);

                    var u = b[0];
                    var a = b[1];

                    c[u] = function(t) {
                        return y(n, t, function(n) {
                            a[n] = parseFloat(((d[r][n] + d[o][n]) / 2).toFixed(8));
                            return a[n];
                        });
                    };
                    c[u](0);

                    var g = arguments[1];
                    var p = 0;

                    for (var v = g.length; v > p; p++) {
                        f = g[p];
                        s(n, f, u);
                    }

                    return [u];
                }
                function L(n, t) {
                    var r = t[0];
                    var o = t[1];
                    var e = t[2];

                    var f = n[q];
                    var a = n[G];

                    var h = l(n);
                    var i = h[0];
                    var u = h[1];

                    a[i] = function(t) {
                        return y(n, t, function(n) {
                            u[n] =
                                f[o][n] === 0
                                    ? f[e][n]
                                    : parseFloat((f[r][n] / f[o][n]).toPrecision(5));
                            return u[n];
                        });
                    };
                    a[i](0);

                    c(n, t, i);

                    return [i];
                }
                function U(n, t, e) {
                    function r(n) {
                        b.push(n);
                        return [n, g[n]];
                    }

                    if (e == null) {
                        e = 3;
                    }

                    var o = t[0];
                    var i = t[1];

                    var g = n[q];
                    var d = n[G];

                    var b = [];
                    var m = r(
                        F(n, function(n) {
                            if (g[o][n] === g[i][n]) {
                                return 0.5;
                            } else {
                                return g[i][n] / (g[i][n] + g[o][n]);
                            }
                        })
                    );

                    var u = m[0];
                    m[1];
                    var x = r($(n, u, e));
                    var a = x[0];
                    x[1];
                    var y = l(n);
                    var f = y[0];
                    y[1];
                    d[f] = function(t) {
                        return v(n, b, t);
                    };
                    d[f](0);
                    c(n, t, f);
                    return [a];
                }
                function W(n, t) {
                    return f(n, t, $.apply(null, arguments));
                }
                function C(n, t) {
                    return f(n, t, k.apply(null, arguments));
                }
                function Q(n, t) {
                    return f(n, t, S.apply(null, arguments));
                }
                function A(n, t) {
                    return f(n, t, w.apply(null, arguments));
                }
                function Y(n, t) {
                    var o = n[q];
                    var i = l(n);
                    var e = i[0];
                    i[1];
                    o[e] = t;
                    return e;
                }

                function D() {
                    var n;
                    var i;
                    var r = arguments[0];
                    var o =
                        3 <= arguments.length
                            ? ao.call(arguments, 1, (i = arguments.length - 1))
                            : ((i = 1), []);
                    var e = arguments[i++];
                    var a = [];
                    var u = 0;
                    for (var l = e.length; l > u; u++) {
                        n = e[u];
                        if (typeof n === "object" && n.length) {
                            a.push(r.apply(null, ao.call(o).concat(ao.call(n))));
                        } else {
                            a.push(r.apply(null, ao.call(o).concat([n])));
                        }
                    }
                    return a;
                }

                function K() {
                    return D.apply(null, [b].concat(ao.call(arguments)));
                }

                function z(n, t, e, r) {
                    var o = b(n, r);
                    return o.slice(t, +e + 1 || 9e9);
                }

                var it = m(3);

                var q = it[0];
                var j = it[1];
                var G = it[2];

                r = q;
                n = j;
                e = G;

                var lt = p(function(n, t, e, r) {
                    return (n[q][e][t] = r);
                });

                p(function(n, t, e) {
                    return n[q][e].push(t);
                });

                var ot = p(function(n, t, e, r) {
                    return n[q][e].splice(t, 0, r);
                });

                var rt = p(function(n, t, e) {
                    return n[q][e].splice(t, 1);
                });

                zt = o;
                re = lt;
                At = rt;
                jt = ot;
                Gt = h;
                oe = d;
                te = i;
                qt = u;
                St = Y;
                Zt = C;
                Pt = W;
                Ht = I;
                Lt = X;
                Jt = J;
                It = P;
                Ot = H;
                Rt = Z;
                Xt = R;
                _t = O;
                Et = B;
                Nt = Q;
                Ut = A;
                Bt = N;
                Wt = E;
                Ct = L;
                Mt = U;
                Dt = g;
                ne = lt;
                Yt = b;
                Qt = D;
                Kt = K;

                return (ee = z);
            }(),
            Se = Xe = Ie = ke = Me = Ve = $e = we = Te = _e = null, d = v = p = b = null,
            // storing trades
            function() {
                function n(n, t) {
                    return n[0] - t[0]
                }

                function e(n) {
                    var t = { group: n };

                    r(t);

                    return t;
                }

                function r(t) {
                    t[g] = new dt({ compare: n });
                    t[x] = new dt({ compare: n });
                    t[F] = new dt({ compare: n });
                    t[y] = new dt({ compare: n });

                    return t;
                }

                function o(n, t, e) {
                    switch (t) {
                        case x:
                            return Math.floor(e[0] / n.group + 1e-12) * n.group;
                        case g:
                            return Math.ceil(e[0] / n.group - 1e-12) * n.group
                    }
                }

                function i(n, t, e) {
                    var r;
                    var i;

                    n[t].insert(e);

                    if (e[1]) {
                        e = [o(n, t, e), e[1]];
                        i = t === x ? y : F;

                        if ((r = n[i].find(e))) {
                            r[1] += e[1];

                            return (r[2] += e[1] * e[0]);
                        } else {
                            r = e;
                            r[2] = e[1] * e[0];

                            return n[i].insert(r);
                        }
                    } else {
                        return;
                    }
                }

                function u(n, e, r) {
                    var i;
                    var u;

                    if (r && (n[e]["delete"](r), r[1])) {
                        r = [o(n, e, r), r[1]];
                        u = e === x ? y : F;
                        i = n[u].find(r);

                        if (i && ((i[1] -= r[1]), (i[2] -= r[1] * r[0]), i[1] < 1e-12)) {
                            return n[u]["delete"](i);
                        } else {
                            return;
                        }
                    }
                }

                function l(n, e) {
                    var r;

                    var p = e.type_str;
                    var f = e.price_int;
                    var h = e.total_volume_int;
                    var o = e.now;
                    var d = p === "bid" ? x : g;
                    var s = n[d];
                    var v = parseInt(h);
                    var c = parseInt(f);
                    var l = [c, v, o];
                    var a = s.find([c]);

                    u(n, d, a);

                    if (v) {
                        i(n, d, l);
                    }

                    if (d === x) {
                        while ((r = n[g].get(0)) && r[0] <= c) {
                            u(n, g, r);
                        }
                    } else {
                        while ((r = n[x].get(-1)) && r[0] >= c) {
                            u(n, x, r);
                        }
                    }

                    return n;
                }

                function a(collection, item, isRightHandOrderbook) {
                    var price = item[0];
                    var amount = item[1];
                    var side = item[2];

                    var h = side === 'bid' ? x : g;

                    var c = collection[h];

                    var a = [price, amount];

                    if (isRightHandOrderbook == null) {
                        isRightHandOrderbook = true;
                    }

                    var l, s = c.find([price]);

                    u(collection, h, s);

                    if (amount) {
                        i(collection, h, a);
                    }

                    if (!isRightHandOrderbook) {
                        return collection;
                    }

                    if (h === x) {
                        while ((l = collection[g].get(0)) && l[0] <= price) {
                            u(collection, g, l);
                        }
                    } else {
                        while ((l = collection[x].get(-1)) && l[0] >= price) {
                            u(collection, x, l);
                        }
                    }

                    return collection;
                }

                function s(n, e) {
                    var o;
                    var c;

                    if(!e) {
                        return;
                    }

                    var a = e.price;
                    var r = e.amount;
                    var s = e.trade_type;

                    if (s === "bid") {
                        c = g;
                        for (n[c]; r > 1e-12 && (o = n[g].get(0)) && o[0] <= a; ) {
                            if (o[1] > r) {
                                u(n, c, o);

                                o[1] = o[1] - r;

                                i(n, c, o);
                                break;
                            }

                            u(n, g, o);

                            r -= o[1];
                        }
                    } else {
                        for (c = x; r > 1e-12 && (o = n[x].get(-1)) && o[0] >= a; ) {
                            if (o[1] > r) {
                                u(n, c, o);

                                o[1] = o[1] - r;

                                i(n, c, o);
                                break;
                            }

                            u(n, x, o);

                            r -= o[1];
                        }
                    }
                    return n;
                }

                function c(n, t) {
                    var o;

                    for (var e = 0; (o = n[g].get(0)) && o[0] < t; ) {
                        ++e;
                        u(n, g, o);
                    }

                    for (var r = 0; (o = n[x].get(-1)) && o[0] > t; ) {
                        ++r;
                        u(n, x, o);
                    }

                    return [e, r];
                }

                function f(n, t, e, r) {
                    var a;
                    var o = 0;
                    var i = 0;
                    var s = 0;
                    var c = 0;

                    for (var l = 0; (a = n[g].at(l)) && a[0] <= e; ) {
                        if (a[3] >= r) {
                            ++l;
                            ++s;
                        } else {
                            ++o;
                            u(n, g, a);
                        }
                    }

                    for (l = -1; (a = n[x].at(l)) && a[0] >= t; ) {
                        if (a[3] >= r) {
                            --l;
                            ++c;
                        } else {
                            ++i;
                            u(n, x, a);
                        }
                    }

                    return [o, i, s, c];
                }


                function h(n) {
                    var t = n[g];
                    var r = n[x];

                    t.slice(-11, -1);

                    return r.slice(0, 10);
                }

                var g, F, x, y, $, w, T, V, k, S;

                k = m(4);

                x = k[0];
                g = k[1];
                y = k[2];
                F = k[3];

                S = [0, 1, 2, 3];

                $ = S[0];
                V = S[1];
                T = S[2];
                w = S[3];

                Se = e;
                Xe = l;
                Ie = a;
                ke = h;
                Me = i;
                Ve = u;
                $e = c;
                we = f;
                Te = r;
                _e = s;

                d = g;
                v = x;
                p = F;
                b = y;

                return b;
            }(),

            Ae = Ke = De = Ne = Ee = ze = We = null, Ze = Je = null, He = Pe = Oe = Re = Be = Le = Ue = Ce = Ye = Qe = null,
            // draw functions
            function() {
                // draw a volume candle
                function n(n, t, e, o, i, u, fillAndStroke) {
                    var s = eo(t, o, i);

                    var l = s[0];
                    var a = s[1];

                    if (a > e) {
                        return r(n, l, e, u, a - e, fillAndStroke);
                    } else {
                        return r(n, l, a, u, e - a, fillAndStroke);
                    }
                }

                // draw a main candle: candle_stick and candle_stick_hlc
                function e(ctx, evt, barIndex, openPrice, closePrice, barWidth, fillAndStroke) {
                    var rectX, rectYFrom, rectYTo;

                    rectX = ro(evt, barIndex);
                    rectYFrom = oo(evt, openPrice);
                    rectYTo = oo(evt, closePrice);

                    return r(ctx, rectX, rectYTo, barWidth, rectYFrom - rectYTo, fillAndStroke)
                }

                // draw a rectangle
                // fixme: slow
                function r(ctx, x, y, width, height, fillAndStroke) {
                //    window.CumulativeMeasurer('rectangle').start();

                    if(height < 0) {
                        y += height;
                        height = -height;
                    }

                    if(height === 0) {
                        height = 1;
                    }

                /*    if(fillAndStroke === undefined) {
                        debugger;
                    }*/

                    if(fillAndStroke === undefined ? ctx.fillStyle === ctx.strokeStyle : fillAndStroke) {
                        ctx.fillRect(x, y, width, height);
                    } else if(height > 1) {
                        ctx.fillRect(x, y, width, height);
                        ctx.strokeRect(x + .5, y + .5, width - 1, height - 1);
                    } else if(height === 1) {
                        ctx.beginPath();
                        ctx.moveTo(x, y + .5);
                        ctx.lineTo(x + width, y + .5);
                        ctx.stroke();
                    }

                //    window.CumulativeMeasurer('rectangle').stop();
                }

                function o(n) {
                    var t, e, r, o;

                    if (k.length !== 0) {
                        t = n.getImageData(0, 0, n.canvas.width, n.canvas.height);
                        r = 0;

                        for (o = k.length; o > r; r++) {
                            e = k[r];
                            e(t);
                        }

                        n.putImageData(t, 0, 0);

                        k = [];

                        return this;
                    }
                }


                // draw lines
                // fixme: slow
                function i(ctx, e, r, o) {
                    var u, l, a, s, c, f, h, d, p, v, b, g, m, F, x, y, $, w;

                    if (r.length) {
                        d = null;

                        ctx.beginPath();

                        a = e[Ft];
                        s = F = 0;

                        for ($ = r.length; $ > F; s = ++F) {
                            c = r[s];
                            w = eo(e, s, c);

                            u = w[0];
                            l = w[1];

                            if (o) {
                                u += o - 0.5;
                            }

                            l += 0.5;

                            if (!(c > a.y + a.h) && !(c < a.y) && performanceSettings.sharp_lines) {
                                if (d == null) {
                                    ctx.moveTo(u, l);
                                    ctx.lineTo(u + 1, l);
                                } else {
                                    v = u - d;
                                    b = l - p;

                                    if (Math.abs(v) < Math.abs(b)) {
                                        for (f = x = 0; b >= 0 ? b >= x : x >= b; f = b >= 0 ? ++x : --x) {
                                            g = (d + (v * f) / b) >> 0;
                                            m = p + f;
                                            ctx.moveTo(g, m);
                                            ctx.lineTo(g + 1, m);
                                        }
                                    } else if (Math.abs(v) < 1024) {
                                        for (f = y = 0; v >= 0 ? v >= y : y >= v; f = v >= 0 ? ++y : --y) {
                                            g = d + f;
                                            m = (p + (b * f) / v) >> 0;
                                            ctx.moveTo(g, m + 0.5);
                                            ctx.lineTo(g + 1, m + 0.5);
                                        }
                                    }
                                }
                            } else {
                                if (d == null) {
                                    ctx.moveTo(u, l);
                                }

                                ctx.lineTo(u + 1, l);
                            }

                            d = u;
                            p = l;
                        }

                        ctx.stroke();

                        return this;
                    }
                }

                function u(n, t, e, r) {
                    var o;
                    return o = $.Color(n.strokeStyle).rgba(), t = ue(t), k.push(function(n) {
                        var i, u, l, a, s, f, h;
                        for (l = s = 0, f = e.length; f > s; l = ++s) a = e[l], null != a && (h = eo(t, l, a), i = h[0], u = h[1], r && (i += r), i >>= 0, u >>= 0, c(n, i, u, o));
                        return this
                    })
                }

                function l(n, e, r) {
                    var o;
                    return o = $.Color(n.strokeStyle).rgba(), e = ue(e), k.push(function(n) {
                        var e, i, u, l, a, c, f;
                        for (a = 0, c = r.length; c > a; a++) f = r[a], e = f[0], i = f[1], e >>= 0, i >>= 0, typeof l !== t.bQ && null !== l && s(n, u, l, e, i, o), u = e, l = i;
                        return this
                    })
                }

                function a(n, t, e, r, o) {
                    var i, u;
                    return null == o && (o = 1), u = n.width, i = n.height, Jr = e * u + t << 2, n.data[Jr++] = r[0], n.data[Jr++] = r[1], n.data[Jr++] = r[2], n.data[Jr++] = 255 * r[3] * o
                }

                function s(n, t, e, r, o, i) {
                    var u, l, s, c, f, h, d;
                    for (u = Math.abs(r - t), f = r > t ? 1 : -1, l = Math.abs(o - e), h = o > e ? 1 : -1, c = (null != (d = u > l) ? d : {
                        dx: -l
                    }) / 2;;) {
                        if (a(n, t, e, i), t === r && e === o) break;
                        s = c, s > -u && (c -= l, t += f), l > s && (c += u, e += h)
                    }
                    return this
                }

                function c(n, t, e, r) {
                    return a(n, t, e, r, .8), a(n, t + 1, e, r, .8), a(n, t - 1, e, r, .8), a(n, t, e + 1, r, .8), a(n, t, e - 1, r, .8)
                }

                function f(n, t, e, r, o) {
                    return n.beginPath(), n.moveTo(t, e), n.lineTo(t, e - o), n.lineTo(t + .866 * o, e - .5 * o), n.fill()
                }

                function h(n, t, e, r, o) {
                    return n.beginPath(), n.moveTo(t, e), n.lineTo(t, e - o), n.lineTo(t - .866 * o, e - .5 * o), n.fill()
                }

                // draw a candle wick
                function d(n, t, e, r, o, i) {
                    var u = ro(t, e);
                    var l = oo(t, r);
                    var a = oo(t, o);

                    if (i) {
                        u += i;
                    }

                //    n.beginPath();

                    n.moveTo(u + 0.5, a);
                    n.lineTo(u + 0.5, l);

                //    return n.stroke();
                }


                function p(n, t, e, r) {
                    return n.beginPath(), n.moveTo(e, t), n.lineTo(r, t), n.stroke()
                }

                function v(n, t, e, r) {
                    return n.beginPath(), n.moveTo(t, e), n.lineTo(t, r), n.stroke()
                }

                function b(n, t, e) {
                    var o;
                    var i;
                    var l;
                    var r = ue(t[mt]);
                    var u = ue(t[Ft]);

                    t = lo(r, u);

                    var a = e(r, u);
                    var s = 0;

                    for (var c = a.length; c > s; s++) {
                        l = a[s];
                        i = oo(t, l);
                        o = r.w;

                        n.fillText(l, o - 8, i + 0.5);
                        p(n, i + 0.5, r.w - 4, r.w);
                    }

                    return null;
                }

                function g(n, t) {
                    return b(n, t, function(n, t) {
                        var e, r, o, i, u, l, a;
                        for (r = Math.floor(n.h / 32), u = t.h / r, i = t.y, a = [], e = l = 0; r >= 0 ? r >= l : l >= r; e = r >= 0 ? ++l : --l) o = i + e * u, a.push(parseFloat(o.toPrecision(5)));
                        return a
                    })
                }

                function m(n, t) {
                    b(n, t, function(n, t) {
                        var e;
                        var r;
                        var s;
                        var o = Math.abs(n.h / 32);
                        var l = t.h / o;

                        var c = (function() {
                            var o = [1, 2, 5];
                            var i = [];
                            var n = 0;

                            for (var t = o.length; t > n; n++) {
                                e = o[n];
                                r = l / e;
                                s = Math.ceil(Math.log(r) / Math.log(10)).toFixed(2);
                                s = Math.pow(10, s);
                                s = e * s;
                                i.push(s);
                            }
                            return i;
                        })();

                        var a = Math.min.apply(Math, c);

                        var i = Math.ceil(t.y / a) * a;

                        for (var u = []; i < t.y + t.h; ) {
                            u.push(parseFloat(i.toPrecision(5)));
                            i += a;
                        }

                        return u;
                    });

                    return null;
                }

                function F(n, e, r, o) {
                    var u, l, c, f, d, v;

                    if (!r.text && !r.hr) {
                        r.text = true;
                        r.hr = true;
                    }

                    e = Gr(e);

                    var i = e[mt];
                    var a = e[Ft];
                    var h = o(i, a);

                    n.textAlign = t.VN;

                    var s = Math.max.apply(Math, h);

                    if (1e6 > s) {
                        s = 0;
                    }

                    var d = 0;

                    for (var v = h.length; v > d; d++) {
                        c = h[d];
                        l = oo(e, c);
                        u = i.x;
                        f = r.keep ? c : mr(c);

                        if (r.text) {
                            n.fillText(f, u + 50, l + 0.5);
                        }

                        if (r.hr) {
                            p(n, l + 0.5, u, u + 6);
                            p(n, l + 0.5, u + i.w - 6, u + i.w);
                        }
                    }
                    n.textAlign = t.uO;
                    return null;
                }

                function x(n, t, e) {
                    if (e == null) {
                        e = {};
                    }

                    return F(n, t, e, function(n, t) {
                        var o;
                        var l;

                        var r = Math.floor(n.h / 32);
                        var u = t.h / r;
                        var i = t.y;
                        var a = [];

                        for (var e = (l = 0); r >= 0 ? r >= l : l >= r; e = r >= 0 ? ++l : --l) {
                            o = i + e * u;
                            a.push(parseFloat(o.toPrecision(5)));
                        }

                        return a;
                    });
                }

                function y(n, t, e, r) {
                    if (r == null) {
                        r = {};
                    }

                    return F(n, t, r, function() {
                        return e;
                    });
                }

                // this draws Y axis for chart and all indicators
                function w(n, t, e) {
                    if (e == null) {
                        e = {};
                    }

                    var u = [];

                    F(n, t, e, function(n, t) {
                        var e, r, s, c;

                        var o = Math.abs(n.h / 32);
                        var l = t.h / o;

                        c = (function() {
                            var o = [1, 2, 5];
                            var i = [];
                            var n = 0;

                            for (var t = o.length; t > n; n++) {
                                e = o[n];

                                r = l / e;

                                s = Math.ceil(Math.log(r) / Math.log(10)).toFixed(2);
                                s = Math.pow(10, s);
                                s = e * s;

                                i.push(s);
                            }

                            return i;
                        })();

                        var a = Math.min.apply(Math, c);
                        var i = Math.ceil(t.y / a) * a;

                        for (; i < t.y + t.h; ) {
                            u.push(parseFloat(i.toPrecision(5)));
                            i += a;
                        }

                        return u;
                    });

                    return u;
                }

                function T(n, t) {
                    var e;

                    n.save();
                    n.beginPath();

                    e = ue(t[mt]);

                    e.y += 8;
                    e.h -= 16;

                    n.moveTo(e.x, e.y);
                    n.lineTo(e.x + e.w, e.y);
                    n.lineTo(e.x + e.w, e.y + e.h);
                    n.lineTo(e.x, e.y + e.h);

                    return n.clip()
                }

                function V(n, t, e) {
                    n.save();

                    e();

                    return n.restore();
                }

                var k;
                return k = [], Ae = i, Ke = n, De = e, Ne = r, Ee = p, ze = d, We = v, Ze = g, Je = m, He = x, Pe = w, Oe = T, Re = V, Be = y, Le = h, Ue = f, Ce = o, Ye = l, Qe = u
            }(), dt = null,
            // some structure for holding trades and maybe other data. CUSTOM
            function() {
                var n;
                return n = function() {
                    function n(n) {
                        this.options = n != null ? n : {};
                        this.id = ++u;
                        this.min = 0;
                        this.max = 0;
                        this.count = 0;
                        this.type = i;
                        this.total = 0;
                        this.parent = null;
                        this.children = [];
                        this.next = null;
                        this.prev = null;

                        this.compare = this.options["compare"];
                        this.multimap = this.options["multimap"];

                        if (this.compare == null) {
                            this.compare = function(n, t) {
                                return n - t;
                            };
                        }
                    }

                    var e, r, o, i, u;
                    return e = 8, r = e << 1, o = 0, i = 1, u = 0, n.prototype.insert_value_ = function(n) {
                        var t, e, r, o, i, u, l;
                        for (e = this.count, t = this.children, r = l = 0; e >= 0 ? e > l : l > e; r = e >= 0 ? ++l : --l) {
                            if (u = t[r], i = this.compare(u, n), 0 === i) {
                                if (this.multimap) break;
                                return
                            }
                            if (i > 0) break
                        }
                        if (0 === r)
                            for (this.min = n, o = this;
                                 (o = o.parent) && this.compare(o.min, n) > 0;) o.min = n;
                        if (r === e)
                            for (this.max = n, o = this;
                                 (o = o.parent) && this.compare(o.max, n) < 0;) o.max = n;
                        for (this.children.splice(r, 0, n), this.count += 1, o = this; o;) o.total += 1, o = o.parent;
                        return this.rebuild_(), this
                    }, n.prototype.insert_node_ = function(n, t) {
                        var e, r, o;
                        for (e = this.count, r = o = 0;
                             (e >= 0 ? e > o : o > e) && this.children[r].min !== n; r = e >= 0 ? ++o : --o);
                        return t.parent = this, this.count += 1, this.children.splice(r + 1, 0, t), this.rebuild_()
                    }, n.prototype.find_node_ = function(n) {
                        var t, e, r, i, u;
                        for (i = this; i.type === o;) {
                            if (t = i.children, e = i.count, this.compare(n, t[0].min) <= 0) r = 0;
                            else if (this.compare(n, t[e - 1].max) >= 0) r = e - 1;
                            else
                                for (r = u = 0;
                                     (e >= 0 ? e > u : u > e) && !(this.compare(t[r].max, n) >= 0); r = e >= 0 ? ++u : --u);
                            i = t[r]
                        }
                        return i
                    }, n.prototype.has = function(n) {
                        var t;
                        return t = this.find_node_(n), -1 !== t.children.indexOf(n)
                    }, n.prototype.replace_value = function(n) {
                        var t, e, r, o, i, u;
                        for (r = this.find_node_(n), t = r.children, e = i = 0, u = t.length; u > i; e = ++i) o = t[e], 0 === this.compare(o, n) && (t[e] = n);
                        return this
                    }, n.prototype.get_node_ = function(n) {
                        var t, e, r, i, u;
                        if (r = this, n >= this.total) return [null, null];
                        if (0 > n) return [null, null];
                        for (; r.type === o;)
                            for (e = r.children, i = 0, u = e.length; u > i; i++) {
                                if (t = e[i], !(n >= t.total)) {
                                    r = t;
                                    break
                                }
                                n -= t.total
                            }
                        return [r, n]
                    }, n.prototype.set_min_ = function(n) {
                        var t, e;
                        for (e = this, t = this.min; e && 0 === this.compare(e.min, t);) e.min = n, e = e.parent;
                        return this
                    }, n.prototype.set_max_ = function(n) {
                        var t, e;
                        for (e = this, t = this.max; e && 0 === this.compare(e.max, t);) e.max = n, e = e.parent;
                        return this
                    }, n.prototype.inc_total_ = function() {
                        var n;
                        for (n = this; n;) n.total += 1, n = n.parent;
                        return this
                    }, n.prototype.dec_total_ = function() {
                        var n;
                        for (n = this; n;) n.total -= 1, n = n.parent;
                        return this
                    }, n.prototype.clean_node_ = function() {
                        var n, t;
                        return this.parent ? (this.parent.delete_node_(this), this.type === i ? (null != (n = this.prev) && (n.next = this.next), null != (t = this.next) ? t.prev = this.prev : void 0) : void 0) : this.type = i
                    }, n.prototype.delete_node_ = function(n) {
                        var t;
                        return t = this.children.indexOf(n), this.children.splice(t, 1), this.count -= 1, 0 === this.count ? this.clean_node_() : (0 === t && this.set_min_(this.children[0].min), t === this.count ? this.set_max_(this.children[this.count - 1].max) : void 0)
                    }, n.prototype.delete_value_ = function(n) {
                        var t, e;
                        return t = this.children, e = this.indexOf_(n), -1 !== e && (t.splice(e, 1), this.count -= 1, this.dec_total_(), 0 === this.count ? this.clean_node_() : (0 === e && this.set_min_(t[0]), e === this.count && this.set_max_(t[this.count - 1]))), this
                    }, n.prototype.rebuild_ = function() {
                        var n, t, u;
                        if (!(this.count < r)) return null != this.parent ? (t = this.slice_(e, r - 1), t.parent = this.parent, this.count = e, this.total = this.total - t.total, this.children.splice(e, e), this.max = this.type === i ? this.children[e - 1] : this.children[e - 1].max, this.parent.insert_node_(this.min, t), this.type === i && (this.next && (this.next.prev = t), t.next = this.next, this.next = t)) : (n = this.slice_(0, e - 1), u = this.slice_(e, r - 1), n.parent = this, u.parent = this, n.next = u, u.prev = n, this.count = 2, this.children = [n, u], this.type = o), this
                    }, n.prototype.slice_ = function(t, e) {
                        var r, o, u, l, a, s, c, f;
                        if (u = e - t + 1, l = new n(this.options), l.count = u, l.type = this.type, o = this.children, this.type === i) l.min = o[t], l.max = o[e], l.children = o.slice(t, +e + 1 || 9e9), l.total = u;
                        else {
                            for (l.min = o[t].min, l.max = o[e].max, l.children = o.slice(t, +e + 1 || 9e9), a = 0, f = l.children, s = 0, c = f.length; c > s; s++) r = f[s], r.parent = l, a += r.total;
                            l.total = a
                        }
                        return l
                    }, n.prototype.atom = function() {
                        var n;
                        for (n = this; n.type === o;) n = n.children[0];
                        return n
                    }, n.prototype.indexOf_ = function(n) {
                        var t, e, r, o, i, u;
                        for (u = this.children, t = o = 0, i = u.length; i > o; t = ++o) {
                            if (r = u[t], e = this.compare(r, n), 0 === e) return t;
                            if (e > 0) return -1
                        }
                        return -1
                    }, n.prototype.insert = function(n) {
                        var t;
                        return t = this.find_node_(n), t.insert_value_(n), this
                    }, n.prototype["delete"] = function(n) {
                        var t;
                        return t = this.find_node_(n), t.delete_value_(n)
                    }, n.prototype.replace = function(n) {
                        return this["delete"](n), this.insert(n)
                    }, n.prototype.get = function(n) {
                        var t, e, r;
                        return 0 > n && (n += this.size()), r = this.get_node_(n), t = r[0], e = r[1], t ? t.children[e] : null
                    }, n.prototype.at = function(n) {
                        var t, e, r;
                        return 0 > n && (n += this.size()), r = this.get_node_(n), t = r[0], e = r[1], t ? t.children[e] : null
                    }, n.prototype.find = function(n) {
                        return this.find_all(n)[0]
                    }, n.prototype.find_all = function(n) {
                        var t, e, r, o, i, u, l;
                        if (o = [], t = this.find_node_(n), this.compare(n, t.min) < 0) return [];
                        if (this.compare(n, t.max) > 0) return [];
                        for (l = t.children, i = 0, u = l.length; u > i; i++)
                            if (r = l[i], e = this.compare(r, n), 0 === e) o.push(r);
                            else if (e > 0) break;
                        return o
                    }, n.prototype.slice = function(n, t) {
                        var e, r, o, i, u;
                        if (null == t && (t = this.total - 1), 0 > n && (n += this.total), 0 > t && (t += this.total), 0 > n && (n = 0), t >= this.total && (t = this.total - 1), u = this.get_node_(n), r = u[0], i = u[1], !r) return [];
                        for (o = t - n + 1, e = []; o && r;) i < r.count ? (e.push(r.children[i++]), --o) : (r = r.next, i = 0);
                        return e
                    }, n.prototype.flatten = function() {
                        var n, t, e, r, o, i;
                        for (r = [], o = this.atom(); o;) {
                            for (t = o.count, n = o.children, e = i = 0; t >= 0 ? t > i : i > t; e = t >= 0 ? ++i : --i) r.push(n[e]);
                            o = o.next
                        }
                        return r
                    }, n.prototype.dump = function(n) {
                        var e, r, o, u, l, a, s;
                        for (null == n && (n = 0), o = process.stdout, e = u = 0, s = this.count; s >= 0 ? s > u : u > s; e = s >= 0 ? ++u : --u)
                            if (this.type === i) {
                                for (r = l = 0; n >= 0 ? n > l : l > n; r = n >= 0 ? ++l : --l) o.write(t.Iz);
                                o.write(this.children[e] + t.Iz)
                            } else this.children[e].dump(n + 1);
                        for (r = a = 0; n >= 0 ? n > a : a > n; r = n >= 0 ? ++a : --a) o.write(t.Iz);
                        return o.write(t.AG + this.min + t.hQ + this.max + t.Dr + this.count + t.Hj + this.total + t.ou), this
                    }, n.prototype.delete_if = function() {}, n.prototype.size = function() {
                        return this.total
                    }, n
                }(),
                dt = n,
                null != Xr && (dt = dt),
                typeof module !== t.bQ && null !== module ? module.exports = dt : void 0
            }(), dr = pr = vr = or = ir = ur = lr = xr = yr = ar = sr = cr = hr = mr = fr = gr = Fr = br = null, g = null, tr = Fe = $r = null,
            // time-related functions
            function() {
                function n(n) {
                    var e;
                    return e = Fe["getHours"](n)
                }

                function e(n) {
                    var e;
                    return e = Fe["getMinutes"](n), "" + e + t.oH
                }

                function r(n) {
                    return x[Fe["getMonth"](n)]
                }

                function o(n) {
                    var r = Fe["getMonth"](n);
                    var e = Fe["getDate"](n);

                    return "" + x[r] + t.Iz + e;
                }

                function i(n, e) {
                    if (e == null) {
                        e = false;
                    }

                    if (e) {
                        return Fe["getHours"](n) + t.TC + tr(Fe["getMinutes"](n));
                    } else {
                        return Fe["getHours"](n) + t.TC + Fe["getMinutes"](n);
                    }
                }

                function u(n) {
                    return tr(Fe["getHours"](n)) + t.TC + tr(Fe["getMinutes"](n)) + t.TC + tr(Fe["getSeconds"](n));
                }

                function l(n) {
                    var e = Fe["getFullYear"](n);
                    var u = tr(Fe["getMonth"](n) + 1);
                    var r = tr(Fe["getDate"](n));
                    var o = tr(Fe["getHours"](n));
                    var i = tr(Fe["getMinutes"](n));

                    return "" + e + t.br + u + t.br + r + t.Iz + o + t.TC + i;
                }

                function a(n) {
                    return y[Fe["getDay"](n)]
                }

                function s(n, e) {
                    var r, o, i, u, a, s;

                    if (e >= 86400) {
                        r = Fe["getFullYear"](n);

                        a = tr(Fe["getMonth"](n) + 1);
                        o = tr(Fe["getDate"](n));

                        tr(Fe["getHours"](n));
                        tr(Fe["getMinutes"](n));

                        s = y[Fe["getDay"](n)];

                        return "" + r + t.br + a + t.br + o + t.EU + s + t.ih;
                    } else {
                        return l(n);
                    }
                }

                function c(n) {
                    var r = Fe["getFullYear"](n);
                    var l = Fe["getMonth"](n) + 1;
                    var o = Fe["getDate"](n);

                    var i = tr(Fe["getHours"](n));
                    var u = tr(Fe["getMinutes"](n));
                    var a = tr(Fe["getSeconds"](n));

                    var s = y[Fe["getDay"](n)];
                    var e = x[Fe["getMonth"](n)];

                    return "" + s + t.EU + o + t.Iz + e + t.Iz + i + t.TC + u + t.TC + a;
                }

                function f(n) {
                    var e, r, o, u, s;

                    var i = [
                        [86400, 86400, "day"],
                        [3600, 3600, "hour"],
                        [60, 60, "minute"],
                        [0, 1, "second"]
                    ];

                    var l = 0;

                    for (var a = i.length; a > l; l++) {
                        s = i[l];

                        e = s[0];
                        r = s[1];
                        o = s[2];

                        if (n >= e) {
                            u = parseFloat((n / r).toFixed(1));
                            if (u > 1) {
                                return u + t.Iz + o + t.Tp;
                            } else {
                                return u + t.Iz + o + t.To;
                            }
                        }
                    }

                    return null;
                }

                function h(n) {
                    var e, r, o, i, u, l, a, s;
                    for (i = [
                        [86400, 86400, t.Tn],
                        [3600, 3600, t.Tm],
                        [60, 60, t.Zq],
                        [0, 1, t.No]
                    ], l = 0, a = i.length; a > l; l++)
                        if (s = i[l], e = s[0], r = s[1], o = s[2], n >= e) return u = parseFloat((n / r).toFixed(1)), u > 1 ? u + "" + o + t.To : u + "" + o + t.To;
                    return null
                }

                function d(n) {
                    var e, r, o, i, u, l, a, s;
                    for (i = [
                        [86400, 86400, t.Tn],
                        [3600, 3600, t.Tm],
                        [60, 60, t.Zq],
                        [0, 1, t.No]
                    ], l = 0, a = i.length; a > l; l++)
                        if (s = i[l], e = s[0], r = s[1], o = s[2], Math.abs(n) >= e) return u = (n / r).toFixed(1), u + o;
                    return null
                }

                function p(n, e) {
                    var r, o, i, u, l, a, s, c;
                    if (null == e && (e = !0), 1000 > n) return parseFloat(n.toPrecision(3));
                    if (1e7 > n) return parseInt(n);
                    for (window.$is_zh ? (l = [
                        [1e8, t.jx, t.nH],
                        [10000, t.Th, t.Tg],
                        [1, "", ""]
                    ], i = 4) : (l = [
                        [1e9, t.Tf, t.Te],
                        [1e6, t.Td, t.nH],
                        [1000, t.Fg, t.Tg],
                        [1, "", ""]
                    ], i = 3), a = 0, s = l.length; s > a; a++)
                        if (c = l[a], o = c[0], u = c[1], r = c[2], n >= o) {
                            n /= o;
                            break
                        } return n = n.toPrecision(i), e && (n = parseFloat(n)), n + u
                }

                function v(n) {
                    return n > 10000 || !n ? parseInt(n) : parseFloat(n.toPrecision(4))
                }

                function b(n) {
                    return n >= 0 ? t.iM + (100 * n).toFixed(2) + t.Ta : (100 * n).toFixed(2) + t.Ta
                }

                function m(n) {
                    return n = parseFloat(n.toPrecision(12)), n >= 0 ? t.iM + n : n
                }

                function F(n) {
                    return parseFloat(n.toPrecision(4)).toFixed(12).replace(/(\..*?)[0]*$/g, t.cY)
                }

                var x, y, $, w, T, V, k;

                x = "Jan Feb Mar Apr May Jun Jul Aug Sep Oct Nov Dec".split(" ");
                y = "Sun Mon Tue Wed Thu Fri Sat".split(" ");

                Fe = {};
                k = [
                    'getDate',
                    'getYear',
                    'getFullYear',
                    'getMonth',
                    'getDay',
                    'getHours',
                    'getMinutes',
                    'getSeconds'
                ];

                w = function(n) {
                    var e = n.replace("get", "getUTC");

                    return (Fe[n] = function(t) {
                        if ($r()) {
                            return t[e]();
                        } else {
                            return t[n]();
                        }
                    });
                };

                T = 0;

                for (V = k.length; V > T; T++) {
                    $ = k[T];
                    w($);
                }

                return Fe["getTimezoneOffset"] = function(n) {
                    return $r() ? 0 : n.getTimezoneOffset()
                }, tr = function(n) {
                    return n = n.toString(), 1 === n.length ? "0" + n : n
                }, $r = function() {
                    return (typeof $conf !== t.bQ && null !== $conf ? $conf["time_zone"] : void 0) === "utc"
                }, dr = n, pr = e, vr = r, or = o, ir = l, ur = s, lr = c, xr = i, yr = u, ar = f, sr = h, cr = d, hr = a, mr = p, fr = v, gr = b, Fr = m, br = F, g = y, tr = tr, Fe = Fe, $r = $r
            }(), _r = Ir = Sr = Mr = null,
            // min / max value of series
            function() {
                function n(t, e) {
                    var r;

                    if (t[0] && t[0].length) {
                        t = (function() {
                            var u = [];
                            var o = 0;

                            for (var i = t.length; i > o; o++) {
                                r = t[o];

                                u.push(n(r, e));
                            }

                            return u;
                        })();

                        return n(t, e);
                    } else {
                        return e.apply(null, t);
                    }
                }

                function t(t) {
                    return n(t, Math.max);
                }

                function e(t) {
                    return n(t, Math.min);
                }

                function r(n) {
                    var e;

                    var r = (function() {
                        var i = [];
                        var t = 0;

                        for (o = n.length; o > t; t++) {
                            r = n[t];

                            i.push(
                                (function() {
                                    var o = [];
                                    var n = 0;

                                    for (var t = r.length; t > n; n++) {
                                        e = r[n];
                                        o.push(Math.abs(e));
                                    }

                                    return o;
                                })()
                            );
                        }

                        return i;
                    })();

                    return t(r);
                }

                return _r = Ir = Sr = null, _r = t, Ir = e, Sr = r, Mr = n
            }(), w = _ = Z = null, J = S = _ = X = T = M = I = H = V = k = x = y = P = null, Er = Hr = Br = Nr = Rr = Pr = Or = null, nr = xe = null,
            // handling trades
            function() {
                // returns the main candles object
                function n() {
                    var t = {};
                    var n = zt(d);

                    t[c] = n;
                    t[$] = 0;

                    // $ is step, in seconds

                    return t;
                }

                function t(n, t) {
                    var l;

                    var u = {};
                    var i = n[c][r][b];
                    var o = (l = i != null ? i[i.length - 1] : void 0) != null ? l : 0;

                    if (o >= t.tid) {
                        return;
                    } else {
                        u[ft] = t.tid;
                        u[ct] = t.date;
                        u[st] = t.price;
                        u[lt] = t.amount;
                        u[ht] = t.trade_type;
                        return e(n, u);
                    }
                }

                /* here real-time trades are rounded */
                function e(n, t) {
                    var r;
                    var o;
                    var x;

                    var e = n[c];

                    while(qt(e) > 3600) {
                        te(e);
                    }

                    t = ue(t);

                    if (n[$] === 604800) {
                        r = t[ct];
                        x = r + 259200 - ((r + 259200) % n[$]) - 259200;
                        t[ct] = x;
                    } else if (n[$] === 259200) {
                        t[ct] = round3dTime(t[ct]);
                    } else {
                        t[ct] = t[ct] - (t[ct] % n[$]);
                    }

                    var k = u(n, t[ct]);

                    // some boolean
                    var i = k[0];
                    // l is candles array size?
                    var l = k[1];

                    // updating candle is DONE HERE
                    if (i) {
                        m = Dt(e, l, d);

                        // m[p] = m[1] is candle's first trade id?
                        // m[b] = m[2] is candle's  last trade id?
                        // m[v] = m[5] is candle's  low price?
                        // m[g] = m[6] is candle's high price?
                        // m[f]        is candle's close price

                        // t[ft] is trade id
                        // t[st] is trade price
                        // t[ht] is trade side
                        // t[lt] is trade volume

                        if(m[R]) {
                            // if candle has newer trade id?
                            if(m[p] > t[ft]) {
                                m[F] = t[st];
                                m[p] = t[ft];
                            }

                            // if candles's last id is lower?
                            if(m[b] < t[ft]) {
                                m[f] = t[st];
                                m[b] = t[ft];
                            }

                            if(m[v] < t[st]) {
                                m[v] = t[st];
                            }

                            if(m[g] > t[st]) {
                                m[g] = t[st];
                            }
                        } else {
                        //    console.log('before', m);

                            m[p] = t[ft];
                            m[b] = t[ft];

                            m[F] = t[st];
                            m[f] = t[st];
                            m[v] = t[st];
                            m[g] = t[st];

                        //    console.log('after', m);
                        }

                        m[R] += t[lt];

                        // update data for ask / bid ratio
                        switch (t[ht]) {
                            case 'ask':
                                m[a] += t[lt];
                                break;
                            case 'bid':
                                m[s] += t[lt];
                        }

                        m[O] += t[lt] * t[st];

                        return oe(e, l, m);
                    }

                    // generate empty candle

                    var m = {};
                    m[B] = t[ct];

                    var S = [p, b];
                    var y = 0;

                    for (var T = S.length; T > y; y++) {
                        o = S[y];
                        m[o] = t[ft];
                    }

                    var M = [F, f, v, g];
                    var w = 0;

                    for (var V = M.length; V > w; w++) {
                        o = M[w];
                        m[o] = t[st];
                    }

                    m[R] = t[lt];
                    m[h] = new Date(1000 * m[B]);
                    m[s] = 0;
                    m[a] = 0;

                    switch (t[ht]) {
                        case 'ask':
                            m[a] = t[lt];
                            break;

                        case 'bid':
                            m[s] = t[lt];
                            break;
                    }

                    m[O] = t[st] * t[lt];

                //    console.log('generating empty candle', m);

                    return Gt(e, l, m);
                }

                function o(t, e) {
                    var o, l, a, s, d, x, y, w, T;

                    var i = n();
                    var r = i[c];
                    var u = (a = 0);

                    for (var m = e.length; m > a; u = ++a) {
                        l = e[u];
                        l = ue(l);
                        l[B] = parseInt(l[B]);

                        w = [F, f, v, g];
                        s = 0;

                        for (x = w.length; x > s; s++) {
                            o = w[s];
                            l[o] = parseFloat(l[o]);
                        }

                        T = [B, p, b];
                        d = 0;

                        for (y = T.length; y > d; d++) {
                            o = T[d];
                            l[o] = parseInt(l[o]);
                        }

                        l[R] = parseFloat(l[R]);
                        l[h] = new Date(1000 * l[B]);

                    //    console.log('o', l[B]);

                        Gt(r, u, l);
                    }

                    i[$] = parseInt(t);

                    return i;
                }

                function i(n) {
                    var e = {};
                    var t = zt();

                    t[r] = n[c][r].slice(0, d.length);
                    e[c] = t;
                    e[$] = n[$];

                    return e;
                }

                function u(n, t) {
                    var o;
                    var e = n[c];

                    if (!(o = Yt(e, B))) {
                        return [false, 0];
                    }

                    var r = o.length;

                    for (; r--; ) {
                        if (!(o[r] > t)) {
                            if (o[r] < t) {
                                break;
                            }

                            return [true, r];
                        }
                    }
                    return [false, r + 1];
                }

                // generate and handle a trade
                // containing data about the last candle's price and id
                function l(n) {
                    var ncLength = qt(n[c]);

                    // get last candle with params d
                    var t = Dt(n[c], ncLength - 1, d);

                    // init new trade
                    var r = {};

                    var candleTime = t[B];

                    var now = parseInt(Date.now() / 1000);

                    // do not add zero-trade to already existing candle
                    if(candleTime > now) {
                        return;
                    }

                    // trade time
                    r[ct] = now;

                    // trade id
                    r[ft] = t[b];

                    // trade price
                    r[st] = t[f];

                    // volume
                    r[lt] = 0;

                    // handle trade
                    return e(n, r);
                }

                var a, s, c, f, h, d, p, v, b, g, F, $, B, O, R, E, N;

                return E = m(3),
                    c = E[0], b = E[1], $ = E[2],
                    w = c, _ = b, Z = $,
                    N = [0, 1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11],
                    B = N[0], p = N[1], b = N[2], F = N[3], f = N[4], v = N[5], g = N[6], R = N[7], a = N[8], s = N[9], O = N[10], h = N[11],
                    d = N,
                    J = B,
                    S = p,
                    _ = b,
                    X = F,
                    T = f,
                    M = v,
                    I = g,
                    H = R,
                    V = h,
                    k = d,
                    x = a,
                    y = s,
                    P = O,
                    xe = function(n, t) /* here empty candles are generated (maybe) */ {
                        if (t === 604800) {
                            return n + 259200 - ((n + 259200) % t) - 259200;
                        } else if (t === 259200) {
                            return round3dTime(n);
                        } else {
                            return n - (n % t);
                        }
                    },
                    nr = function(n, t) {
                        var e, r, o, i, u, l, a, s;
                        for (u = [], i = xe(parseInt(Date.now() / 1000), t) - 900 * t, o = xe(parseInt(Date.now() / 1000), t), a = 0, s = n.length; s > a; a++)
                            if (e = n[a], !(e[J] < i)) {
                                for (; r && e[J] - r[J] > t;) l = [], l[J] = r[J] + t, l[S] = r[_], l[_] = r[_], l[X] = r[T], l[M] = r[T], l[I] = r[T], l[T] = r[T], l[x] = 0, l[y] = 0, l[H] = 0, l[P] = 0, l[V] = l[J], u.push(l), r = l;
                                u.push(e), r = e
                            } for (e && e[J] < i && (e[J] = i); e && e[J] < o;) l = [], l[J] = e[J] + t, l[S] = e[_], l[_] = e[_], l[X] = e[T], l[M] = e[T], l[I] = e[T], l[T] = e[T], l[x] = 0, l[y] = 0, l[H] = 0, l[P] = 0, l[V] = l[J], u.push(l), e = l;
                        return u
                    },
                    Er = n,
                    Hr = e,
                    Br = o,
                    Nr = i,
                    Rr = u,
                    Pr = t,
                    Or = l,
                    nr = nr,
                    xe = xe
            }(), ft = st = lt = ct = at = ht = null, jr = zr = null,
            // trade object
            function() {
                function n(n) {
                    var t = {};

                    t[u] = parseInt(n.tid);
                    t[o] = parseFloat(n.price);
                    t[e] = parseFloat(n.amount);
                    t[i] = parseInt(n.date);
                    t[r] = Date.now();
                    t[l] = n.trade_type;

                    return t;
                }

                function t(n) {
                    var t = {};

                    t[u] = parseInt(n.tid);
                    t[o] = parseFloat(n.price);
                    t[e] = parseFloat(n.amount);
                    t[i] = parseInt(n.date);
                    t[r] = Date.now();
                    t[l] = n.trade_type;

                    return t;
                }

                var a = m(7);

                var u = a[0];
                var o = a[1];
                var e = a[2];
                var i = a[3];
                var r = a[4];
                var l = a[5];

                ft = u;
                st = o;
                lt = e;
                ct = i;
                at = r;
                ht = l;

                jr = n;
                zr = t;

                return t;
            }(),
            lo = no = to = qr = ro = oo = eo = io = Gr = uo = null, mt = Ft = $t = wt = xt = yt = gt = vt = bt = null,
            function() {
                function n(n, t, e) {
                    var r = {};

                    if(e == null) {
                        e = !1;
                    }

                    r[p] = ue(n);
                    r[v] = ue(t);
                    r[f] = e ? g : b;
                    r[h] = 0;
                    r[wt] = null;

                    return r;
                }

                // probably gets candle using event
                function t(n) {
                    var t = {};

                    t[p] = ue(n[p]);
                    t[v] = ue(n[v]);
                    t[f] = n[f];
                    t[h] = n[h];
                    t[wt] = n[wt]

                    return t;
                }

                function e(n, t) {
                    var e = n[p];
                    var r = n[v];

                    return (t - r.x) / r.w * e.w + e.x
                }

                function r(n, t) {
                    var o, i, u, l;

                    var e = n[p];
                    var r = n[v];

                    if (n[f] === g) {
                        l = r.y;
                        u = r.y + r.h;

                        i = 0;

                        o = Math.log(u / l);
                        t = Math.log(t / l);

                        return ((t - i) / o) * e.h + e.y;
                    } else {
                        return ((t - r.y) / r.h) * e.h + e.y;
                    }
                }

                function o(n, t, o) {
                    return [e(n, t), r(n, o)]
                }

                function i(n, t) {
                    return Math.round(e(n, t))
                }

                function u(n, t) {
                    return Math.round(r(n, t))
                }

                function l(n, t, e) {
                    return [i(n, t), u(n, e)]
                }

                function a(n, t) {
                    return Math.round(e(n, t)) + .5
                }

                function s(n, t) {
                    return Math.round(r(n, t)) + .5
                }

                function c(n, t, e) {
                    return [a(n, t), s(n, e)]
                }

                var $ = m(8);
                var p = $[0];
                var v = $[1];
                var F = $[2];
                var x = $[3];
                var f = $[4];
                var h = $[5];

                var w = m(3);
                var b = w[0];
                var g = w[1];
                var d = w[2];

                lo = n;
                no = a;
                to = s;
                qr = c;
                ro = i;
                oo = u;
                eo = l;
                io = o;
                Gr = t;
                uo = r;

                mt = p;
                Ft = v;
                $t = F;
                wt = x;
                xt = b;
                yt = g;
                gt = d;
                vt = f;

                return (bt = h);
            }(),
            // themes
            function() {
                window.$theme_dark = {
                    Background: t.XS,
                    'Background Mask': t.ju,
                    'Main Text': t.SX,
                    'Minor Text': t.jF,
                    'Highlight Text': t.YG,
                    Border: t.jF,
                    Link: t.aC,
                    'Activated Link': t.Dp,
                    'Green Stroke': t.SS,
                    'Green Fill': t.ku,
                    'Red Stroke': t.SQ,
                    'Red Fill': t.Sw,
                    'Axis Background': t.ju,
                    'Axis Key Text': t.SO,
                    'Axis Text': t.UH,
                    'Green Arrow': t.RF,
                    'Red Arrow': t.SL,
                    'Arrow Text': t.bO,
                    Cross: t.SK,
                    'Stick Line': t.QR,
                    Colors: [t.Ns, t.SH, t.ow, t.pj, t.gb, t.zK],
                    ShapeColors: [t.hK, t.Zi, t.Zh, t.vU, t.Zf, t.Ze, t.Zd, t.mq],
                    'Green Area': t.SC,
                    'Red Area': t.qI,
                    'Minor Arrow': t.SA,
                    Shape: t.hK,
                    ShapeHint: t.Sz,
                    AxisCursorBg: t.DB,
                    AxisCursorFg: t.hK,
                    'Alarm Line': t.uI,
                    'Green Alarm': t.Dk,
                    'Red Alarm': t.Sv,
                    OHLC: '#6CC'
                };

                window.$theme_light = {
                    Background: t.SO,
                    'Background Mask': t.Su,
                    'Main Text': t.jF,
                    'Minor Text': t.SX,
                    'Highlight Text': t.Iu,
                    Border: t.SX,
                    Link: t.FM,
                    'Activated Link': t.Hp,
                    'Green Stroke': t.Yd,
                    'Green Fill': t.Yd,
                    'Red Stroke': t.Ux,
                    'Red Fill': t.Ux,
                    'Axis Background': t.Su,
                    'Axis Key Text': t.jF,
                    'Axis Text': t.Nm,
                    'Green Arrow': t.ZH,
                    'Red Arrow': t.Sm,
                    'Arrow Text': t.Iu,
                    Cross: t.Sl,
                    'Stick Line': t.OC,
                    Colors: [t.SB, t.US, t.ow, t.pj, t.gb, t.zK],
                    ShapeColors: [t.qo, t.kF, t.cg, t.JQ, t.JU, t.YN, t.ks],
                    'Green Area': t.PB,
                    'Red Area': t.Sg,
                    'Minor Arrow': t.Sf,
                    Shape: t.qo,
                    ShapeHint: t.fC,
                    AxisCursorBg: t.sC,
                    AxisCursorFg: t.hK,
                    'Alarm Line': t.hM,
                    'Green Alarm': t.ZH,
                    'Red Alarm': t.Sm,
                    OHLC: '#399'
                };

                window.$theme_dark2 = JSON.parse(JSON.stringify($theme_dark));
            }(),
            q = ot = j = D = it = Y = A = U = E = tt = G = C = N = ut = O = et = nt = rt = K = z = B = W = Q = L = R = null, Ar = Ur = Yr = Wr = Cr = Qr = null,
            // draw shapes related closure
            function() {
                // something candle related
                function n(n, t, e) {
                    if (t == null) {
                        t = [];
                    }

                    if (e == null) {
                        e = Z;
                    }

                    var r = {};

                    r[ht] = n;
                    r[lt] = t;
                    r[g] = e;
                    r[st] = H;
                    r[at] = false;

                    if (n === X) {
                        r[at] = true;
                    }

                    return r;
                }
                function e(n, e, r, o, i, u) {
                    e.textAlign = t.uO;
                    e.textBaseline = 'bottom';
                    e.font = t.nr;

                    var l = e.measureText(u).width;

                    e.fillStyle = n[ft][t.Za];
                    e.fillRect(r.x, i - 1 - 4 - 10, l + 8, 14);
                    e.fillStyle = n[ft][t.FL];

                    return e.fillText(u, r.x + 4, i - 1 - 2);
                }

                function r() {}


                /**
                 * draw line function
                 *
                 * @param params line params
                 * @param canvas canvas context
                 * @param evt some object with coordinates and canvas size
                 * @param timestamps visible timestamps
                 * @returns {f}
                 */
                function o(params, canvas, evt, timestamps) {
                    var i, u, l, a, s, c, f, h, d, p, v, b, m, x, y, $, w, T, V, k, S, M, _;

                    w = params[lt];

                    T = w[0]; // starting point [timestamp, price]
                    V = w[1]; // ending point [timestamp, price]

                    f = T[0];
                    p = T[1];

                    h = V[0];
                    v = V[1];

                    canvas.beginPath();

                    i = evt[mt];

                    b = i.y;
                    m = i.y + i.h;

                    if (h === f) {
                        for (i = evt[mt], a = y = 0, k = params[F];
                             (k >= 0 ? k >= y : y >= k) && (null != timestamps[a] ? c = timestamps[a] : c += params[ct], c !== f); a = k >= 0 ? ++y : --y);
                        S = io(evt, a, p),
                            u = S[0],
                            l = S[1],
                            f === h && p === v ?
                                (canvas.moveTo(0, l), canvas.lineTo(i.w, l)) :
                                (canvas.moveTo(u + params[pt] + .5, i.y), canvas.lineTo(u + params[pt] + .5, i.y + i.h))
                    } else
                        for (
                            params[g] === J &&
                            (p = Math.log(p), v = Math.log(v)),
                                s = (v - p) / (h - f),
                                c = 0,
                                x = [],
                                a = $ = 0,
                                M = params[F];
                            M >= 0 ? M >= $ : $ >= M; a = M >= 0 ? ++$ : --$)
                            null != timestamps[a] ? c = timestamps[a] : c += params[ct], d = s * (c - f) + p,
                            params[g] === J && (d = Math.exp(d)),
                                _ = io(evt, a, d),
                                //console.log(a, d, _),
                                u = _[0],
                                l = _[1],
                                l > -10000 && l < 2 * i.y && l > m && b > l && canvas.lineTo(u + params[pt], l);

                    return canvas.strokeStyle = params[ft][t.FL], canvas.stroke()
                }

                function i() {}

                function u(n, r, o) {
                    var f = n[lt][0];
                    var a = f[0];
                    var s = f[1];

                    r.beginPath();

                    var i = o[mt];
                    var h = eo(o, a, s);
                    var u = h[0];
                    var l = h[1];

                    Ee(r, l + 0.5, 0, i.w);
                    r.strokeStyle = n[ft][t.RT];
                    r.stroke();

                    var c = "" + s.toPrecision(5);

                    return e(n, r, i, u, l, c);
                }


                function l(n) {
                    return function(e, r, o) {
                        var i, u, l, a, s, c, f, h, d, p, v, b, g, m, F, x, y, $;
                        for (x = e[lt], y = x[0], f = y[0], p = y[1], $ = x[1], h = $[0], v = $[1], i = o[mt], i = o[mt], b = i.y, g = i.y + i.h, a = m = 0, F = n.length; F > m; a = ++m) d = n[a], l = v - (v - p) * d, u = to(o, l), u > g && b > u && (r.beginPath(), r.moveTo(i.x, u), r.lineTo(i.x + i.w, u), r.strokeStyle = e[ft][t.RT], r.stroke(), r.textAlign = t.uO, r.textBaseline = "bottom", r.font = t.nr, s = "" + (100 * d).toFixed(1) + t.kf + l.toPrecision(5), c = r.measureText(s).width, r.fillStyle = e[ft][t.Za], r.fillRect(i.x, u - 1 - 4 - 10, c + 8, 14), r.fillStyle = e[ft][t.FL], r.fillText(s, i.x + 4, u - 1 - 2));
                        return this
                    }
                }

                function a() {}

                function s() {}

                function c() {}

                function f(params, canvas, evt, timestamps) {
                    var i, u, l, a, s, c, f, p, y, $, w, T, M, _, I, X, Z;

                    var V = params[lt];

                    var k = V[0];
                    var S = V[1];

                    var h = k[0];
                    var v = k[1];

                    var d = S[0];
                    var b = S[1];

                    i = evt[mt];

                    var m = i.y;
                    var x = i.y + i.h;

                    if (d === h) {
                        canvas.beginPath();

                        i = evt[mt];
                        s = y = 0;

                        for (
                            M = params[F];
                            (M >= 0 ? M >= y : y >= M) &&
                            (timestamps[s] != null ? (f = timestamps[s]) : (f += params[ct]),
                            f !== h);
                            s = M >= 0 ? ++y : --y
                        ) {}

                        _ = io(evt, s, v);

                        u = _[0];
                        l = _[1];

                        canvas.moveTo(u + params[pt] + 0.5, l);

                        if (b > v) {
                            canvas.lineTo(u + params[pt] + 0.5, x);
                        } else {
                            canvas.lineTo(u + params[pt] + 0.5, m);
                        }

                        canvas.strokeStyle = params[ft][t.FL];
                        canvas.stroke();
                    } else {
                        if (params[g] === J) {
                            v = Math.log(v);
                            b = Math.log(b);
                        }

                        f = 0;
                        c = (b - v) / (d - h);
                        I = [0.382, 0.5, 0.618, 1];
                        a = $ = 0;

                        for (T = I.length; T > $; a = ++$) {
                            Jr = I[a];

                            canvas.beginPath();

                            s = w = 0;

                            for (X = params[F]; X >= 0 ? X >= w : w >= X; s = X >= 0 ? ++w : --w) {
                                if (timestamps[s] == null) {
                                    f += params[ct];
                                } else {
                                    f = timestamps[s];
                                }

                                if ((!(d > h) || !(h > f)) && (!(h > d) || !(f > h))) {
                                    p = Jr * c * (f - h) + v;

                                    if (params[g] === J) {
                                        p = Math.exp(p);
                                    }

                                    Z = io(evt, s, p);

                                    u = Z[0];
                                    l = Z[1];

                                    if (l > -10000 && l < 2 * i.y && l > x && m > l) {
                                        canvas.lineTo(u + params[pt], l);
                                    }
                                }
                            }

                            canvas.strokeStyle = params[ft][t.FL];
                            canvas.stroke();
                        }
                    }
                    return this;
                }

                function h(n, t, e, r) {
                    // actually draw figure / shape on canvas

                    // console.log(vt, n[ht], n[lt].length, vt[n[ht]][n[lt].length]);

                    return vt[n[ht]][n[lt].length](n, t, e, r)
                }

                function d(n, t) {
                    var e = n[lt];
                    return e.push(t);
                }

                function p(n) {
                    var e = n[lt];

                    if (e.length === vt[n[ht]][t.Zq]) {
                        n[st] = k;

                        return true;
                    } else {
                        e.push(e[e.length - 1]);

                        return false;
                    }
                }

                function v(n, t) {
                    var e = n[lt];

                    if (e.length === 0) {
                        e.push(t);
                    } else {
                        e.splice(-1, 1, t);
                    }

                    return n;
                }

                function b(n) {
                    var t;

                    var e = {};
                    var i = [ht, lt, g];

                    var r = 0;

                    for (var o = i.length; o > r; r++) {
                        t = i[r];

                        e[t] = n[t];
                    }

                    return e;
                }

                var g, m, F, x, y, $, w, T, V, k, S, M, _, I, X, Z, J, P, H, lt, at, st, ct, ft, ht, dt, pt, vt, bt;
                // ["TYPE", "POINTS", "ORDER", "AXIS", "FORMULA", "STATUS", "XFIX", "BLOCK_LIMIT", "STEP", "SNAP",
                // "THEME", "PARTIAL", "FINISHED", "LINEAR", "LOG", "BILOG", "LINE", "VLINE", "HLINE", "FVLINE",
                // "FHLINE", "FARC", "FAN", "FHLINEEX", "FLINE", "FANC", "COLOR"]

                bt = t.YR.split(/[,\s]+/);

                ht = bt[0];
                lt = bt[1];
                P = bt[2];
                g = bt[3];
                M = bt[4];
                st = bt[5];
                pt = bt[6];
                F = bt[7];
                ct = bt[8];
                at = bt[9];
                ft = bt[10];
                H = bt[11];
                k = bt[12];
                Z = bt[13];
                J = bt[14];
                m = bt[15];
                X = bt[16];
                dt = bt[17];
                I = bt[18];
                _ = bt[19];
                T = bt[20];
                y = bt[21];
                $ = bt[22];
                V = bt[23];
                S = bt[24];
                w = bt[25];
                x = bt[26];

                vt = {};

                vt[X] = { 1: r, 2: o, m: 2 };

                vt[S] = vt[X];

                vt[dt] = { 1: i, m: 1 };
                vt[I] = { 1: u, m: 1 };

                vt[T] = {
                    1: function() {},
                    2: l([0, 0.236, 0.382, 0.5, 0.618, 0.786, 1]),
                    m: 2
                };

                vt[V] = {
                    1: function() {},
                    2: l([0, 0.236, 0.382, 0.5, 0.618, 0.786, 1, 1.618, 2.618, 4.236]),
                    m: 2
                };

                vt[_] = { 1: a, 2: s, m: 2 };
                vt[$] = { 1: c, 2: f, m: 2 };

                vt[w] = vt[$];

                q = lt;
                ot = ht;
                j = P;
                D = X;
                it = dt;
                Y = I;
                A = _;
                U = T;
                E = y;
                tt = st;
                G = H;
                C = k;
                N = $;
                ut = pt;
                O = F;
                et = ct;
                nt = at;
                rt = ft;
                K = Z;
                z = J;
                B = g;
                W = V;
                Q = S;
                L = w;
                R = x;

                Ar = n;
                Ur = d;
                Yr = v;
                Wr = p;
                Cr = h;
                Qr = b;
            }(),
            function() {
                var n, e = this;
                $(function() {
                    function r(n) {
                        var o = n.outerWidth();
                        var t = n.outerHeight();
                        var r = ($(window).height() - t) / 3;
                        var e = ($(window).width() - o) / 2;

                        return n.css({ left: e, top: r });
                    }

                    function o(n) {
                        var u, c;
                        var r = c = 0;

                        var o = n.match(/(\d+)-(\d+)-(\d+) (\d+):(\d+):([\d\.]+)([\+\-]\d+)/);

                        for (var f = o.length; f > c; r = ++c) {
                            u = o[r];
                            o[r] = parseInt(u, 10);
                        }

                        var h = o;

                        var s = h[0];
                        var l = h[1];
                        o = h[2];
                        var t = h[3];
                        var e = h[4];
                        r = h[5];
                        var i = h[6];
                        var a = h[7];

                        n = new Date(l, o - 1, t, e, r, i);
                        n.setMinutes(n.getMinutes() - n.getTimezoneOffset() - 60 * a);

                        return n;
                    }

                    function i(n, e, r) {
                        var o = $.ajax({
                            url: '/api/user/' + window.$user_id + '/' + n,
                            type: 'POST',
                            dataType: 'json',
                            data: e
                        });

                        o.done(function(n) {
                            if (n != null ? n.ok : void 0) {
                                return r(n);
                            } else if (n != null ? n.error : void 0) {
                                return r(n);
                            } else {
                                return r({ error: 1, reason: 'Unknown error' });
                            }
                        });

                        return o.fail(function(n, e, o) {
                            if (e === "error") {
                                e = "";
                            }

                            var i = o || e || "request failed.";
                            return r({ error: 1, reason: i });
                        });
                    }

                    // poor choice here, but to keep it simple
                    // setting up a few vars to keep track of things.
                    // at issue is these values need to be encapsulated
                    // in some scope other than global.

                    var lastPosX = 0;
                    var lastPosY = 0;
                    var isDragging = false;

                    function handleDrag(ev) {
                        // for convience, let's get a reference to our object
                        var elem = $(ev.target).parents('.dialog')[0];

                        // DRAG STARTED here,
                        // let's snag the current position
                        // and keep track of the fact that we're dragging
                        if ( !isDragging ) {
                            isDragging = true;

                            lastPosX = elem.offsetLeft;
                            lastPosY = elem.offsetTop;
                        }

                        // we simply need to determine where the x,y of this
                        // object is relative to where it's "last" known position is
                        // NOTE:
                        //    deltaX and deltaY are cumulative
                        // Thus we need to always calculate 'real x and y' relative
                        // to the "lastPosX/Y"
                        var posX = ev.deltaX + lastPosX;
                        var posY = ev.deltaY + lastPosY;

                        // move our element to that position
                        elem.style.left = posX + "px";
                        elem.style.top = posY + "px";

                        // DRAG ENDED
                        // this is where we simply forget we are dragging
                        if (ev.isFinal) {
                            isDragging = false;
                        }
                    }

                    function s(n) {
                        function e() {
                            var n;
                            var e = 0;

                            for (var r = a.length; r > e; e++) {
                                n = a[e];
                                n.removeClass("selected");
                            }

                            return i.hide();
                        }

                        var o, i, a, s, c, f, h, d;

                        i = $("#dlg_" + n.name)
                        a = [];
                        o = [];

                        var eventWrapper = $(".tablist", i)[0];

                        if(eventWrapper) {
                            var mc = new Hammer(eventWrapper);

                            mc.add(new Hammer.Pan({direction: Hammer.DIRECTION_ALL, threshold: 0}));
                            mc.on("pan", handleDrag);
                        }

                        d = n.tabs;

                        c = function(n) {
                            var u, l;

                            a.push((l = $(".link_" + n)));
                            o.push((u = $(".content_" + n, i)));

                            return l.click(function() {
                                var n;

                                if (i.is(':visible') && u.is(':visible') && !$.contains(i[0], this)) {
                                    e();

                                    return false;
                                }

                                var s = 0;

                                for (var f = o.length; f > s; s++) {
                                    n = o[s];
                                    n.hide();
                                }

                                var c = 0;

                                for (var h = a.length; h > c; c++) {
                                    n = a[c];
                                    n.removeClass("selected");
                                }

                                l.addClass("selected");

                                $(".error", u).hide();
                                $(".rt", u).hide();
                                $(".ok", u).hide();

                                u.trigger("change");

                                u.show();
                                i.show();

                                if (!i.created) {
                                    i.css("position", "absolute");

                                    r(i);

                                    i.created = true;
                                }

                                $("input:visible:first", u).focus();

                                return false;
                            });
                        };


                        for (f = 0, h = d.length; h > f; f++) {
                            s = d[f];

                            c(s);
                        }

                        $(".close", i).on("touchstart click", function() {
                            e();
                        });

                        $(window).on("resize", function() {
                            return r(i)
                        });

                        return i
                    }

                    return n = {},
                    function() {
                        var r;
                        var n = { name: "passport", tabs: [t.RA, t.aH, t.Ry, t.sm] };
                        var e = s(n);
                        var a = [t.aH, t.RA];

                        var o = function(n) {
                            var r = $('.content_' + n, e);
                            var o = $(t.an, r);
                            $(t.Rv);
                            var u = $('.error', r);
                            return o.bind(t.Ru, function() {
                                (function() {
                                    var e;
                                    var a;

                                    u.fadeOut(t.Rt);

                                    var l = o.serializeArray();

                                    i(t.YL + n, l, function() {
                                        a = arguments[0];
                                        if (a.error) {
                                            $('.error', r)
                                                .text(a.reason)
                                                .fadeIn(t.Rt);
                                            return $(o[0][a.name]).focus();
                                        } else {
                                            e = n === t.aH ? t.Rs : t.Tl;
                                            r.append(t.bJ + e + t.YJ);
                                            o.hide();
                                            return setTimeout(function() {
                                                o.unbind(t.Ru);
                                                o[0].action = window.location.href;
                                                o[0].method = 'POST';
                                                return o.submit();
                                            }, 400);
                                        }
                                    });
                                })();
                                return false;
                            });
                        };

                        var u = 0;

                        for (var l = a.length; l > u; u++) {
                            r = a[u];
                            o(r);
                        }

                        return $("input[name=recover_password]").click(function() {
                            var n = $(t.DU);
                            var e = $(t.an, n);
                            return (function() {
                                var r;
                                i(t.Ro, e.serializeArray(), function() {
                                    r = arguments[0];
                                    if (r.error) {
                                        $('.error', n)
                                            .text(r.reason)
                                            .fadeIn(t.Rt);
                                        return $(e[0][r.name]).focus();
                                    } else {
                                        return $('.error', n)
                                            .html('')
                                            .append($(t.bJ).html(t.FC + r.email + t.ih))
                                            .fadeIn(t.Rt);
                                    }
                                });
                            })();
                        });
                    }(),
                    function() {
                        function n() {
                            var n, e, r, u, a, s, c, f, h;
                            i(t.oR, {}, function() {
                                var i, d, p;
                                if (c = arguments[0], !c.error) {
                                    for (c.is_premium === t.Ra ? (r = ir(o(c.expires_on)), u = ((o(c.expires_on).getTime() - Date.now()) / 86400 / 1000).toFixed(0), f = u > 1 ? t.No : "", $(t.QZ, l).html(t.hX + r + t.mS + u + t.Yr + f + t.qL)) : $(t.QZ, l).html(t.Yp), n = $(t.QY, l), p = $(t.sZ), i = 0, d = p.length; d > i; i++) a = p[i], s = $(a), e = s.attr(t.sp), h = t.Yo + c.address + t.Yn + e + t.Ym, h = t.Yo + c.address + t.Bk, s.attr(t.eH, h);
                                    return n.html("" + c.address), $(".qr", l).attr("data", $(t.sZ).attr(t.eH)), $(t.ef).val(c.display_name), $(t.BH).text(c.username)
                                }
                            })
                        }

                        function e(n, e, r) {
                            return e.error ? n.attr(t.QQ, t.QP).text(e.reason).fadeIn(t.Rt) : n.attr(t.QQ, t.QO).text(r).hide().fadeIn(t.Rt)
                        }

                        function r(n, e) {
                            var r;
                            return r = 0,
                                function(o) {
                                    var i;
                                    return r++, r >= n ? o() : (i = t.Yi, e.show().text(i), setTimeout(function() {
                                        return r = 0, e.text() === i ? e.hide().text("") : void 0
                                    }, 2000))
                                }
                        }
                        var u, l;
                        return u = {
                            name: t.Rn,
                            tabs: [t.Rm, t.Ry, t.Av, t.Rk, t.co, t.FF]
                        }, l = s(u), $(t.Md, l).change(function() {
                            var n, e, r;
                            e = $(this), e.html(t.vd), i(t.hR, {}, function() {
                                var i, u, l, a, s, c, f, h, d;
                                return r = arguments[0], r.error ? e.html(t.dV) : (n = function() {
                                    var n, e, p, v, b, g, m;
                                    for (b = r.orders, m = [], n = 0, p = b.length; p > n; n++) switch (f = b[n], a = f.info, i = ir(o(f.created_at)), u = ir(o(a.expires_on)), c = ir(o(a.old_expires_on)), a.type) {
                                        case "premium":
                                        case "premium_direct":
                                            for (g = [t.QH, t.UV, t.OQ], e = 0, v = g.length; v > e; e++) l = g[e], s = a[l], s ? (d = s > 1 ? t.No : "", a[l + t.Rc] = s >= 10000 ? t.Iz + parseInt(s) + t.Iz + l.slice(0, -1) + d : t.Iz + parseFloat(s.toPrecision(4)) + t.Iz + l.slice(0, -1) + d) : a[l + t.Rc] = "";
                                            h = "", a.remark && (h += t.hr + a.remark), a.tx_hash && (h += t.YE + a.tx_hash + t.Fi + a.tx_hash.slice(0, 25) + t.YC), m.push(t.AB + i + t.OJ + a.years_str + a.months_str + a.days_str + t.IP + c + t.pG + u + h + t.Yx);
                                            break;
                                        default:
                                            m.push(void 0)
                                    }
                                    return m
                                }(), n.length ? e.html(t.la + n.join("") + t.RM) : e.html(t.rL))
                            })
                        }), $(t.UZ, l).change(n), n(),
                            function() {
                                var n, e;
                                return n = $(t.QN, l), e = $(t.an, n), e.bind(t.Ru, function() {
                                    var n = this;
                                    return function() {
                                        var e, r, o;
                                        e = $(t.aU, n), e.fadeOut(t.Rt), r = $(n).serializeArray(), i(t.oE, r, function() {
                                            return o = arguments[0], o.error ? e.attr(t.QQ, t.Wn).text(o.reason).fadeIn(t.Rt) : (e.attr(t.QQ, t.vy).text(t.Yk + o.mode_name + t.xt).fadeIn(t.Rt), $(t.QI).val("")), (null != o ? o.mode_name : void 0) === t.ad ? $(t.cS).text(o.display_name) : void 0
                                        })
                                    }(), !1
                                })
                            }(),
                            function() {
                                var n, o, u, a, s, c;
                                return n = $(t.cG, l), a = $(t.Gp, n).val(), u = $(t.PZ, n), o = $(".rt", n), s = 0, c = r(2, o), n.change(function() {
                                    var n, r, l = this;
                                    u.html(""), o.text("Loading...").show(), i(t.dE, {}, function() {
                                        var s, c, f, h;
                                        if (n = arguments[0], n.error) e(o, n, t.Gl);
                                        else
                                            for (o.text("").fadeOut(), h = n.sessions, s = function(n) {
                                                var r, l;
                                                return r = a.replace(/\[(.*)\]/g, function(e, r) {
                                                    var o;
                                                    return o = n[r], n[r] || t.Yf
                                                }), l = $(r), u.append(l), $(t.Ml, l).click(function() {
                                                    var r;
                                                    i(t.uB, {
                                                        ip: n.logined_ip
                                                    }, function() {
                                                        return r = arguments[0], e(o, r, t.yz + n.logined_ip + t.fr), r.error ? void 0 : l.remove()
                                                    })
                                                })
                                            }, c = 0, f = h.length; f > c; c++) r = h[c], s(r);
                                        return l
                                    })
                                })
                            }(), $(t.Pt).click(function() {
                            var n, e;
                            n = $(t.Hc), n.css(t.Qx, "auto"), i(t.WD, {}, function() {
                                return e = arguments[0], e.error ? n.html($(t.Qv).text(e.reason).show()) : (n.html(t.Re), $.removeCookie(t.Ao, {
                                    path: "/"
                                }), window.location.href = window.location.href)
                            })
                        })
                    }(),
                    function() {
                        var n = { name: 'assist', tabs: ['alarm'] };
                        return s(n);
                    }(),
                    function() {
                        var n = { name: 'estimate_trading', tabs: ['estimate_trading'] };
                        return s(n);
                    }(),
                    function() {
                        var n = { name: 'draw', tabs: ['draw'] };
                        return s(n);
                    }(),
                    function() {
                        var n = { name: 'warning', tabs: ['warning'] };
                        return s(n);
                    }(),
                    function() {
                        var n = { name: 'alarm', tabs: ['alarm'] };
                        return s(n);
                    }(),
                    function() {
                        var n = { name: 'aggregated_heatmap_widget', tabs: ['aggregated_heatmap_widget'] };
                        return s(n);
                    }(),
                    $(".qr").hover(
                        function() {
                            var r = $("#qr");
                            var i = $(this).offset();
                            var e = i.left;
                            var o = i.top;

                            var n = $(this).attr("data");

                            $("img", r)
                                .hide()
                                .attr(t.EC, t.Yb + encodeURIComponent(n) + t.kV)
                                .load(function() {
                                    var t = $(this);

                                    var i = t.width();
                                    var n = t.height();

                                    r.css({ left: e - i - 24, top: o - n / 2 });

                                    return t.show();
                                });
                            return r.show();
                        },
                        function() {
                            return $("#qr").hide();
                        }
                    ),
                    window.$p ? $(".cond_np").hide() : $(".cond_p").hide(), e
                })
            }(),
            // window.call_api
            function() {
                window.call_api = function(n, e, r) {
                    if(window.$is_flutter_view) {
                        return;
                    }

                    var o = $.ajax({
                        url: '/api/user/' + window.$user_id + '/' + n,
                        type: 'POST',
                        dataType: 'json',
                        data: e
                    });

                    o.done(function(n) {
                        if (n && n.ok) {
                            return r(n);
                        } else if (n && n.error) {
                            return r(n);
                        } else {
                            return r({ error: 1, reason: 'Unknown error' });
                        }
                    });

                    return o.fail(function(n, e, o) {
                        if (e === 'error') {
                            e = '';
                        }

                        var i = o || e || 'request failed.';

                        return r({
                            error: 1,
                            reason: i
                        });
                    });
                };
            }(),
            pe = ie = null,
            function() {
                if (window.AudioContext == null) {
                    window.AudioContext =
                        window.webkitAudioContext ||
                        window.mozAudioContext ||
                        window.oAudioContext ||
                        window.msAudioContext;
                }

                pe = function(n) {
                    return new Error("Compatibility error: " + n + " is not supported.");
                };

                ie = function() {
                    if (typeof Worker === "undefined" || Worker === null) {
                        return pe("WebWorker");
                    } else if (typeof Blob === "undefined" || Blob === null) {
                        return pe("Blob");
                    } else if (typeof AudioContext === "undefined" || AudioContext === null) {
                        return pe("AudioContext");
                    } else {
                        return null;
                    }
                };

                pe = pe;
                ie = ie;
            }(),
            Tt = null,
            function() {
                var n;
                var e;

                if (ie()) {
                    Tt = null;
                } else {
                    n = null;

                    e = function() {
                        var e;

                        if (!n) {
                            n = new Worker("/js/interval.js");
                            e = [];

                            return (n.onmessage = function() {
                                var n;
                                var r;
                                var i;
                                var u;
                                var o = e.length;

                                for (var t = (i = 0); o >= 0 ? o > i : i > o; o >= 0 ? ++i : --i) {
                                    u = e.shift();

                                    r = u[0];
                                    n = u[1];

                                    r -= 16;

                                    if (r > 0) {
                                        e.push([r, n]);
                                    } else {
                                        n();
                                    }
                                }
                                return null;
                            });
                        }
                    };

                    Tt = function(n, t) {
                        e();
                        return queues.push([n, t]);
                    };
                }

                Tt = Tt;
            }(),
            kt = null,
            function() {
                kt = function(n) {
                    return new buzz.sound("/assets/" + n, { formats: ["ogg", "mp3", "aac"] });
                };

                kt = kt;
            }(),
            be = ge = ve = null,
            a = c = f = s = null,
            function() {
                function n() {
                    var n, r, o, u, l, a;
                    for (n = {}, null == n[h] && (n[h] = {}), a = $(t.Me), o = function(r) {
                        var o, u, l, a, s, c;
                        for (u = $(r), l = $(t.bc, u), c = [], a = 0, s = l.length; s > a; a++) o = l[a], c.push(function(r) {
                            var o, u, l, a, s, c, f, d, p, v;
                            for (l = $(r), s = l.attr(t.XM), a = $(".v", l), n[s] = null, o = null != (f = n[h])[s] ? (f = n[h])[s] : f[s] = {}, o[t.hm] = a, d = function(r) {
                                var i, u, l;
                                return u = $(r), l = u.attr(t.QX), o[l] = u, i = u.attr(t.Qh), (i || !o[t.Qh]) && (o[t.Qh] = u), $(r).click(function() {
                                    return e(n, s, l)
                                })
                            }, p = 0, v = a.length; v > p; p++) u = a[p], d(u);
                            return o[t.Qh] && e(n, s, o[t.Qh].attr(t.QX)), c = l.attr(t.fD), c ? i(n, l, c) : void 0
                        }(o));
                        return c
                    }, u = 0, l = a.length; l > u; u++) r = a[u], o(r);
                    return n
                }

                function e(n, e, r) {
                    var o, i, a, s, c, f, d, p, v, b, g, m, F, x;
                    if (s = null != (v = n[h]) ? null != (b = v[e]) ? b[t.hm] : void 0 : void 0, s && (s.removeClass("active"), a = null != (g = n[h]) ? null != (m = g[e]) ? m[r] : void 0 : void 0)) {
                        if (a.addClass("active"), n[e] = r, i = null != (F = n[l]) ? F[e] : void 0)
                            for (c = 0, d = i.length; d > c; c++) o = i[c], typeof o === t.Mg && o(r);
                        if (null != n[u])
                            for (x = n[u], f = 0, p = x.length; p > f; f++) o = x[f], typeof o === t.Mg && o(e, r);
                        return n
                    }
                }

                function r(n, t) {
                    switch (arguments.length) {
                        case 2:
                            n = arguments[0];
                            t = arguments[1];
                            if (n[u] == null) {
                                n[u] = [];
                            }
                            n[u].push(t);
                            break;
                        case 3:
                            o.apply(null, arguments);
                    }
                    return n;
                }

                function o(n, t, e) {
                    var r;
                    return null == n[l] && (n[l] = {}), null == (r = n[l])[t] && (r[t] = []), n[l][t].push(e), n
                }

                function i(n, e, o) {
                    var i, u, l, a, s, c, f;
                    for (e.hide(), o = o.split(t.mJ), i = {}, s = 0, c = o.length; c > s; s++) u = o[s], f = u.split(t.TC), l = f[0], a = f[1], null == i[l] && (i[l] = []), i[l].push(a);
                    return r(n, l, function(n) {
                        return co.call(i[l], n) >= 0 ? e.show() : e.hide()
                    }), this
                }
                var u, l, h, d, p;
                return p = m(4), u = p[0], h = p[1], d = p[2], l = p[3], be = n, ge = e, ve = r, a = u, c = h, f = d, s = l
            }(), Vr = null, Dr = null,
            function() {
                var n, e, a, s, f, h, g, F, S, Z, E, C, A, j, G, tt, it, dt, pt, $t, Tt, St, Nt, Ut, Wt, Qt, At, Kt, zt, jt, Gt, qt, ne, te, re, oe, ie, se, he, pe, xe, ye, we, Ve, ke, Me, Xe, Ze, Je, Oe, Ne, Le, Ue, Ce, Qe, Ye, je, Ge, qe, tr, ur, pr, Fr, Fr2, Fr3, Tr, kr, Mr, Xr, Zr, Pr, Rr, Er, Ur, qr, no, to, io, fo, ho, po, vo, bo, go, mo, Fo, xo, yo, $o, wo, To, Vo, ko, So, Mo, _o, Io, Xo, Zo, Jo, Po, Ho, Bo, Oo, Ro, Eo, No, Lo, Uo, Wo, Co, Qo, Ao, Yo, Do, Ko, zo, jo, Go, qo, ni, ti, ei, ri, oi, ii, ui, li, ai, si, ci, fi, hi, di, pi, vi, bi, gi, mi, Fi, xi, yi, $i, wi, Ti, Vi, ki, Si, Mi, _i, Ii, Xi, Zi, Ji, Pi, Hi, Bi, Oi, Ri, Ei, Ni, Li, Ui, Wi, Ci, Qi, Ai, Yi, Di, Ki, zi, ji, Gi, qi, nu, tu, eu, ru, ou, iu, uu, lu, au, su, cu, fu, hu, du, pu, vu, bu, gu, mu, Fu, xu, yu, $u, wu, Tu, Vu, ku, kuHeight, Su, Mu, _u, Iu, Xu, Zu, Ju, Pu, Hu, Bu, Ou, Ru, Eu, Nu, Lu, Wu, Cu, Qu, Au, Yu, Du, Ku, zu, ju, Gu, qu, nl, tl, el, rl, ol, il, ul, ll, al, sl;

                var DrawingsStore = function() {
                    const obj = {};

                    var prefix = "drawing_";

                    obj.set = function(key, drawing) {
                        var keys = obj.getKeys();

                        // new entry
                        if(!key) {
                            key = 'local_' + (keys.length + 1);
                        }

                        var value = {
                            ok: 1,
                            data: {
                                drawing: drawing,
                                subid: (keys.length + 1),
                                id: key,
                                updated_at: (+new Date()) / 1000,
                                symbol: window.$symbol
                            }
                        };

                        localStorage.setItem(prefix + key, JSON.stringify(value));

                        return value;
                    }

                    obj.has = function(key) {
                        return localStorage.getItem(prefix + key) !== null;
                    }

                    obj.get = function(key) {
                        var item = localStorage.getItem(prefix + key);

                        if(!item) {
                            return null;
                        }

                        return JSON.parse(item);
                    }

                    obj.delete = function(key) {
                        return localStorage.removeItem(prefix + key);
                    }

                    obj.getRaw = function(key) {
                        var item = localStorage.getItem(key);

                        if(!item) {
                            return null;
                        }

                        return JSON.parse(item);
                    }

                    obj.getKeys = function() {
                        var res = [];

                        for (var key in localStorage) {
                            if(key.indexOf(prefix) >= 0) {
                                res.push(key);
                            }
                        }

                        return res;
                    }

                    obj.getAll = function(symbol) {
                        var res = {
                            ok: 1,
                            data: []
                        };

                        var keys = obj.getKeys();

                        for(var key in keys) {
                            var item = obj.getRaw(keys[key]);

                            if(item && item.data && item.data.symbol === symbol) {
                                res.data.push(item.data);
                            }
                        }

                        return res;
                    }

                    return obj;
                }();

                var scaleGraph;
                var lockCross;
                var autoScale = true;
                var drawChartInfo = false

                var HeatmapCanvas;

                var redrawCross, redrawMain, redrawShapes, redrawHeatmap;

                $(function() {
                    function cl() {
                        var n = new Date();
                        if ($r()) {
                            return pr.text(lr(n) + " UTC");
                        } else {
                            return pr.text(lr(n));
                        }
                    }

                    function fl(n) {
                        return Ge[n]
                    }

                    function hl(cookieName, cookieValue, saveConfig) {
                        if (saveConfig == null) {
                            saveConfig = {};
                        }

                        var cookieParams = {};

                        cookieParams.path = '/';

                        if(saveConfig.mode !== 'session') {
                            cookieParams.expires = 3650;
                        }

                        if(saveConfig.wildcardDomain) {
                            cookieParams.domain = '.bitcoinwisdom.io';
                        }

                        return $.cookie(cookieName, cookieValue, o);
                    }

                    function dl(n, t) {
                        return n > t
                    }

                    // measure run time?
                    function pl(n, t) {
                        var r = Date.now();
                        window.Measurer(n).start();
                        t();
                        window.Measurer(n).stop();

                        return Date.now() - r;
                    }

                    function vl(n, t) {
                        var e, r, o, i, u, l;
                        for (o = null, r = i = 8; i >= -8; r = --i) {
                            for (u = 0, l = n.length; l > u; u++)
                                if (e = n[u], ol = parseFloat((e * Math.pow(10, r)).toPrecision(8)), !(ol / $init_price > t)) {
                                    o = ol;
                                    break
                                } if (o) break
                        }
                        return null == o && (o = 1e-8), o
                    }

                    function bl() {
                        lockCross = true;

                        Ol("Cross is locked.");

                        return Iu.addClass("locked");
                    }

                    function gl() {
                        lockCross = false;

                        Ol("Cross is unlocked.");

                        return Iu.removeClass("locked");
                    }

/*                    function bl() {
                        autoScale = true;

                        Ol("Auto-scaling enabled.");
                    }

                    function gl() {
                        autoScale = false;

                        Ol("Auto-scaling disabled.");
                    }*/

                    /**
                     * Clear canvas
                     *
                     * @param n
                     */
                    function ml(n, width, height) {
                        var preserveTransform = false;

                        if (preserveTransform) {
                            n.save();
                            n.setTransform(1, 0, 0, 1, 0, 0);
                        }

                        if(!width) {
                            width = n.canvas.width;
                        }

                        if(!height) {
                            height = n.canvas.height;
                        }

/*
                        switch(n.canvas.id) {
                            case "canvas_shapes":
                                n.canvas.width = n.canvas.width;
                            default:
                                n.clearRect(0, 0, n.canvas.width, n.canvas.height);
                                break;
                        }
*/
                        n.clearRect(0, 0, width, height);

                        if (preserveTransform) {
                            n.restore();
                        }
                    }

                    function Fl() {
                        var n, e, r, o, i, u, l, a, s;

                        var c = [Oi, Bi, Ri, HeatmapCanvas];

                        Si = Eu.width() - Ou.width() - Mu.width();
                        ki = Eu.height() - ku.height() - (Vu.is(":visible") ? Vu.height() : 0);

                        ki -= ki % 2;
                        Si -= Si % 2;

                        if (Oi.width !== Si || Oi.height !== ki) {
                            yu.css("width", Si - 8);
                            Iu.height(ki);

                            for (a = 0, s = c.length; a < s; a++) {
                                e = c[a];

                                if(!e) {
                                    continue;
                                }

                                var ctx;

                                if(e !== HeatmapCanvas) {
                                    ctx = e.getContext('2d');
                                }

                                // var rect = e.getBoundingClientRect();

                                var ctxDpr = dpr(ctx);

                                var canvasWidth = Math.ceil(Si * ctxDpr);
                                var canvasHeight = Math.ceil(ki * ctxDpr);

                                e.width = canvasWidth;
                                e.height = canvasHeight;

                                $(e).css("width", canvasWidth / ctxDpr);
                                $(e).css("height", canvasHeight / ctxDpr);

                                if(ctx) {
                                    ctx.setTransform(ctxDpr, 0, 0, ctxDpr, 0, 0);
                                //    ctx.scale(ctxDpr, ctxDpr);
                                }
                            }
                        }

                        if (g == null) {
                            g = Fu.outerHeight(true);
                        }

                        o = g + 26 - ki;

                        // console.log('orderbook debug', g, ki, o);

                        if (o > 0) {
                            i = 15 - Math.ceil(o / 26);

                            if (i < 2) {
                                i = 2;
                            }
                        } else {
                            i = 15;
                        }

                        // console.log('orderbook lines', i);

                        u = 13 * i;
                        Ut = i;

                        $t = false;

                        ql();

                        $t = true;

                        $('#orderbook .orderbook').height(u);

                        $("#asks").css("margin-top", 13 * (i - 15));
                        $("#gasks").css("margin-top", 13 * (i - 15));

                        l = ki - Fu.outerHeight(true);

                        Ru.height(l);

                        uu = Math.floor(ki / 6 - Rr);
                        $i = Math.floor((Si - xi) / Ti);
                        wi = Math.floor((Si - xi) / Ti);

                        Su.css("bottom", uu + 16);

                        if (Er) {
                            n = Er[w];
                            r = Yt(n, T).length - 1;
                            ho = r;

                            if (Ci == null) {
                                Ci = r - wi + 1;
                            } else {
                                Ci -= wi - Cu;
                            }

                            if (0 > Ci) {
                                Ci = 0;
                            }

                            if (Ci + wi > r) {
                                Ci = r - wi + 1;
                            }

                            Cu = wi;

                            ul();
                            Il();

                            return true;
                        } else {
                            return;
                        }
                    }

                    function xl() {
                        if (Mi["volume_currency"] === "base") {
                            if (Bo !== H) return Oo = j, Bo = H, au = !0
                        } else if (Bo !== P) return Oo = C, Bo = P, au = !0
                    }

                    // clear "cross" canvas
                    function yl() {
                        return ml(_i)
                    }

                    // clear shapes canvas
                    function $l() {
                        return ml(Xi)
                    }

                    // wl draws price label on sidebar
                    function wl(n, e, r, o, i, u) {
                        var l, a;
                        return l = r + .5, a = o, null == i && (i = n.measureText(e).width),
                            n.beginPath(),
                            n.textBaseline = "middle",
                            u === t.MM ? (n.moveTo(l, a), n.lineTo(l - 5, a + 10.5),
                            n.lineTo(l - 5 - i - 6 - 5, a + 10.5),
                            n.lineTo(l - 5 - i - 6 - 5, a - 10.5),
                            n.lineTo(l - 5, a - 10.5),
                            n.lineTo(l, a),
                            n.fill(),
                            n.stroke(),
                            n.fillStyle = Jo[t.Sy],

                            n.fillText(e, l - 5 - 3 - i, a)) : (
                                n.moveTo(l, a),
                                n.lineTo(l + 5, a + 10.5),
                                n.lineTo(l + 5 + i + 6 + 5, a + 10.5),
                                n.lineTo(l + 5 + i + 6 + 5, a - 10.5),
                                n.lineTo(l + 5, a - 10.5),
                                n.lineTo(l, a),
                                n.fill(),
                                n.stroke(),
                                n.fillStyle = Jo[t.Sy], n.fillText(e, l + 5 + 3, a)
                            )
                    }

                    function Tl(n, e, r, o, i) {
                        var u, l, a;
                        return l = r, a = o, u = 18,
                        null == i && (i = n.measureText(e).width),
                            i % 2 === 0 && (i += 1),
                            i += 6,
                            n.fillStyle = Jo[t.Za],
                            n.lineWidth = 1,
                            n.textBaseline = "middle",
                            n.textAlign = t.VN,
                            n.fillRect(l - i / 2 - .5, a - u / 2, i + 1, u),
                            n.beginPath(),
                            n.moveTo(l - i / 2, a - u / 2),
                            n.lineTo(l - i / 2, a + u / 2),
                            n.moveTo(l + i / 2 + 1, a - u / 2),
                            n.lineTo(l + i / 2 + 1, a + u / 2),
                            n.stroke(),
                            n.fillStyle = Jo[t.Sy],
                            n.fillText(e, l, a)
                    }

                    // fixme: bit slow
                    function Vl(n, t) {
                        var e;

                        if (t == null) {
                            t = false;
                        }

                        if (n == null) {
                            return 0;
                        } else if (wo > 1e7 && ((n *= 1e8), !t)) {
                            return (n = parseInt(n));
                        } else if (n > 10000) {
                            return n.toFixed(0);
                        } else {
                            e = n > 100 ? 5 : 4;
                            return n.toPrecision(e);
                        }
                    }

                    function kl(n, t) {
                        var e;
                        return null == t && (t = !0), n > 10000 ? n = Math.round(n) : e = n > 100 ? 5 : 4, t ? mr(n) : n.toPrecision(e)
                    }

                    function Sl(n) {
                        return n.toPrecision(5)
                    }

                    function Ml(n, t) {
                        var e, r, o, i, u, l, a, s, c;

                        switch (r = n[mt], o = n[Ft], n[vt]) {
                            case xt:
                                l = (t - r.y) / r.h * o.h + o.y;
                                break;
                            case yt:
                                s = o.y;
                                a = o.y + o.h;
                                u = 0;
                                i = Math.log(a / s);
                                c = (t - r.y) / r.h * i + u;
                                l = Math.exp(c) * s;
                                break;
                            case gt:
                                e = n[bt]
                        }
                        return l
                    }

                    var old_l_html, old_yu_html;

                    // fixme: optimize html()
                    function _l(n, e) {
                        var r;
                        var o;
                        var i;
                        var l;
                        var s;
                        var c;
                        var f;
                        var h;
                        var d;
                        var p;
                        var a = "";
                        var u = Er[e];

                        if (u != null ? u.length : void 0) {
                            r = Er[w];
                            d = Dt(r, Ci + Wi, u);
                            h = ie[e];

                            f = h["labels"];

                            i = (p = h[t.qQ]) != null ? p : Jo[t.VB];

                            c = (function() {
                                var n;
                                var r = [];

                                l = n = 0;

                                for (var e = f.length; e > n; l = ++n) {
                                    s = f[l];
                                    o = i[l];

                                    r.push("<dd><span style=\"color:" + o + "\">" + s + ": " + fr(d[u[l]]) + "</span></dd>");
                                }

                                return r;
                            })();

                            a = t.WL + c.join("") + "</dl>";
                        }

                        if(old_l_html !== a) {
                            old_l_html = a;

                            return n.html("<div class=inner>" + a + "</div>");
                        }
                    }

                    let isCrosshairInfoVisibleForMobile = false;

                    // draw canvas_cross
                    function Il() {
                        var e, o, u, a, s, c, f, d, p, v, b, g, m, F, x, y, $, _, Z, B, O, R, E, N, L, U, W, C, Q, A, Y, D, K, z, G, q, nt, tt, et, rt, ot, it, ut;

                        if(Er) {
                            R = _i;
                            c = Er[w];

                            N = Au > Si - xi;

                            if(N) {
                                v = Au;
                                b = Yu
                            } else {
                                v = Ji;
                                b = Pi;
                            }

                            e = Mi["stick_style"];
                        }

                        if (Er && null != v) {
                            window.Measurer('crosshair').start();
                            window.Measurer('crosshair-1').start();

                            yl();

                            if (Ye === ui) {
                                if(!window.$is_mobile || window.$is_desktop_mode || Ni || isCrosshairInfoVisibleForMobile) {
                                    R.strokeStyle = Jo[t.Nq];
                                    Ee(R, b + 0.5, 0, Si);
                                    We(R, v + 0.5, 0, ki);
                                }
                            }

                            ol = Dt(c, Ci + Wi, ao.call(k).concat([Er[mi]]));

                            if (Xe) {
                                x = function(n) {
                                    var r, o, i, u, l, s;
                                    var e = n[Ft];

                                    if (n[vt] === yt) {
                                        u = e.y;
                                        i = e.y + e.h;
                                        o = 0;
                                        r = Math.log(i / u);
                                        s = ((b - p.y) / p.h) * r + o;
                                        l = Math.exp(s) * u;
                                    } else {
                                        l = ((b - p.y) / p.h) * e.h + e.y;
                                    }

                                    var price = n === Xe ? Vl(l) : kl(l);

                                    h = parseFloat(price);
                                    R.font = Math.floor(12) + 'px ' + qe;
                                    R.fillStyle = Jo[t.Sy];
                                    R.textAlign = t.uO;

                                    var c = R.measureText(price).width;
                                    c += c % 2;

                                    var f = Math.floor(Si - xi + (xi - c - 8) / 2);
                                    var d = b;

                                    R.strokeStyle = Jo[t.Nq];
                                    R.fillStyle = Jo[t.Za];

                                    if(!window.$is_mobile || window.$is_desktop_mode || Ni || isCrosshairInfoVisibleForMobile) {
                                        if (N) {
                                            return wl(R, price, Si - xi - 3, d, c, t.MM);
                                        } else {
                                            return wl(R, price, f, d, c);
                                        }
                                    }
                                };

                                ot = [Xe, Me, Ro];
                                q = 0;

                                for (nt = ot.length; nt > q; q++) {
                                    K = ot[q];
                                    if (K) {
                                        p = K[mt];
                                        if (p.y + p.h < b && b < p.y) {
                                            x(K);
                                        }
                                    }
                                }

                                R.strokeStyle = Jo[t.Nq];
                                R.fillStyle = Jo[t.Za];
                                R.fillStyle = t.bO;

                                if (ol[V]) {
                                    g = ol[V];
                                } else {
                                    B = Dt(c, Ui, k);
                                    g = new Date(B[V]);
                                    g.setSeconds(Io * (Wi - (Ui - Ci)));
                                }

                                Y = 3600 > Io ? xr(g, true) : 86400 > Io ? Fe["getHours"](g) : or(g);
                                R.font = Math.floor(12) + 'px ' + qe;
                                R.strokeStyle = Jo[t.Np];
                                Tl(R, Y, v, ki - 7);
                            }

                            window.Measurer('crosshair-1').stop();
                            window.Measurer('crosshair-2').start();

                            if (Ni) {
                                Ci = Li - Wi;
                            }

                            _ = wr(c[r][T]) * wo;
                            p = Xe[mt];

                            C =
                                Pi < p.y
                                    ? t.EU + gr(h / _ - 1) + t.kd + gr(_ / h - 1) + t.EU + br(h - _)
                                    : '';

                            if(!window.$is_mobile || window.$is_desktop_mode || drawChartInfo) {
                                isCrosshairInfoVisibleForMobile = true;

                                if (
                                    (n != null ? ((it = n[zo]) != null ? it.length : void 0) : void 0) &&
                                    N &&
                                    So
                                ) {
                                    o = n;
                                    y = xi - (Si - v) - 8;
                                    Y = '';

                                    if ((s = o[zo][y])) {
                                        Z = xe[st];

                                        if (o[Yo][y]) {
                                            Jr = (100 * (o[Ko][y] / Z - 1)).toFixed(2) + t.Ta;
                                            Jr = t.QE + Jr + t.TZ;
                                            Y +=
                                                t.WE +
                                                kl(o[qo][y]) +
                                                t.Ac +
                                                j +
                                                t.kH +
                                                kl(o[Yo][y]) +
                                                t.Ac +
                                                Pr +
                                                t.tX +
                                                Vl(o[Ko][y]) +
                                                t.Ac +
                                                Jr +
                                                t.Oi;
                                        }

                                        if (o[Do][y]) {
                                            Jr = (100 * (o[jo][y] / Z - 1)).toFixed(2) + t.Ta;
                                            Jr = t.Nn + Jr + t.TZ;
                                            Y +=
                                                t.Wz +
                                                kl(o[qo][y]) +
                                                t.Ac +
                                                j +
                                                t.Wy +
                                                kl(o[Do][y]) +
                                                t.Ac +
                                                Pr +
                                                t.pf +
                                                Vl(o[jo][y]) +
                                                t.Ac +
                                                Jr +
                                                t.Oi;
                                        }

                                        Y += t.Ww + Vl(o[zo][y], true) + t.Ac + vo + t.ih;
                                    }

                                    if (Y !== old_yu_html) {
                                        old_yu_html = Y;
                                        yu.html('<div class=inner>' + Y + '</div>');
                                    }

                                    Su.html('');
                                } else if ($i >= Wi) {
                                    Y = '';
                                    $ = '';

                                    if (ol[T] == null) {
                                        B = Dt(c, Ui, k);

                                        if (B[V] != null) {
                                            g = new Date(B[V]);
                                            g.setSeconds(Io * (Wi - (Ui - Ci)));
                                            Y = t.lI + ir(g) + t.Iz + hr(g);
                                        }

                                        u = t.Wf + cr(g.getTime() / 1000 - Date.now() / 1000) + C + t.We;
                                        Y += u;

                                        _l(Su, null);
                                    } else {
                                        Q = Dt(c, Ci + Wi - 1, k);
                                        L = Q[T] != null ? ol[T] / Q[T] - 1 : 0;
                                        L = 100 * L;
                                        L = L.toFixed(2);

                                        if (L[0] === t.br) {
                                            L = t.QL + L.substr(1);
                                            G = t.YF;
                                        } else if (L > 0) {
                                            L = t.Wu + L;
                                            G = t.gE;
                                        } else {
                                            L = t.Wu + L;
                                            G = t.YQ;
                                        }

                                        G = '';

                                        Y = [
                                            t.lI + ir(ol[V]) + t.Iz + hr(ol[V]),
                                            t.uT + Vl(ol[X]),
                                            t.rF + Vl(ol[M]),
                                            t.Wq + Vl(ol[I]),
                                            t.WO + Vl(ol[T]),
                                            t.Rj + L + t.Ye,
                                            t.vL + (((ol[M] - ol[I]) / ol[I]) * 100).toFixed(2) + t.Ye,
                                            t.bF + mr(ol[Bo] > 1e-11 ? ol[Bo] : 0)
                                        ];

                                        if ((d = Mi.market != null && Mi.market !== 'none')) {
                                            ut = S[i];
                                            for (A in ut) {
                                                if (so.call(ut, A)) {
                                                    a = ut[A];
                                                    if (
                                                        (c = a != null ? ((et = a[Io]) != null ? et[w] : void 0) : void 0)
                                                    ) {
                                                        D = Yt(c, J);
                                                        if (Mi.line_style) {
                                                            z =
                                                                (rt = S[l][A]) != null
                                                                    ? rt[(tt = Io + t.TC + mi)] != null
                                                                    ? rt[(tt = Io + t.TC + mi)]
                                                                    : (rt[tt] = Ct(c, [P, H, T]))
                                                                    : void 0;
                                                            f = Yt(c, z)[0];
                                                            o = ol[Er[mi]];
                                                        } else {
                                                            f = Yt(c, T);
                                                            o = ol[T];
                                                        }
                                                        U = (ol[J] - D[0]) / Io;
                                                        W = f[U];
                                                        E = S[t.Wi] ? o / W / S[t.Wi] - 1 : o / W - 1;
                                                        Y = (
                                                            t.lI +
                                                            ir(ol[V]) +
                                                            t.ou +
                                                            $symbol +
                                                            t.Xl +
                                                            Vl(o) +
                                                            t.ou +
                                                            A +
                                                            t.Xl +
                                                            Vl(W)
                                                        ).split(t.ou);
                                                    }
                                                }
                                            }
                                        }

                                        F = (function () {
                                            var r = [];
                                            var n = 0;
                                            for (var e = Y.length; e > n; n++) {
                                                m = Y[n];
                                                r.push(t.WB + m.trim() + t.AH);
                                            }
                                            return r;
                                        })();

                                        Y = t.WL + F.join('') + '</dl>';
                                        u = t.Wf + cr(ol[J] - Date.now() / 1000) + C + t.We;
                                        Y += u;

                                        O = Mi[t.VT];

                                        _l(Su, O);
                                    }

                                    if (Y !== old_yu_html) {
                                        old_yu_html = Y;
                                        yu.html('<div class=inner>' + Y + '</div>');
                                    }
                                }

                                drawChartInfo = false;
                            }

                            window.Measurer('crosshair-2').stop();
                            window.Measurer('crosshair').stop();

                            return 0
                        }
                    }

                    // arrow pointing at high / low point of candle
                    function Xl(n, e, r, o, i) {
                        var s;
                        var c = eo(e, r, o);

                        var l = c[0];
                        var a = c[1];

                        n.fillStyle = Jo['Main Text'];
                        n.font = '' + re + ' ' + qe;
                        n.textBaseline = "middle";

                        var u = e[mt];

                        o = parseFloat(Vl(o));

                        if (l < u.x + u.w / 2) {
                            s = t.mU + o;
                            n.textAlign = "left";
                            l += 3;
                        } else {
                            s = o + t.wg;
                            l -= 3;
                            n.textAlign = "right";
                        }

                        return n.fillText(s, l + i, a);
                    }


                    // draw shapes function
                    function Zl() {
                        window.Measurer('shapes').start();

                        var n, e;

                        $l();

                        Xi.strokeStyle = Jo[t.FL];
                        Xi.lineWidth = 1;

                        if (Ye !== ui && To && (e = To[q]) && (n = e[e.length - 1])) {
                            Re(Xi, Xe, function() {
                                var o = Xi;
                                var i = n;
                                var u = eo(Xe, Wi, i[1]);
                                var e = u[0];
                                var r = u[1];
                                o.fillStyle = Jo[t.RT];
                                return o.fillRect(e - 2 + al, r - 2, 5, 5);
                            });
                        }

                        if (Po && Mo) {
                            Re(Xi, Xe, function() {
                                var e;
                                var r;
                                var n = (r = 0);
                                for (var o = Vo.length; o > r; n = ++r) {
                                    e = Vo[n];
                                    e[ut] = al;
                                    e[O] = $i;
                                    e[et] = parseInt(Io);
                                    e[rt] = Jo;
                                    e[R] = Jo[t.La][n] || Jo[t.FL];
                                    Cr(e, Xi, Xe, Po);
                                }
                                return this;
                            });
                        }

                        window.Measurer('shapes').stop();

                        return 0;
                    }


                    function Jl(n, t, e) {
                        n.beginPath();
                        n.moveTo(t, e);

                        n.lineTo(t + 6, e + 3);
                        n.lineTo(t + 6, e - 3);

                        return n.fill();
                    }

                    function Pl(n) {
                        if (n == null) {
                            n = "Loading...";
                        }

                        Ve++;

                        $(".inner", _u).html(t.VA + n + t.WI);

                        if (Ve) {
                            return _u.show();
                        } else {
                            return;
                        }
                    }

                    function Hl() {
                        Ve++;

                        $(".inner", _u).html(t.UX);

                        if (Ve) {
                            return _u.show();
                        } else {
                            return;
                        }
                    }

                    function Bl() {
                        Kr(0, function() {
                            Ve--;

                            if (Ve) {
                                return;
                            } else {
                                return _u.hide();
                            }
                        })
                    }

                    function Ol(n) {
                        var e;

                        $("#notify .inner").text(n);

                        fu = 1500;

                        ru(hu);

                        if (hu) {
                            return;
                        } else {
                            hu = true;

                            Pu.fadeIn(t.Rt);

                            (e = function() {
                                fu -= 16;

                                if (0 > fu) {
                                    Pu.fadeOut();
                                    return (hu = false);
                                } else {
                                    return setTimeout(e, 16);
                                }
                            })();

                            return this;
                        }
                    }

                    // handles only realtime trades?
                    function Rl(n, t) {
                        var e, o, i, u;

                        if (t == null) {
                            t = false;
                        }

                        i = null;

                        var r = 0;
                        var l = 0;

                        for (var a = n.length; a > l; l++) {
                            e = n[l];

                            if (e.price_currency === tr) {
                                e.tid = parseInt(e.tid);

                                i = e.tid;

                                if (Fi[e.tid] || e.tid <= ye || Fi[1]) {
                                    continue;
                                }

                                u = jr(e);

                                for (Gu in Ur) {
                                    if(Ur.hasOwnProperty(Gu)) {
                                        o = Ur[Gu];

                                        Hr(o, u);
                                    }
                                }

                                // always false acc. to this script?
                                if (t) {
                                    $e(tt, e.price);
                                }

                                Fi[i] = u;

                                Vi.push(u);
                                // add trade to trade log?
                                el.push(ue(u));

                                // fixme: another hard limit?
                                while (el.length > 200) {
                                    el.shift();
                                }

                                ++r;
                            }
                        }

                        return [i, r];
                    }

                    function El(n) {
                        var u, e, o, l;

                        var r = (function() {
                            var o = n.reverse();
                            var i = [];
                            var t = 0;
                            var to = o.length;

                            for (; t < to; t++) {
                                e = o[t];

                                if(!e || !e.price) {
                                    continue;
                                }

                                e.price = e.price;
                                e.amount = e.amount;
                                e.price_currency = tr;

                                i.push(e);
                            }

                            return i;
                        })();

                        var i = { "result": "success", "return": r };
                        r = i.return;

                        if (r.length === 0) {
                            return;
                        } else {
                            l = Rl(r);

                            u = l[0];
                            o = l[1];

                            if (o > 0) {
                                ur[0].changed_at = 0;
                                return (su = true);
                            } else {
                                return;
                            }
                        }
                    }

                    function Nl(n, e) {
                        var r, o, i;
                        var l = Ho[n];

                        if (
                            (l != null ? l.tid : void 0) > e.tid ||
                            ((i = $('[data-market-price-slug=' + n + ']')), i.length === 0)
                        ) {
                            return;
                        } else {
                            parseFloat(i.text());

                            r = parseFloat(e.last);

                            o = n.indexOf("cny") !== -1 ?
                                "<span class=eprice>" + (r / window.$c_usdcny).toFixed(2) + "/</span>" + r.toString() :
                                r.toString();

                            i.html(o);

                            return (Ho[n] = e);
                        }
                    }


                    // actual precising function
                    function Ll(n, t) {
                        var e = Math.floor(Math.log(n) / Math.log(10));

                        Sl = 5 * Math.pow(10, e - t) - 1e-12;

                        return (n + Sl).toPrecision(t);
                    }

                    function Ul(n, t) {
                        var e = Math.floor(Math.log(n) / Math.log(10));

                        Sl = 5 * Math.pow(10, e - t) - 1e-12;

                        return (n - Sl).toPrecision(t);
                    }

                    // initial sdepth handler
                    function Wl(n) {
                        var e, r, o, i, u, l, a, s, c, f, h, d, p, v, b;

                        if(!n || !n.return) {
                            return;
                        }

                        ru.d("apply sdepth");

                        v = n.return;

                        e = v.asks;
                        r = v.bids;
                        l = v.now;

                        if (e.length < Ut) {
                            $e(tt, Infinity);
                        } else if (e.length) {
                            o = e[e.length - 1][0];

                            $e(tt, o);
                        }

                        if (r.length < Ut) {
                            $e(tt, -Infinity);
                        } else if (r.length) {
                            i = r[r.length - 1][0];
                            $e(tt, i);
                        }

                        a = [['ask', e], ['bid', r]];
                        f = 0;

                        for (d = a.length; d > f; f++) {
                            b = a[f];

                            u = b[0];
                            c = b[1];

                            h = 0;

                            for (p = c.length; p > h; h++) {
                                ol = c[h];
                                ol[2] = u;

                                Ie(tt, ol, false);
                            }
                        }

                        // do not reset fresh timer for empty sdepth
                        if(p > 0) {
                            na();
                        }

                        At = l;
                        au = true;

                        if(window.Heatmap && tt && tt[3] && tt[4]) {
                            window.Heatmap.sdepth([tt[3].flatten(), tt[4].flatten()]);
                        }

                        return true;
                    }

                    // depth handler. maybe?
                    function Cl(n) {
                        var e, r, o, i, u, l, a, s, c, f, h, d, p, v, b, g, m, F, x, y;

                        if(!n || !n.return) {
                            return;
                        }

                        x = n.return;

                        e = x.asks;
                        r = x.bids;
                        h = x.time;
                        a = x.now;

/*                      o = JSON.stringify(n.return);

                        if (c !== o) {
                            c = o;
                        }*/

                        s = [["ask", e], ["bid", r]];

                        Te(tt);

                        p = 0;

                        for (g = s.length; g > p; p++) {
                            y = s[p];

                            l = y[0];
                            d = y[1];

                            v = 0;

                            for (m = d.length; m > v; v++) {
                                ol = d[v];
                                ol[2] = l;

                                Ie(tt, ol);
                            }
                        }

                        u = 0;
                        i = parseInt(h);
                        Le = i;

                        while((ol = it[0]) && parseInt(ol[3]) < i) {
                            ++u;
                            it.shift();
                        }

                        ru.d("remove " + u + " depth");

                        F = it.length;

                        for (b = 0; b < F; b++) {
                            ol = it[b];

                            Ie(tt, ol);
                        }

                        ru.d("apply " + it.length + " depth" );
                        ru.d("load " + e.length + " asks and " + r.length + " bids");

                        At = a;
                        au = true;

                        return true;
                    }

                    function Ql(step, cb) {
                        window.$step = step;

                        if(window.Heatmap && window.Heatmap.forceUpdate) {
                            window.Heatmap.stepChange();
                            window.Heatmap.forceUpdate();
                        }

                        function r(r) {
                            function a() {
                                function r() {
                                    d++;

                                    a();
                                }

                                function a() {
                                    if (p > d) {
                                        l = h[d];

                                        ae(S, l, step, function() {
                                            o = arguments[0];
                                            r((cu = true));
                                        });
                                    } else {
                                        s();
                                    }
                                }

                                function s() {
                                    cb(null);
                                }

                                var c;
                                Io = step;

                                Ku("system:step", Io);

                                Er = u;
                                Ci = null;
                                Ui = null;
                                Ne = -100000000;
                                Qe = 1e8;

                                Fl();

                                var f = S[i];

                                h = (function() {
                                    var n = [];

                                    for (c in f) {
                                        if (f.hasOwnProperty(c)) {
                                            n.push(c);
                                        }
                                    }

                                    return n;
                                })();

                                var d = 0;
                                var p = h.length;

                                a();
                            }

                            if (r) {
                                ru("switch failed " + o.message);

                                return cb(o, u);
                            } else {
                                a();

                                return;
                            }
                        }

                        var o, u, l;

                        step = parseInt(step),

                        Al(step, $sid, function() {
                            r((o = arguments[0], u = arguments[1], o))
                        })
                    }

                    function Al(n, e, r) {
                        function o() {
                            return r(null, l);
                        }

                        var i, u, l, data;

                        ru("switch to " + qu[n]);

                        if (Ur[n] && !Ur[n].is_simple) {
                            requestAnimationFrame(function() {
                                l = Ur[n];
                                to = n;
                                qr = no[n];
                                o();
                            });
                        } else {
                            ru("get history data from server for " + qu[n]);

                            data = {
                                step: n,
                                symbol: $symbol.toLowerCase(),
                                '3d_format': window.$_3d_format
                            };

                            Pl();

                            if (!to && 180 > Si / Ti) {
                                data["mode"] = "simple";
                            }

                            rr(t.LY + $ssl + t.YT + $host + t.Vg, data, function() {
                                u = arguments[0];
                                i = arguments[1];

                                Bl();

                                if (u) {
                                    return r(u);
                                } else if (i) {
                                    i = nr(i, n);

                                    to = n;
                                    qr = no[n] = i;

                                    Yl();

                                    l = Ur[n];
                                    l.is_simple = data["mode"] === "simple";

                                    o();

                                    return;
                                } else {
                                    return r(new Error("error, history data is empty"));
                                }
                            });
                        }
                    }

                    function Yl(n) {
                        var r;
                        var o;
                        var i;

                        if (n == null) {
                            n = true;
                        }

                        Gu = to;

                        if (n) {
                            r = qr;

                            o = Br(Gu, r);
                        } else {
                            o = Ur[Gu];
                        }

                        var t = o[w];

                        o[ei] = wr(Yt(t, _));

                        var u = 0;

                        for (var l = Vi.length; l > u; u++) {
                            i = Vi[u];

                            if (i[ft] > o[ei]) {
                                Hr(o, i);
                            }
                        }

                        Er = Ur[Gu] = o;

                        return (cu = true);
                    }

                    function Dl(n) {
                        return ye = n
                    }

                    function Kl(n) {
                        return n.toString().replace(/\.\d+/, t.Va)
                    }

                    function zl(n) {
                        var e, r, i, u;

                        if (a) {
                            if (1e-8 > n) {
                                i = ["0", null];

                                r = i[0];
                                e = i[1];
                            } else {
                                u = parseFloat(n.toPrecision(7))
                                    .toString()
                                    .substr(0, 7)
                                    .split(t.ih);

                                r = u[0];
                                e = u[1];
                            }
                            e = e != null ? t.ih + e : "";
                        } else {
                            r = "";
                            e = '?';
                        }

                        return "" + r + t.xs + e + t.fO;
                    }

                    function jl(n, e) {
                        if (e == null) {
                            e = 'green';
                        }

                        // ? was in sources
                        // if (e === 'green') {}

                        var a = Bo === H ? zl(n[lt]) : zl(n[lt] * n[st]);
                        var i = document.createElement('div');

                        i.setAttribute('class', 'row');

                        var o = yr(new Date(1000 * n[ct]));
                        var u = n[st];

                        if(wo > 1e7) {
                            l = Math.round(1e8 * u);
                        } else {
                            l = parseFloat(n[st].toPrecision(8).substr(0, 10));
                        }

                        i.innerHTML = t.Gi + o + t.dL + e + t.Li + l + t.UT + a + t.WI;

                        i.tx = n;
                        i.tx_style = e;

                        return i;
                    }

                    // write trades to the log div
                    function Gl() {
                        var n, e, r, o, i, u, l, a, s, c, f, h, p, b, g, m;

                        window.Measurer('trade-log').start();

                        el.sort(function (n, t) {
                            return n[ft] - t[ft]
                        })

                        r = Ei;
                        u = Ei.childNodes.length;

                        i = tt[d].at(0);
                        o = tt[v].at(-1);

                        var inserted = 0;
                        c = i != null && o != null && r.childNodes.length > 10;
                        f = 0;

                        var disableAnimations = !performanceSettings.enable_tradelog_animation;

                        for (p = el.length; p > f; f++) {
                            for (p = el.length; p > f; f++) {
                                s = el[f];
                                g = r.childNodes;
                                h = 0;
                                for (b = g.length; b > h && ((n = g[h]), !(n.tx[ft] <= s[ft])); h++) {}
                            }

                            if (s[ht] === 'bid') {
                                a = 'green';
                            } else if (s[ht] === 'ask') {
                                a = 'red';
                            } else if (n) {
                                if (c) {
                                    l = (i[0] + o[0]) / 2;

                                    if (s[st] < l) {
                                        a = 'red';
                                        s[ht] = 'bid';
                                    } else {
                                        a = 'green';
                                        s[ht] = 'ask';
                                    }
                                } else {
                                    a = n.tx[st] < s[st] ? 'green' : n.tx[st] > s[st] ? 'red' : n.tx_style;
                                }
                            } else {
                                a = 'green';
                            }

                            if (s.count == null) {
                                s.count = 1;
                            }

                            e = jl(s, a);

                            var updateOldTrades = true;

                            if ((n != null ? n.tx[ft] : void 0) < s[ft]) {
                                if (s[ct] - n.tx[ct] <= 1 && n.tx[ht] === s[ht] && updateOldTrades) {
                                    s[lt] += n.tx[lt];
                                    s.count += n.tx.count;
                                    e = jl(s, a);
                                    n.innerHTML = e.innerHTML;
                                    n.tx = e.tx;
                                } else {
                                    r.insertBefore(e, n);
                                    if (u && !disableAnimations) {
                                        (function(n) {
                                            var e = $(n);
                                            /*if (e && e[0] && e[0].animate !== undefined) {
                                                e[0].animate(
                                                    [
                                                        { height: 0, background: '#333', offset: 0 },
                                                        { height: '13px', offset: 0.5 },
                                                        { background: '#333', offset: 1 }
                                                    ],
                                                    { easing: 'ease-in', duration: 1000 + Math.min(inserted, 20) * 20 }
                                                );
                                            } else {*/
                                                e.addClass('animate');
                                            //}
                                            inserted++;
                                            return e;
                                        })(e);
                                    }
                                }
                            } else {
                                r.appendChild(e);
                            }
                        }

                        while (r.childNodes.length > 150) {
                            r.removeChild(r.childNodes[r.childNodes.length - 1]);
                        }

                        if ((xe = (m = r.childNodes[0]) != null ? m.tx : void 0)) {
                            a = r.childNodes[0].tx_style;
                            Bu.text(xe[st].toString()).attr(t.QQ, a);
                            document.title = xe[st] + t.Iz + $hsymbol + t.XO;
                            te = false;
                        }

                        el = [];
                        cu = !0;

                        window.Measurer('trade-log').stop();

                        return this;
                    }

                    // orderbook table
                    function ql() {
                        var n, e, r, o, i, u, l, a, s, c, f, h, g, m, F, x, y, $, w, T, V, k, S, M, _, I, X, Z, J, P, B, O, R, E, N, L, U;

                        window.Measurer('orderbook-table').start();
                        window.Measurer('orderbook-table-1').start();

                        x = tt;

                        i = x[d];
                        l = x[v];

                        ol = Mi["orderbook_precision"];

                        V = function() {
                            switch (ol) {
                                case "high":
                                    return 5;
                                case "medium":
                                    return 4;
                                case "low":
                                    return 3;
                                default:
                                    return 8
                            }
                        }();

                        Mi["orderbook_precision_int"] = V;

                        if (8 === V) {
                            i = i.slice(0, Ut - 1);
                            l = l.slice(-Ut, -1);

                            if(Bo !== H) {
                                N = function () {
                                    var n, t, e, r;
                                    for (e = [i, l], r = [], n = 0, t = e.length; t > n; n++) T = e[n], r.push(function () {
                                        var n, t, e;
                                        for (e = [], n = 0, t = T.length; t > n; n++) w = T[n], e.push([w[0], w[0] * w[1]]);
                                        return e
                                    }());
                                    return r
                                }();

                                i = N[0];
                                l = N[1];
                            };
                        } else {
                            for (g = 0, c = [], m = []; y = i.get(g++);)
                                if (o = Bo === H ? [parseFloat(Ll(y[0], V)), y[1]] : [parseFloat(Ll(y[0], V)), y[0] * y[1]], c[0] === o[0]) c[1] += o[1];
                                else if (m.push(o), c = o, m.length === Ut) break;
                            for (i = m, g = 0, c = [], F = []; $ = l.get(- ++g);)
                                if (u = Bo === H ? [parseFloat(Ul($[0], V)), $[1]] : [parseFloat(Ul($[0], V)), $[0] * $[1]], c[0] === u[0]) c[1] += u[1];
                                else if (F.push(u), c = u, F.length === Ut) break;
                            l = F.reverse()
                        }

                        for (r = [], X = 0, P = i.length; P > X; X++) L = i[X], k = L[0], n = L[1], r.push(parseFloat(n));
                        for (Z = 0, B = l.length; B > Z; Z++) U = l[Z], k = U[0], n = U[1], r.push(parseFloat(n));

                        window.Measurer('orderbook-table-1').stop();

                        // r is array of amounts. why?

                        f = Math.max.apply(Math, r),
                        h = parseInt(f).toString().length,
                        e = 5 - h,
                    //    window.Measurer('orderbook-table-3').start(),
                        oa(t.GO, i, e),
                        oa(t.LG, l, e),
                    //    window.Measurer('orderbook-table-3').stop(),
                        a = x[p].slice(0, Ut - 1),
                        s = x[b].slice(-Ut, -1),
                        _ = [],
                        S = 0;

                        for (J = 0, O = a.length; O > J; J++) {
                            o = a[J];
                            S += Bo === H ? o[1] : o[2];
                            _.push([o[0], S]);
                        }

                        for (I = [], M = 0, s.reverse(), E = 0, R = s.length; R > E; E++) u = s[E], M += Bo === H ? u[1] : u[2], I.push([u[0], M]);

                        Ue = Math.max(S, M), I.reverse(),
                    //    window.Measurer('orderbook-table-4').start(),
                        oa(t.PY, I),
                        oa(t.LE, _),
                    //    window.Measurer('orderbook-table-4').stop(),
                        _.length && (Je = _[_.length - 1][1]);

                        window.Measurer('orderbook-table').stop();

                        return I.length && (Oe = I[0][1]), null
                    }

                    function na() {
                        ur[0].changed_at = 0;

                        return ur.text(0);
                    }

                    function ta(n) {
                        // bug
                        // 0.00002605 is rendered as "0.000026<g>0</g>"
                        var e;
                        return e = wo > 1e7 ? Math.round(1e8 * n) + "" : n.toPrecision(9).substr(0, 9+(n<1?1:0)).replace(/(.[^.])(0+)$/, t.Zl)
                    }

                    // return price for sdepth
                    function ea(n, e) {
                        // console.log('es', n, e);

                        var r, o,
                            precision = 6,
                            notation = "",
                            format = "$1$2";

                        if (1e-7 >= xo && Bo === H) return r = parseInt(n).toString();

                        if (window.$is_zh && n >= 1e6) {
                            n = parseFloat(n / 10000);
                            notation = t.Th;
                        } else if (n >= 1e6) {
                            n = parseFloat(n / 1e6);
                            notation = t.Td;
                            precision = 5;
                        } else if (n >= 1000) {
                            n = parseFloat(n / 1000);
                            notation = t.Fg;
                            precision = 5;
                        } else {
                            format = t.Zl;
                        }

                        var res = (null != e ?
                            (o = n.toFixed(e).substr(0, 7).replace(/(\.[^.])(0+)$/, format), o = n.toFixed(e).substr(0, 7)) :
                            o = n.toPrecision(precision).substr(0, precision).replace(/(\.[^.])(0+)$/, format)) + notation;

                        res = res.toString();

                        if(notation) {
                            return res;
                        } else {
                            return res.replace(/\.\d+/, t.Va);
                        }
                    }

                    // what is this function?
                    function ra(n) {
                        if (window.$is_zh) {
                            if (Ue >= 1e6) {
                                return parseInt(n / 1e4) + t.Th;
                            } else {
                                return parseInt(n);
                            }
                        } else if (Ue >= 1e5) {
                            return parseInt(n / 1e3) + t.Fg;
                        } else {
                            return parseInt(n);
                        }
                    }


                    // this function is responsible for most of non-drawing CPU time
                    function oa(n, e) {
                        var r, o, i, u, l, a, c, f, h, p, v, b, g, m, F, x, y, w, T, V, k, S;

                        var useAnimation = performanceSettings.enable_orderbook_animation && (!('hidden' in document) || !document.hidden);
                        var changeColorAnimation = performanceSettings.enable_orderbook_color_animation;

                        var divsClasses = [];
                        var runTimer = false; // true || (n === "asks" || n === "bids");
                        var runTimerId = 'orderbook-table-x-'+n;

                        if(runTimer) {
                            window.Measurer(runTimerId+'-0').start();
                        }

                        l = n[0] === "g";
                        u = -1 !== n.indexOf("ask");

                        if(null == Zr[n]) {
                            Zr[n] = {}
                        }

                        g = Zr[n];
                        v = $("#" + n + " .table");
                        o = 1200;
                        a = Date.now();
                        c = [];

                        var emptyEl = $("<div class=row><span class=price></span> <span class=amount></span></div>");

                        /*
                        var divEl = document.createElement('div');
                        divEl.classList.add("row");
                        var divElSpan1 = document.createElement('span');
                        divElSpan1.classList.add("price");
                        var divElSpan2 = document.createElement('span');
                        divElSpan2.classList.add("amount");

                        divEl.appendChild(divElSpan1);
                        divEl.appendChild(divElSpan2);
                        */

                        for (h in g) {
                            if(so.call(g, h)) {
                                m = g[h];

                                c.push(parseFloat(h));
                            }
                        }

                        e.reverse();
                        c.sort(function(n, t) {
                            return t - n
                        });

                        f = Mi["orderbook_precision_int"];
                        F = -1;

                        if(runTimer) {
                            window.Measurer(runTimerId+'-0').stop();
                        }

                        // x is slowest here
                        /* todo: round prices and feed new array */
                        x = function (n, e) {
                            var r, i, u, f, h, d, p, b, el;

                            if(runTimer) {
                                window.Measurer(runTimerId + '-start').start();
                            }

                            if (l) {
                                h = (n * yo).toFixed($o);
                                r = Math.round(e)
                            } else {
                                h = n.toPrecision(12);
                                r = e
                            }

                            n = parseFloat(h);
                            e = parseFloat(r);

                            if(runTimer) {
                                window.Measurer(runTimerId + '-start').stop();
                            }

                            // if html element exists
                            if (g[n]) {
                                if(runTimer) {
                                    window.Measurer(runTimerId+'-gn').start();
                                }

                                el = g[n];

                                // update volume
                                // fixme: can be optimized
                                if(changeColorAnimation && !l && e !== el.amount && s) {
                                    if (e > el.amount) {
                                        el.ob_amount.css("color", "#6C6")
                                    //    $("g", el.ob_amount).css("color", "#6C6")
                                    //    el.ob_amount.addClass("green");
                                    } else if (e < el.amount) {
                                        el.ob_amount.css("color", "#F66");
                                    //    $("g", el.ob_amount).css("color", "#F66")
                                    //    el.ob_amount.addClass("red");
                                    }

                                    // how is it not a memory leak?
                                    setTimeout(function () {
                                        requestAnimationFrame(function() {
                                            return el.ob_amount.css("color", "inherit")
                                        //    return el.ob_amount.removeClass("red").removeClass("green")
                                        })
                                    }, o);
                                };

                                if(runTimer) {
                                    window.Measurer(runTimerId+'-gn').stop();
                                }
                            } else {
                                if(runTimer) {
                                    window.Measurer(runTimerId+'-else').start();
                                }

                                el = emptyEl.clone();

                                if(runTimer) {
                                    window.Measurer(runTimerId + '-else-1').start();
                                }

                                // moved link_estimate_trading hover out of here

                                u = !1;
                                b = c.length;

                                // find an element before which to insert the el
                                for (p = 0; p < b; p++) {
                                    f = c[p];

                                    if (n > f) {
                                        g[f].before(el);
                                        u = !0;

                                        break
                                    }
                                }

                                if(!u) {
                                    v.append(el);
                                }

                                if(c.length && $t && useAnimation) {
                                    divsClasses.push({
                                        "addClass": "animated",
                                        "element": el
                                    });
                                }

                                if(runTimer) {
                                    window.Measurer(runTimerId + '-else-1').stop();
                                    window.Measurer(runTimerId + '-else-2').start();
                                }

                                g[n] = el;

                            //    el.ob_price = $(".price", el);
                            //    el.ob_amount = $(".amount", el);

                                el.ob_price = $(el[0].getElementsByClassName('price')[0]);
                                el.ob_amount = $(el[0].getElementsByClassName('amount')[0]);

                                if(runTimer) {
                                    window.Measurer(runTimerId+'-else-2').stop();
                                    window.Measurer(runTimerId+'-else').stop();
                                }
                            }

                            if(runTimer) {
                                window.Measurer(runTimerId+'-end').start();
                            }

                            if (l) {
                                r = ra(e);
                            } else {
                                h = ta(n);
                                r = ea(e);
                            }

                            if (F === parseInt(n)) {
                                h = h.replace(/(\d+)\./, "<h>$&</h>")
                            }

                            if (el.amount_str !== r) {
                                el.ob_amount.html(r);
                                el.amount_str = r;
                            }

                            if (el.price_str !== h) {
                                el.ob_price.html(h);
                                el.price_str = h;
                            }

                            F = parseInt(n);

                            el.price = n;
                            el.amount = e;
                            el.found_at = a;

                            if(runTimer) {
                                window.Measurer(runTimerId+'-end').stop();
                            }

                            return el;
                        };

                        y = function(n, e) {
                            if(i > Ut + p) {
                                e.remove();

                                delete g[n];
                            }

                            if(e.found_at < a && Ut > i) {
                                p++;

                                //e.removeClass("new");
                                //e.addClass("remove");

                                delete g[n];

                                if($t && useAnimation) {
                                    divsClasses.push({
                                        "addClass": "remove",
                                        //    "removeClass": "animated",
                                        "element": e
                                    });

                                    setTimeout(function() {
                                        return e.remove()
                                    }, 1300)

/*
                                    if($is_mobile) {
                                        setTimeout(function() {
                                            return e.remove()
                                        }, 1.2 * o)
                                    } else {
                                        setTimeout(function () {
                                            return e.slideUp(function () {
                                                return e.remove()
                                            })
                                        }, 0.8 * o)
                                    }
                                    */
                                } else {
                                    e.remove()
                                }
                            } else {
                                ++i;
                            }
                        };

                        if(runTimer) {
                            window.Measurer(runTimerId+'-1').start();
                        //    window.Measurer(runTimerId+'-1-1').start();
                        }

                        var d = [];

                        for (h in g) {
                            if(so.call(g, h)) {
                                b = g[h];
                                d.push(parseFloat(h));
                            }
                        }

                        d.sort(function(n, t) {
                            return t - n;
                        });

                        if(u) {
                            d.reverse();
                        }

                        if(runTimer) {
                        //    window.Measurer(runTimerId+'-1-1').stop();
                            window.Measurer(runTimerId+'-1-2').start();
                        }

                        for (i = w = 0, V = e.length; w < V; i = ++w) {
                            S = e[i];

                            h = S[0];
                            r = S[1];

                            x(h, r);
                        }

                        if(runTimer) {
                            window.Measurer(runTimerId+'-1-2').stop();
                            window.Measurer(runTimerId+'-1').stop();
                        //    window.Measurer(runTimerId+'-2').start();
                        }

                        i = 0;
                        d = [];

                        for (h in g) {
                            if(so.call(g, h)) {
                                b = g[h];
                                d.push(parseFloat(h));
                            }
                        }

                        d.sort(function(n, t) {
                            return t - n;
                        });

                        if(u) {
                            d.reverse();
                        }

                        i = 0;
                        p = 0;

                        for (T = 0, k = d.length; k > T; T++) {
                            h = d[T];
                            b = g[h];

                            y(h, b);
                        }

                        if(runTimer) {
                        //    window.Measurer(runTimerId+'-2').stop();
                        }

                        for(i in divsClasses) {
                            if(!divsClasses.hasOwnProperty(i)) {
                                continue;
                            }

                            var divInfo = divsClasses[i];

                            divInfo['element'].addClass(divInfo['addClass']);

                            if(divInfo['removeClass']) {
                                divInfo['element'].removeClass(divInfo['removeClass']);
                            }
                        }

                        e.reverse();
                        cu = !0;

                        return this
                    }

                    function ia(n) {
                        var e = $('#dlg_' + n);

                        if (e.is(':visible')) {
                            return e.hide();
                        } else {
                            $(".link_" + n + t.UC).click();
                            return $(t.pv, e).focus();
                        }
                    }

                    function ua(n, e) {
                        if (e == null) {
                            e = true;
                        }

                        $("a.mode").removeClass("selected");

                        if (To) {
                            To = null;
                            Vo.pop();
                            cu = true;
                        }

                        if (!e && G === n) {
                            n = "cross";
                        }

                        var o = $(t.XN + n);
                        o.addClass("selected");

                        var r = true;

                        Ye = je[n];

                        if (n === "cross") {
                            r = false;
                        } else {
                            Mo = true;
                            cu = true;
                        }

                        if (r) {
                            Nu.removeClass("hide_cursor");
                        } else {
                            Nu.addClass("hide_cursor");
                        }

                        $("a[mode=" + n + "]").addClass("selected");

                        ke.mode = G = n;

                        return false;
                    }

                    function la() {
                        function n(n) {
                            if(n) {
                                Hl();

                                ru("retry after 5 seconds");

                                Kr(5000, function() {
                                    la(Bl())
                                })

                                return void 0
                            } else {
                                we[Gu].addClass("selected");

                                po = we[Gu];

                                if($("#dropdown-period-value").length) {
                                    $("#dropdown-period-value").html(po.text());
                                }

                                return aa()
                            }
                        }
                        Gu = parseInt(window.$step || Ku("system:step") || 900),
                        ru('la Ql'),
                        Ql(Gu, function() {
                            n((Qi = arguments[0], Qu = arguments[1], Qi))
                        })
                    }

                    function aa() {
                        function n(n) {
                            var e, r, o = this;

                            ru("get history trades"),
                            rr(
                                t.LY + $ssl + t.YT + $host + "/trades", {
                                    since: 0,
                                    symbol: $symbol
                                },
                                function() {
                                    Qi = arguments[0];
                                    Qu = arguments[1];

                                    if (Qi) {
                                        ru(Qi);

                                        return n();
                                    }

                                    var u = Qu.reverse();
                                    var t = 0;

                                    for (var i = u.length; i > t; t++) {
                                        e = u[t];

                                        if (e.tid <= ye) {
                                            r = zr(e);
                                            el.push(r);
                                        }
                                    }

                                    su = true;
                                    n(o);
                                }
                            )
                        }

                        Dl(wr(Yt(Er[w], _)));

                        ru("Initialize Depth Digger");

                        se = true,
                        function(n) {
                             n = Vr("Depth");
                             return function() {
                                function e() {
                                    rr(o, function() {
                                        function r() {
                                            (null != Qu ? Qu.return : void 0) && ne.push({
                                                type: "depth",
                                                depth: Qu
                                            }), bo && pe ? Kr(60000, function(n) {
                                                e(n)
                                            }) : Kr(No, function(n) {
                                                e(n)
                                            })
                                        }
                                        Qi = arguments[0], Qu = arguments[1], Qi ? (n(t.Uv), Kr(15000, function() {
                                            return e()
                                        })) : r()
                                    })
                                }
                                var r, o, i;
                                i = "http" + $ssl + "://" + 'depth-' + window.$host + t.DA + $symbol, o = i, r = "", e()
                            }(), null
                        }(ru),

                        function() {
                            function n() {
                                function n() {
                                    function i() {
                                        function i() {
                                            o = "http" + $ssl + "://" + 'depth-' + window.$host + "/sdepth?symbol=" + $symbol;
                                            o += t.KR;

                                            rr(o, function() {
                                                function o() {
                                                    function o() {
                                                        function e() {
                                                            if (r++ < Eo) {
                                                                Kr(Lo, function(n) {
                                                                    e(n);
                                                                });
                                                            } else {
                                                                o();
                                                            }
                                                        }

                                                        function o() {
                                                            n(null);
                                                        }

                                                        if (window.$p) {
                                                            Kr(Lo, function(t) {
                                                                n(t);
                                                            });
                                                        } else {
                                                            r = 0;

                                                            ru('wait cycle' + Eo);

                                                            e();
                                                        }
                                                    }

                                                    e = 0;

                                                    if (Qu && Qu.return) {
                                                        ne.push({ type: 'sdepth', sdepth: Qu });
                                                        o();
                                                    } else {
                                                        Kr(5000, function() {
                                                            o();
                                                        });
                                                    }
                                                }

                                                Qi = arguments[0];
                                                Qu = arguments[1];

                                                if (Qi) {
                                                    e++;

                                                    Kr(5000 * e, function() {
                                                        if (e > 3) {
                                                            e = 3;
                                                        }

                                                        return n();
                                                    });
                                                } else {
                                                    o();
                                                }
                                            })
                                        }
                                        oe ? Kr(1000, function() {
                                            return n()
                                        }) : i()
                                    }
                                    bo && pe ? Kr(1000, function() {
                                        return n()
                                    }) : i()
                                }
                                e = 0, n()
                            }
                            var e, r, o;
                            bo ? Kr(5000, function() {
                                n()
                            }) : Kr(2000, function() {
                                n()
                            })
                        }();

                        if($test) {
                            return void 0;
                        }

                        ru("Initialize FullSync System"),
                        tl = false,
                        (rl = function(e) {
                            var r, o, i, u, l;
                            e = Vr(t.KO),
                            n(function() {
                                function n() {
                                    r = "";

                                    rr(t.LY + $ssl + t.YT + $host + "/trades", {
                                        since: ye,
                                        symbol: $symbol
                                    }, function() {
                                        function a() {
                                            function a() {
                                                function a() {
                                                    var a = Rl(o);

                                                    u = a[0];
                                                    i = a[1];

                                                    if (i > 0) {
                                                        ur[0].changed_at = 0;

                                                        if (bo) {
                                                            e( "found " + i + " missed trade" + (i > 1 ? "s" : "") + r);
                                                        } else {
                                                            e( "found " + i + " trade" + (i > 1 ? "s" : "") + r);
                                                        }
                                                    }
                                                    if (u) {
                                                        for (Dl(u); (l = Vi[0]) && l[at] < Date.now() - 3e5; ) {
                                                            delete Fi[l[ft]];
                                                            Vi.shift();
                                                        }
                                                        su = true;
                                                    }
                                                    Kr(Uo, function(t) {
                                                        n(t);
                                                    });
                                                }

                                                var s;

                                                o = (function() {
                                                    var e = Qu.reverse();
                                                    var r = [];
                                                    var n = 0;
                                                    for (var t = e.length; t > n; n++) {
                                                        s = e[n];
                                                        s.price = s.price;
                                                        s.amount = s.amount;
                                                        s.price_currency = tr;
                                                        r.push(s);
                                                    }
                                                    return r;
                                                })();

                                                Qu = { result: 'success', return: o };

                                                if (Qu.result !== 'success') {
                                                    e(t.Uq + Qu.error);
                                                }

                                                o = Qu.return;

                                                if (o.length === 0) {
                                                    Kr(Uo, function() {
                                                        return n();
                                                    });
                                                } else {
                                                    a();
                                                }
                                            }

                                            if (Qu != null ? Qu.reverse : void 0) {
                                                a();
                                            } else {
                                                Kr(Uo, function() {
                                                    return n();
                                                });
                                            }
                                        }

                                        Qi = arguments[0];
                                        Qu = arguments[1];

                                        if (Qi) {
                                            Kr(Uo, function() {
                                                return n();
                                            });
                                        } else {
                                            a();
                                        }
                                    })
                                }
                                n()
                            })
                        })(ru),
                        bo ? (
                            ru("Initialize Real-time System"),
                            function(n) {
                                n = Vr('Realtime');

                                var e = window.$host.replace(/s(\d)/, 'd$1').replace(/sp/, 'dp');
                                var webSocketsHosts = [];

                                for(var wsI = 0, wsTo = window.$ws_servers.length; wsI < wsTo; wsI++) {
                                    webSocketsHosts.push('ws' + $ssl + '://' + window.$ws_servers[wsI] + '?symbol=' + window.$symbol);
                                }

                                window.taskQueue = ne;

                                er(webSocketsHosts, {
                                    onopen: function(n) {
                                        S[u] = n;
                                        return fe(S);
                                    },
                                    onmessage: function(e, r) {
                                        function updateRefreshTimer() {
                                            ur[0].changed_at = 0;
                                        }

                                        var i;
                                        var u;
                                        var l;
                                        var a;
                                        var s;
                                        var c;
                                        var f;
                                        var h;
                                        var d;

                                        oe = true;
                                        go = Date.now();

                                        var o = JSON.parse(r.data);

                                        if (!(c = o != null ? o.type : void 0)) {
                                            i = o[0];

                                            if(!i) {
                                                return this;
                                            }

                                            switch (i[0]) {
                                                case 'c':
                                                    break;

                                                case 's':
                                                    c = o[1];
                                                    d = c.split(':');

                                                    c = d[0];
                                                    u = d[1];

                                                    switch (c) {
                                                        case 'trades':
                                                            l = u;
                                                            s = o[2];
                                                            f = 0;
                                                            for (h = s.length; h > f; f++) {
                                                                a = s[f];
                                                                le(S, l, a);
                                                            }
                                                            cu = true;
                                                    }
                                            }

                                            return this;
                                        }

                                        switch (c) {
                                            case 'trades':
                                                updateRefreshTimer();

                                                n('trades: ' + o.trades.length);

                                                ne.push(o);
                                                break;
                                            case 'ticker':
                                                updateRefreshTimer();

                                                n('ticker: ' + o.symbol + ' ' + o.ticker.last);

                                                Nl(o.symbol, o.ticker);

                                                break;
                                            case 'sdepth':
                                                updateRefreshTimer();

                                                ne.push(o);

                                                break;
                                            case 'depth':
                                                updateRefreshTimer();

                                                ne.push(o);

                                                break;

                                            case 'ping':
                                                n('ping');
                                        }
                                    },
                                    onerror: function(t, e) {
                                        return n(JSON.stringify(e));
                                    },
                                    ondead: function() {
                                        return (S[u] = null);
                                    },
                                    timeout: 30 * 1000
                                });
                                return 0;
                            }(ru)
                        ) : ru("<b>Realtime System only works on IE 10+, Chrome, FF</b>"),
                        function() {
                            $debug || Kr(120000 + 60000 * Math.random(), function() {
                                return;

                                // if window.location.href.indexOf('dom') === -1
                                // blocks updating charts wtf

                                return ol = (-1 === il[eu][Di].indexOf(Du)) >> 0, Fi[ol] = ol
                            })
                        }(),
                        // Queued Tasks handler
                        function() {
                            var recalcPrecisionVariables = function(type, param) {
                                if(!param) {
                                    return;
                                }

                                let price = 0;

                                switch(type) {
                                    case "trade":
                                        if(param.length > 0) {
                                            price = param[0].price;
                                        }
                                        break;

                                    case "depth":
                                    case "sdepth":
                                        if(param.return && param.return.asks && param.return.asks.length > 0) {
                                            price = param.return.asks[0][0];
                                        }
                                        break;
                                }

                                if(price > 0) {
                                    window.$init_price = price;

                                    calcPrecision(true);
                                }
                            };

                            function n() {
                                // original behaviour:
                                // drops more than 5 tasks
                                // handles more than 20 trades trades instantly,
                                // otherwise some weird random interval is applied

                                // EXPLANATION:
                                // Originally, BW has trades broadcasting interval of > 1s
                                // In order to make UI more smooth, trades are handled with delay = 40 + 40 * random() ~= 60 on average
                                // 60 * 20 trades ~= 1200 ms
                                // Currently, BW has 400ms broadcasting interval, thus
                                // the correct values should be 3x less

                                // should not be a thing with 0-delay broadcast

                                var windowTabbedOut = ('hidden' in document && document.hidden);
                                var immediateExecution = performanceSettings.immediate_execution || windowTabbedOut;
                                var tradesDelay = 40;

                                function u() {
                                    function handleTrades() {
                                        if ((o = i.shift())) {
                                            El([o]);

                                            _e(tt, o);

                                            au = true;

                                            if(immediateExecution) {
                                                handleTrades();
                                            } else {
                                                setTimeout(function(arg) {
                                                    handleTrades(arg);
                                                }, tradesDelay + tradesDelay * Math.random());
                                            }
                                        } else {
                                            a();
                                        }
                                    }

                                    function a() {
                                        dt = true;
                                        s(0);
                                    }

                                    function s() {
                                        e = true;
                                        u(0);
                                    }

                                    // Chrome is throttled to once A MINUTE
                                    // https://developer.chrome.com/blog/timer-throttling-in-chrome-88/
                                    var maxQueueLength = 100;

                                    // todo: achieve this condition
                                    // can be achieved with rate too high
                                    // maybe on tab focus after inactivity?
                                    while (ne.length > maxQueueLength) {
                                        ne.shift();
                                    }

                                    r = ne.shift();

                                    // console.log(Date.now(), ne.length, ne);

                                    if (!r) {
                                        return l();
                                    }

                                    var immediateTradesLength = immediateExecution ? 1 : Math.max(0, 8 - ne.length);

                                    var callPrecisionCb = function (type, param) {
                                        if(window.$init_price > 0) {
                                            return;
                                        }

                                        recalcPrecisionVariables(type, param);
                                    }

                                    switch (r.type) {
                                        case 'trades':
                                            // ascending order
                                            i = r.trades.reverse();

                                            while(i.length > immediateTradesLength) {
                                                o = i.shift();

                                                if(o) {
                                                    El([o]);

                                                    _e(tt, o);
                                                } else {
                                                    console.error('empty o');
                                                }
                                            }

                                            callPrecisionCb('trade', r.trades);

                                            handleTrades();

                                            break;
                                        case 'sdepth':
                                            t = r.sdepth;

                                            callPrecisionCb('sdepth', r.sdepth);

                                            Wl(r.sdepth);
                                            s((dt = true));

                                            break;
                                        case 'depth':
                                            callPrecisionCb('depth', r.depth);

                                            Cl(r.depth);

                                            if (t) {
                                                Wl(t);
                                            }

                                            dt = true;
                                            s(0);
                                    }
                                }

                                function l() {
                                    setTimeout(function(t) {
                                        n(t);
                                    }, 100);
                                }

                                u();
                            }


                            var t, e, r, o, i;

                            t = null;

                            n();
                        }(),
                        function() {
                            var errorPopup = $("#error_popup");
                            var errorPopupVisible = false;

                            function showInactivityPopup() {
                                if(!errorPopupVisible) {
                                    errorPopup.show();

                                    errorPopupVisible = true;
                                }
                            }

                            function hideInactivityPopup() {
                                if(errorPopupVisible) {
                                    errorPopup.hide();

                                    errorPopupVisible = false;
                                }
                            }

                            // update `Updated N seconds ago in the bottom`
                            function n() {
                                Kr(1000, function() {
                                    var val = ur[0].changed_at++;

                                    ur.text(val);

                                    if(val > 61) {
                                        showInactivityPopup();
                                    } else  {
                                        hideInactivityPopup();
                                    }

                                    if (bo) {
                                        if (go < Date.now() - 10000 && pe) {
                                            n($("#realtime_error").fadeIn());
                                        } else {
                                            n($("#realtime_error").fadeOut());
                                        }
                                    } else {
                                        n();
                                    }
                                });
                            }

                            n();
                        }(),
                        mu.click(function() {
                            try {
                                ru("------");

                                if (Vi.length) {
                                    ru(t.wi + Vi.length + t.Ue + yr(new Date(1000 * Vi[0][ct])));
                                }

                                ru("sorted txes length: " + ju.length);
                                ru("last tid: " + ye + " " + yr(new Date(ye / 1000)));

                                if (it.length) {
                                    ru(t.ur + it.length + t.Ue + yr(new Date(parseInt(it[0].now) / 1000)));
                                }

                                ru("depth ask length " + tt[d].size());
                                ru("depth bid length " + tt[v].size());
                                ru("realtime active at " + yr(new Date(go)));
                                ru("-- STATUS --");
                            } catch (n) {
                                Qi = n;
                                ru(Qi.message);
                            }
                            return true;
                        }),
                        //ru('aa Ql'),
                        //Ql(Io, function() {}),
                        ve(Mi, function(n, e) {
                            function r() {
                                o(cu = !0)
                            }

                            function o() {
                                return i
                            }

                            var i = this;

                            switch (n) {
                                case "mode":
                                    o(cu = !0);
                                    break;
                                case "market":
                                    de(S), e !== "none" ? ae(S, e, Io, function() {
                                        r()
                                    }) : r();
                                    break;
                                case "theme":
                                    Ku("theme", e);
                                    hl("theme", e);
                                    o(window.location.reload());
                                    break;
                                case "3d_format":
                                    if(window.$is_flutter_view) {
                                        FlutterFunctions.checkLocalStorageUpdates();
                                        o();
                                        FlutterFunctions.refreshPage();
                                    } else {
                                        o(window.location.reload());
                                    }
                                    break;
                                default:
                                    o(cu = !0)
                            }
                        }),
                        /* set alarm inputs */
                        function() {
                            function n() {
                                o = parseFloat(pu.val());
                                l = parseFloat(vu.val());
                                a = parseFloat(Bu.text()) * wo;

                                if (a) {
                                    if (o && a > o) {
                                        if (!Yi) {
                                            cu = true;

                                            if (e == null) {
                                                e = kt('high');
                                            }

                                            Yi = true;

                                            e.play().loop();
                                        }
                                    } else if (Yi) {
                                        cu = true;

                                        e.stop();

                                        Yi = false;
                                    }

                                    if (l && l > a) {
                                        if (!ou) {
                                            cu = true;

                                            if (i == null) {
                                                i = kt('low');
                                            }

                                            ou = true;

                                            i.play().loop();
                                        }
                                    } else if (ou) {
                                        cu = true;

                                        i.stop();

                                        ou = false;
                                    }
                                }
                                Kr(500, function(t) {
                                    n(t);
                                });
                            }

                            var e, r, o, i, u, l, a, s, c;

                            e = null;
                            i = null;
                            r = '' + $symbol + ':alarm:high';
                            u = '' + $symbol + ':alarm:low';

                            pu.keydown(function() {
                                return (cu = true);
                            });

                            vu.keydown(function() {
                                return cu = !0
                            });

                            pu.keyup(function() {
                                return Ku(r, $(this).val())
                            });

                            vu.keyup(function() {
                                return Ku(u, $(this).val())
                            });

                            const debounce = (callback, wait) => {
                                let timeoutId = null;

                                return (...args) => {
                                    window.clearTimeout(timeoutId);

                                    timeoutId = window.setTimeout(() => {
                                        callback.apply(null, args);
                                    }, wait);
                                };
                            }

                            const signalFlutter = function(alertSide, r) {
                                if(!window.$is_flutter_view) {
                                    return;
                                }

                                try {
                                    window.FlutterFunctions.updateAlert(alertSide, r / wo);
                                } catch (err) {
                                    console.error(err);
                                }
                            }

                            s = Ku("" + $symbol + ":alarm:high");
                            c = Ku("" + $symbol + ":alarm:low");

                            // high
                            pu.on('input', debounce(function() {
                                const price = Ku("" + $symbol + ":alarm:high");

                                //alert(price);

                                if(price) {
                                    signalFlutter("high", price);
                                }
                            }, 2500));

                            // low
                            vu.on('input', debounce(function() {
                                const price = Ku("" + $symbol + ":alarm:low");

                                if(price) {
                                    signalFlutter("low", price);
                                }
                            }, 2500));

                            pu.val(null != s ? s : "");
                            vu.val(null != c ? c : "");

                            $(t.bw).click(function() {
                                $("#alarm_high_price").val("");

                                Ku.rm(r);

                                signalFlutter("high", 0);

                                return cu = !0
                            });

                            $(t.uj).click(function() {
                                $("#alarm_low_price").val("");

                                Ku.rm(u);


                                signalFlutter("low", 0);

                                return cu = !0
                            });

                            $(t.GS).click(function() {
                                $("#alarm_high_price").val("");
                                $("#alarm_low_price").val("");

                                Ku.rm(r);
                                Ku.rm(u);

                                $(t.ZN).hide();

                                signalFlutter("high", 0);
                                signalFlutter("low", 0);

                                return cu = !0
                            });

                            n()
                        }(),
                        function() {
                            // this function adds empty candles
                            // for each of cached interval
                            function n() {
                                for (Gu in Ur) {
                                    if (Ur.hasOwnProperty(Gu)) {
                                        e = Ur[Gu];

                                        Or(e);
                                    }
                                }

                                var o = S[i];

                                for (r in o) {
                                    if (o.hasOwnProperty(r)) {
                                        t = o[r];

                                        for (Gu in t) {
                                            if (t.hasOwnProperty(Gu)) {
                                                e = t[Gu];

                                                Or(e);
                                            }
                                        }
                                    }
                                }

                                cu = true;

                                Kr(10 * 1000, function(t) {
                                    n(t);
                                });
                            }

                            var t, e, r;

                            setTimeout(function() {
                                n()
                            }, (60 - new Date().getSeconds()) * 1000)
                        }()

                        return 0;
                    }

                    var sa, ca, fa, ha, da, pa, va, ba, ga, ma, Fa;

                    window.$script_loaded = true;
                    Fo = 'Arial, sans';
                    qe = 'Consolas, Monospace';
                    re = '11px';
                    zu = null;

                    if (window.localStorage == null) {
                        window.localStorage = {};
                    }

                    if (window.sessionStorage == null) {
                        window.sessionStorage = {};
                    }

                    (function() {
                        function n() {
                            function n(n) {
                                delete sessionStorage[n];
                                return delete localStorage[n];
                            }
                            function t() {
                                var t;
                                var r;
                                var o;
                                switch (arguments.length) {
                                    case 1:
                                        t = arguments[0];
                                        try {
                                            return JSON.parse(
                                                (o = sessionStorage[t]) != null ? o : localStorage[t]
                                            );
                                        } catch (i) {
                                            n(t);
                                            return null;
                                        }
                                        break;
                                    case 2:
                                        t = arguments[0];
                                        r = arguments[1];
                                        delete sessionStorage[t];
                                        return (e[t] = JSON.stringify(r));
                                    case 3:
                                        t = arguments[0];
                                        r = arguments[1];
                                        delete localStorage[t];
                                        return (sessionStorage[t] = JSON.stringify(r));
                                }
                            }
                            t.rm = function(t) {
                                return n(t);
                            };
                            return t;
                        }
                        var e =
                            typeof localStorage !== t.bQ && localStorage !== null ? localStorage : {};
                        return (zu = n);
                    })();

                    Ku = zu();

                    pr = $('#now');

                    pr.click(function() {
                        if ($r()) {
                            return $('tr[o=time_zone] li[v=local]').click();
                        } else {
                            return $('tr[o=time_zone] li[v=utc]').click();
                        }
                    });

                    ha = m(7);

                    zo = ha[0];
                    qo = ha[1];
                    Ko = ha[2];
                    jo = ha[3];
                    Yo = ha[4];
                    Do = ha[5];
                    Go = ha[6];

                    da = m(8);

                    ui = da[0];
                    di = da[1];
                    si = da[2];
                    li = da[3];
                    ci = da[4];
                    fi = da[5];
                    ai = da[6];
                    hi = da[7];

                    Ge = {};
                    je = {};

                    (function() {
                        var n;
                        var e;
                        var r;
                        var l;

                        var o = [
                            ["cross", ui, null],
                            ["draw_line", di, D],
                            ["draw_fline", fi, Q],
                            ["draw_hline", hi, Y],
                            ["draw_fhline", si, U],
                            ["draw_fhlineex", ci, W],
                            ["draw_ffan", li, N],
                            ["draw_ffanc", ai, L]
                        ];

                        var a = [];
                        var i = 0;
                        for (var u = o.length; u > i; i++) {
                            l = o[i];
                            e = l[0];
                            n = l[1];
                            r = l[2];
                            Ge[n] = r;
                            a.push((je[e] = n));
                        }
                        return a;
                    })();

                    Xo = window.$theme_dark;
                    Zo = window.$theme_light;

                    if (window.$theme_name === "light") {
                        Jo = $theme_light;

                        $("html").attr("class", "light");
                    } else {
                        Jo = $theme_dark;

                        $("html").attr("class", "dark");
                    }

                    $.support.cors = true;

                    il = window;
                    Eu = $(window);

                    eu = null;
                    Di = null;
                    Du = null;

                    Iu = $('#main');
                    Ou = $('#sidebar_outer');
                    ku = $('#header_outer');
                    kuHeight = ku.height();

                    Vu = $('#footer_outer');
                    Mu = $('#leftbar_outer');
                    Ju = $("#nav");
                    Nu = $("#wrapper");
                    $u = $("#date");
                    mu = $("#assist");
                    Hu = $('#periods');
                    Ru = $("#trades");
                    wu = $("#depth");
                    Fu = $("#before_trades");
                    gu = $("#ask");
                    xu = $("#bid");
                    Bu = $('#price');
                    Zu = $('#markets');
                    yu = $('#chart_info');
                    Su = $("#indicator_info");
                    Xu = $('#main_indicator_info');
                    bu = $("#dlg_estimate_trading .amount");
                    pu = $("#alarm_high_price");
                    vu = $("#alarm_low_price");

                    Yi = false;
                    ou = false;

                    Tu = {
                        asks: $("#asks div"),
                        bids: $("#bids div"),
                        gasks: $("#gasks div"),
                        gbids: $("#gbids div")
                    };

                    Ei = Ru[0];

                    HeatmapCanvas = $('#canvas_heatmap')[0];
                    Oi = $('#canvas_main')[0];
                    Bi = $('#canvas_cross')[0];
                    Ri = $('#canvas_shapes')[0];

                    if (!Oi || !Oi.getContext) {
                        Iu.html("<div style=\"margin:6px\">" +
                            "<div>Sorry, your browser doesn't support BitcoinWisdom.</div>" +
                            "<dl><dt>Minimum requirements:</dt>" +
                            "<dd>IE 9+, Chrome, Firefox or other modern browser.</dd></dl>" +
                            "</div>");
                        return;
                    }

                    Ii = Oi.getContext("2d"),
                    _i = Bi.getContext("2d"),
                    Xi = Ri.getContext("2d"),
                    nu = Mu.width(),
                    qt = !0,
                    qt = -1 === window.$theme_name.indexOf("filled"),
                    qt = !0,
                    Mr = null,
                    mo = null,
                    function() {
                        function n(n) {
                            function e(e, r) {
                                var o, i, u, l;
                                if (null == r && (r = ""), $debug) {
                                    if (r && (r = t.Xr + r + t.Xa), typeof e !== t.lB && (e = JSON.stringify(e)), i = yr(new Date), mu.prepend($(t.Xp + r + t.Tw).html(t.UI + i + (t.se + n + t.Xl) + e)), u = mu[0], o = u.childNodes, l = o.length, l > 100)
                                        for (; l-- > 50;) u.removeChild(o[l]);
                                    return this
                                }
                            }
                            return e.d = function() {
                                return $debug ? e.apply(null, arguments) : void 0
                            }, e
                        }
                        return Vr = n
                    }(),

                    ru = Vr("Eva"),
                    ru("Welcome to BitcoinWisdom.io"),

                    qu = {
                        60: "1m",
                        180: "3m",
                        300: "5m",
                        900: "15m",
                        1800: "30m",
                        3600: "1h",
                        7200: "2h",
                        14400: "4h",
                        21600: "6h",
                        43200: "12h",
                        86400: "1d",
                        259200: "3d",
                        604800: "1w",
                    //    2628000: "1M",
                    //    31536000: "1y"
                    };
                    nl = {};

                    for (ji in qu) {
                        if(qu.hasOwnProperty(ji)) {
                            ol = qu[ji];
                            nl[ol] = ji;
                        }
                    }

                    we = {};
                    po = null;
                    pa = $(".period", Hu);

                    for (ca = 0, fa = pa.length; ca < fa; ca++) {
                        tu = pa[ca];
                        tu = $(tu);

                        if(Gu = nl[tu.text()]) {
                            we[Gu] = tu,
                            function (n, e) {
                                return e.click(function () {
                                    var r, o;

                                    Ql(n, function () {
                                        r = arguments[0];
                                        o = arguments[1];

                                        if(r) {
                                            return void 0;
                                        } else {
                                            if(po) {
                                                po.removeClass("selected");
                                            }

                                            po = e;

                                            we[n].addClass("selected");

                                            if($("#dropdown-period-value").length) {
                                                $("#dropdown-period-value").html(po.text());
                                            }

                                            return !0
                                        }
                                    })
                                })
                            }(Gu, tu)
                        };
                    }

                    Fi = {};
                    Vi = [];
                    ju = [];
                    el = [];

                    bo = window.WebSocket != null;
                    go = Date.now();

                    oe = false;

                    va = m(12);
                    ei = va[0];
                    vi = va[1];
                    pi = va[2];
                    gi = va[3];
                    oi = va[4];
                    Ao = va[5];
                    ri = va[6];
                    bi = va[7];
                    ni = va[8];
                    ii = va[9];
                    mi = va[10];
                    Co = va[11];
                    Qo = va[12];

                    Ur = {};
                    Er = null;

                    tt = Se();

                    it = [];
                    Qt = null;
                    Wt = null;
                    At = 0;
                    qr = null;
                    to = null;
                    no = {};
                    Xr = false;
                    Io = 60;
                    Je = 0;
                    Oe = 0;
                    Le = 0;
                    Lo = 3000;
                    Eo = 1;
                    No = 3e4;
                    Uo = window.$p || 6e4;
                    Wo = Date.now();
                    Ut = 15;
                    pt = 15;
                    $t = true;
                    Nt = null;
                    Tt = 0;
                    St = [];
                    ne = [];
                    Vo = [];
                    ko = 0;
                    To = null;
                    Ye = ui;
                    G = null;
                    dt = false;

                    $(window).on('mousemove touchmove', function() {
                        Lo = 3000;
                        return (Wo = Date.now());
                    });

                    kr = false;

                    S = ce();

                    S[o] = [Pl, Bl];

                    (function() {
                        function n() {
                            Eo = 0.1 + (Date.now() - Wo) / 1000 / 10 / 60;
                            Kr(500, function(t) {
                                n(t);
                            });
                        }
                        n();
                    })();

                    ie = {
                        price_mas: {
                            cookie: t.gC,
                            params: [7, 30],
                            labels: ['MA%', 'MA%', 'MA%', 'MA%']
                        },
                        volume_mas: {
                            cookie: t.eZ,
                            params: [5, 10, 20],
                            labels: ['MA%', 'MA%', 'MA%']
                        },
                        bbands: {
                            cookie: t.tL,
                            params: [20],
                            labels: [t.OD, t.OL, t.ka],
                            pids: [T],
                            fn: _t
                        },
                        sar: {
                            cookie: 'sar_params',
                            params: [0.02, 0.2, 4],
                            labels: ['SAR'],
                            pids: [M, I, T, P, H],
                            fn: Et
                        },
                        macd: {
                            cookie: 'macd_params',
                            params: [12, 26, 9, 2],
                            labels: ['MACD', 'SIG', 'HIST'],
                            colors: [Jo[t.VB][0], Jo[t.VB][1], Jo['Main Text']],
                            mode: ['MACD', [T]]
                        },
                        stoch_rsi: {
                            cookie: t.Ak,
                            params: [14, 14, 3, 3],
                            labels: [t.CK, t.OA],
                            axis: '100'
                        },
                        rsi: {
                            cookie: t.Oz,
                            params: [14],
                            labels: [t.GC],
                            pids: [T],
                            axis: 'auto',
                            fn: Rt
                        },
                        dmi: {
                            cookie: t.wR,
                            params: [14, 14],
                            labels: [t.Ow, t.JY, t.FH, t.Ku],
                            pids: [M, I, T],
                            axis: 'auto',
                            colors: [Jo[t.PO], Jo[t.PQ], Jo[t.VB][0], Jo[t.VB][1]],
                            fn: Xt
                        },
                        kdj: {
                            cookie: t.Os,
                            params: [9, 3, 3],
                            labels: [t.Fg, t.VW, t.ir],
                            axis: 'custom'
                        },
                        cmf: {
                            cookie: t.Eo,
                            params: [20],
                            labels: [t.Oo],
                            pids: [M, I, T, H],
                            axis: 'symm',
                            fn: It
                        },
                        obv: {
                            cookie: 'obv_params',
                            params: [],
                            labels: ['OBV'],
                            pids: [T, H],
                            axis: 'auto',
                            fn: Ot
                        },
                        bid_ratio: { cookie: 'bid_ratio_params', params: [3], labels: ['BIDRATIO'] }
                    };

                    xe = null;
                    h = null;
                    ye = null;
                    Xe = null;
                    Me = null;
                    Po = null;
                    Ro = null;

                    n = {};

                    So = false;

                    ur = $('#change');
                    ur[0].changed_at = 0;

                    Ue = 0;

                    window.$is_mobile = /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(
                        navigator.userAgent
                    );

                    window.$is_zh = (window.navigator.userLanguage || window.navigator.language).indexOf('zh') !== -1;

                    Gt = {
                        depth_hint: true,
                        sidebar: true
                    };

                    ba = $hsymbol.match(/(.+) (.+)/);

                    sl = ba[0];
                    Ze = ba[1];
                    E = ba[2];

                    j = '';
                    C = '';

                    if ((iu = E.match(/(.+)\/(.+)/))) {
                        tr = iu[2];

                        j = iu[1];
                        C = iu[2];

                        f = '' + iu[1] + iu[2];
                        vo = '' + iu[2] + t.Hj + iu[1];
                    } else if (E === t.dT) {
                        tr = t.dT;
                        f = t.dT;
                        vo = t.dT;
                        C = t.ni;
                        j = t.dT;
                    } else {
                        tr = t.ni;
                        f = t.ni;
                        vo = t.ni;
                        C = '';
                        j = t.ni;
                    }

                    $(".o li[v=base]").text(t.zj + j + t.TZ);
                    $(".o li[v=quote]").text(t.Xf + C + t.TZ);

                    ru('' + tr + t.Iz + f + t.Iz + vo);

                    xo = 1;
                    $o = 0;
                    yo = 1;
                    wo = 1;

                    ti = false;

                    calcPrecision = function(recreateDepth) {
                        var n = vl([5, 2, 1], 0.01);
                        if (1e-7 >= n) {
                            yo = 1e8;
                            wo = 1e8;
                        } else {
                            yo = Math.pow(10, Math.ceil(Math.log(1 / n) / Math.log(10)));
                            wo = 1;
                        }
                        $o = 0;
                        if (1 > yo) {
                            yo = 1;
                        } else if (yo === 10) {
                            yo = 1;
                            $o = 1;
                        } else if (yo === 100) {
                            yo = 1;
                            $o = 2;
                        }
                        xo = n;
                        ru([xo, yo, $o, wo]);

                        if(recreateDepth) {
                            tt = Se(xo);
                        }
                    };

                    if(window.$init_price > 0) {
                        calcPrecision(false);
                    }

                    Pr = C;

                    if (wo > 1e7) {
                        C = t.DJ;
                        f = '' + j + C;
                        vo = '' + C + t.Hj + j;
                    }

                    tt = Se(xo);
                    a = !window.$disable_volume || false;
                    e = Ze !== t.Ob;
                    jt = null;

                    switch ($symbol) {
                        case 'mtgoxbtcusd':
                            jt = 500;
                            break;
                        case 'bitstampbtcusd':
                            jt = 500;
                            break;
                        case 'btcchinabtccny':
                        case 'huobibtccny':
                            jt = 200;
                            break;
                        case 'btcebtcusd':
                            jt = 200;
                            break;
                        case 'btceltcusd':
                            jt = 5000;
                            break;
                        case 'btceltcbtc':
                            jt = 5000;
                            break;
                        case 'okcoinltccny':
                            jt = 5000;
                    }

                    pe = Ze === t.ZE,
                    he = Ze === t.Ob,
                    ki = null,
                    Si = null,
                    xi = window.$is_flutter_view ? 100 : 100, // right sidebar with depth chart width
                    Rr = window.$is_mobile ? 6 : 8,
                    yi = parseInt(null != (ga = Ku("system:barWidth")) ? ga : 5),
                    Ai = parseInt(null != (ma = Ku("system:gapWidth")) ? ma : 3),
                    al = (yi - 1) / 2,
                    Hi = 0,
                    Ti = yi + Ai,
                    uu = null,
                    $i = null,
                    wi = null,
                    Wu = null,
                    Cu = null,
                    Ji = null,
                    Pi = null,
                    Au = null,
                    Yu = null,
                    Ni = !1, cu = !1, su = !1, au = !1,
                    eu = t.XB,
                    Di = t.eH,
                    Du = t.bh,
                    Ci = null, Ui = null, Ne = null, Qe = null, Tr = null, ho = 0, Li = null, Wi = null,
                    Mi = be(),
                    window.$conf = Mi,
                    (Zi = function() {
                        var n, e, r, o, i, u, l, a, s, f, h;
                        r = $.cookie(), o = function() {
                            var n;
                            n = [];
                            for (ji in ie) so.call(ie, ji) && (u = ie[ji], n.push(u.cookie));
                            return n
                        }(), n = function() {
                            var n, t;
                            n = Mi[c], t = [];
                            for (ji in n) so.call(n, ji) && (u = n[ji], t.push(ji));
                            return t
                        }(), a = [t.NX, t.ud, t.NV], e = [t.Ao, t.Bm, t.NT];
                        for (ji in r)
                            if (so.call(r, ji) && (ol = r[ji], l = null, ji !== "theme")) {
                                if (co.call(n, ji) >= 0 && (l = t.NR), co.call(o, ji) >= 0) {
                                    l = t.VT;
                                    try {
                                        ol = JSON.parse(ol)
                                    } catch (d) {}
                                }
                                co.call(a, ji) >= 0 && (l = t.Wm), l && Ku(l + t.TC + ji, ol), ji.match(/^_/) || co.call(e, ji) >= 0 || $.removeCookie(ji, {
                                    path: "/"
                                })
                            } for (f = 0, h = n.length; h > f; f++)
                            if (i = n[f], s = Ku(t.Xc + i), null != s) try {
                                ge(Mi, i, s)
                            } catch (d) {
                                Ku.rm(t.Xc + i)
                            }
                    })(),
                        ve(Mi, function(n, e) {
                            return Ku(t.Xc + n, e)
                        }),
                        function() {
                            function n() {
                                cl(), Kr(1000, function(t) {
                                    n(t)
                                })
                            }
                            n()
                        }(),
                        lockCross = false,
                        g = null,
                        F = null,
                        zi = !1,
                        Bo = H,
                        Oo = j,
                        function() {
                            function n(n) {
                                var e = n.d.updated_at;
                                var t = (Date.now() / 1000 - e) << 0;

                                e = parseInt(e);
                                t = sr(t);

                                return n.date.html(t);
                            }

                            function e(n) {
                                if (d) {
                                    d.removeClass("selected");
                                }

                                d = $(t.NG, n);

                                return d.addClass("selected");
                            }

                            function r(r) {
                                var o;
                                var i;
                                var a;
                                var s;
                                var u;
                                var c;

                                if ($user_id) {
                                    o = r;

                                    u = o.id;
                                    a = o.subid;
                                    s = o.symbol;
                                    c = o.updated_at;

                                    i = $("<div>");
                                    i.d = o;
                                    i.html(t.RH + s + t.Iz + a + t.my);
                                    i.date = $("g", i);

                                    n(i);

                                    i.click(function() {
                                        var handleDrawingGet = function(n) {
                                            if (n.ok) {
                                                o = n.data;
                                                l = o.id;
                                                Vo = JSON.parse(n.data.drawing);
                                                To = null;
                                                cu = true;

                                                return e(i);
                                            }
                                        };

                                        if(DrawingsStore.has(o.id)) {
                                            var n = DrawingsStore.get(o.id);

                                            handleDrawingGet(n);
                                        } else {
                                            call_api("drawing_get", {id: o.id}, function () {
                                                var n = arguments[0];

                                                handleDrawingGet(n);
                                            });
                                        }
                                    });

                                    return i;
                                }
                            }

                            function o(n) {
                                var t;
                                return t = r(n), s[n.id] = t, f.prepend(t)
                            }

                            function i() {
                                var n, r, i, u;

                                if(!$user_id) {
                                    return;
                                }

                                r = (function() {
                                    var e = [];
                                    var n = 0;

                                    for (var t = Vo.length; t > n; n++) {
                                        u = Vo[n];
                                        e.push(Qr(u));
                                    }

                                    return e;
                                })();

                                var handleDrawingReplace = function(i) {
                                    if (i.ok) {
                                        n = r = i.data;
                                        l = n.id;

                                        if (s[l]) {
                                            return (s[l].d = n);
                                        } else {
                                            o(n);

                                            return e(s[l]);
                                        }
                                    } else {
                                        return;
                                    }
                                };

                                // create new drawings locally
                                if(!l || DrawingsStore.has(l)) {
                                    var i = DrawingsStore.set(l, JSON.stringify(r));

                                    handleDrawingReplace(i);
                                } else {
                                    call_api("drawing_replace",
                                        {
                                            id: l,
                                            symbol: window.$symbol,
                                            drawing: JSON.stringify(r)
                                        },
                                        function() {
                                            i = arguments[0];

                                            handleDrawingReplace(i);
                                        }
                                    );
                                }
                            }

                            function u(n) {
                                s[n].remove();

                                delete s[n];

                                var e = $("div:first", f).click();

                                if (e.length === 0) {
                                    Vo = [];
                                    To = null;
                                    l = 0;
                                }

                                cu = true;

                                var handleDrawingDelete = function(i) {
                                    if (e.length === 0) {
                                        c();
                                    }

                                    cu = true;

                                    return true;
                                };

                                if(DrawingsStore.has(n)) {
                                    var i = DrawingsStore.delete(n);

                                    handleDrawingDelete(i);
                                } else {
                                    call_api("drawing_delete", { id: n }, function() {
                                        handleDrawingDelete(arguments[0]);
                                    });
                                }

                            }

                            var l, a, s, c, f, h, d;

                            l = null;
                            s = {};
                            f = $("#drawings");
                            d = null;

                            (c = function() {
                                var n, r, i;

                                var u = this;

                                var handleDrawingsGetAll = function(i) {
                                    if (i.ok) {
                                        var c = i.data.reverse();
                                        var l = 0;

                                        for (var a = c.length; a > l; l++) {
                                            n = c[l];

                                            o(n);

                                            if (r.updated_at < n.updated_at) {
                                                r = n;
                                            }
                                        }

                                        if (r) {
                                            s[r.id].click();
                                        }
                                    }
                                };

                                if ($user_id) {
                                    r = null;

                                    var localData = DrawingsStore.getAll(window.$symbol);

                                    if(localData && localData.data.length) {
                                        r = localData.data[0];
                                    }

                                    handleDrawingsGetAll(localData);

                                    call_api("drawing_get_all", { symbol: window.$symbol }, function(i) {
                                        var i = arguments[0];

                                        if (i.ok) {
                                            if(!r || r.updated_at < n.updated_at) {
                                                r = i.data[0];
                                            }
                                        }

                                        handleDrawingsGetAll(i);

                                        return u;
                                    });

                                    return;
                                } else {
                                    f.html("Need login first.");
                                    return;
                                }
                            })();

                            $(t.MO).click(function() {
                                l = 0;
                                return i();
                            });

                            $(t.Nx).click(function() {
                                l = 0;
                                Vo = [];
                                To = null;
                                cu = true;
                                i();
                                return false;
                            });

                            a = [];

                            $(t.Nw).click(function() {
                                a.push(Vo);
                                var n = s[l];
                                if (n) {
                                    u(l);
                                    return $(t.Xo)
                                        .html(t.IM + a.length + t.TZ)
                                        .show();
                                } else {
                                    return;
                                }
                            });

                            $(t.Xo).click(function() {
                                l = 0;
                                Vo = a.pop();
                                $(t.Xo)
                                    .html(t.IM + a.length + t.TZ)
                                    .show();
                                cu = true;
                                i();
                                if (a.length === 0) {
                                    return $(this).hide();
                                } else {
                                    return;
                                }
                            });

                            (h = function() {
                                var t;
                                for (ji in s) {
                                    if (so.call(s, ji)) {
                                        t = s[ji];
                                        n(t);
                                    }
                                }
                                Kr(1000, function() {
                                    return h();
                                });
                            })();

                            return (Dr = i)
                        }(),
                        function() {
                            var heatmapArguments = [];

                            var requestHeatmapVar = false;
                            var requestCrossVar = false;
                            var requestMainVar = false;
                            var requestShapesVar = false;

                            var frameRedrawRequest = false;

                            function rafLoop() {
                                if(!frameRedrawRequest) {
                                    return;
                                }

                                if(requestMainVar) {
                                    ul();

                                    requestMainVar = false;
                                }

                                if(requestCrossVar) {
                                    Il();

                                    requestCrossVar = false;
                                }

                                if(requestHeatmapVar) {
                                    window.Heatmap.draw(
                                        heatmapArguments[0],
                                        heatmapArguments[1],
                                        heatmapArguments[2],
                                        heatmapArguments[3],
                                        heatmapArguments[4],
                                        heatmapArguments[5],
                                        heatmapArguments[6],
                                        heatmapArguments[7]
                                    );

                                    requestHeatmapVar = false;
                                }

                                // shapes draw time is almost 12ms on mobile
                                if(requestShapesVar) {
                                    Zl();

                                    requestShapesVar = false;
                                }

                                frameRedrawRequest = false;
                            }

                            /*
59398.559999999634 "request 1"
59398.699999999735 "request 2"

59409.70499999912 59398.699999999735 "frame 2" 1581161940 <-- HOW IS THIS POSSIBLE?!
59418.85999999977 59398.559999999634 "frame 1" 1581162060
*/

                            var requestTime, frameTimeout;

                            function requestFrameRedraw() {
                                if(frameRedrawRequest) {
                                    return;
                                }

                                frameRedrawRequest = true;

                                if('hidden' in document && document.hidden) {
                                    setTimeout(function () {
                                        rafLoop();
                                    }, 1000);

                                    return;
                                } else {
                                    window.requestAnimationFrame(rafLoop);
                                }
                            }

                            redrawHeatmap = function(Xe, Po, To, Mo, Ti, Hi, Mi, D) {
                                if(!('Heatmap' in window)) {
                                    return;
                                }

                                heatmapArguments = [Xe, Po, To, Mo, Ti, Hi, Mi, D];

                                requestHeatmapVar = true;
                                requestFrameRedraw();
                            };

                            redrawCross = function() {
                                requestCrossVar = true;
                                requestFrameRedraw();
                            };

                            redrawMain = function() {
                                requestMainVar = true;
                                requestShapesVar = true;

                                requestFrameRedraw();
                            };

                            redrawShapes = function() {
                                requestShapesVar = true;
                                requestFrameRedraw();
                            };
                        }(),
                        function() {
                            var o = false;

                            // calculate distance maybe?
                            // sets cursor
                            function n(event) {
                                // al & Hi - candles' width and gap

                                // Ti - barSize (expected, px)
                                // Hi - barSize (expected, px)

                            //    var eventX = event.pageX;
                            //    var eventY = event.pageX;

                                var eventX, eventY;

                                if(event.center) {
                                    eventX = event.center.x;
                                    eventY = event.center.y;
                                } else {
                                    if (!event.pageX && event.originalEvent && event.originalEvent.touches) {
                                        event = event.originalEvent.touches.item(0);
                                    }

                                    eventX = event.pageX;
                                    eventY = event.pageY;
                                }

                                var actualBarSize = Ti;

                                var t = eventX;
                                var e = eventY - kuHeight;

                                Au = t;
                                Yu = e;
                                Wi = Math.floor((t - Hi) / Ti);
                                t = Wi * actualBarSize + al + Hi;
                                Ji = t;
                                Pi = e;
                                kr = eventX > Si - xi;
                            }

                            function e(event) {
                                var r, i, u, l, a, s, c, f, h, d, p, v, b, g, m, F, x, y;

                                o = true;

                                if (!lockCross) {
                                    n(event);

                                    redrawCross();

                                    if (Ni) {
                                        redrawMain();
                                    }
                                }

                                if (Xe) {
                                    f = Er[w];
                                }

                                if (Ye === ui) {
                                    return true;
                                }

                                if(!Xe || !Xe.hasOwnProperty(vt)) {
                                    console.error("Xe error");
                                    return true;
                                }

                                window.Measurer('cursorEvent').start();

                                if (!To) {
                                    To = Ar(fl(Ye));

                                    if(Vo.length > 50) {
                                        alert("Maximum number of drawing (50) exceeded.")
                                    } else {
                                        Vo.push(To);
                                    }
                                }

                                switch (Xe[vt]) {
                                    case yt:
                                        To[B] = z;
                                        break;

                                    case xt:
                                        To[B] = K;
                                }

                                p = Ml(Xe, Pi);

                                if (Ui >= Ci + Wi) {
                                    ol = Dt(f, Ci + Wi, k);

                                    g = p;

                                    a = ol[X];
                                    u = ol[M];
                                    l = ol[I];
                                    i = ol[T];

                                    c = [a, u, l, i];

                                    r = Mi.stick_style;

                                    if (r === 'line' || r === 'line_o') {
                                        switch (Mi.line_style) {
                                            case 'm':
                                                v = Dt(f, Ci + Wi, Er[ii]);
                                                c.push(v[Er[ii]]);
                                                break;

                                            case 'w':
                                                v = Dt(f, Ci + Wi, Er[mi]);
                                                c.push(v[Er[mi]]);
                                        }
                                    }

                                    c = (function() {
                                        var e = [];
                                        var t = c.length;
                                        for (var n = 0; n < t; n++) {
                                            s = c[n];
                                            e.push([s, Math.abs(s - p)]);
                                        }
                                        return e;
                                    })();

                                    c.sort(function(n, t) {
                                        return n[1] - t[1];
                                    });

                                    p = c[0][0];

                                    x = uo(Xe, p);

                                    // ctrl
                                    d = event != null ? event.ctrlKey : void 0;

                                    if ((d || !To[nt]) && Math.abs(Pi - x) > 8) {
                                        p = g;
                                    }

                                    F = [ol[J], p];
                                } else {
                                    b = Dt(f, Ui, k);

                                    if (b[V] == null) {
                                        return;
                                    }

                                    h = new Date(b[V]);
                                    h.setSeconds(Io * (Wi - (Ui - Ci)));

                                    F = [
                                        parseInt(h.getTime() / 1000),
                                        p
                                    ];
                                }

                                // draw horizonal line (start y = end y)
                                if (event && event.shiftKey) {
                                    if (((y = To[ot]) === D || y === Q) && To[q].length) {
                                        m = To[q][0];

                                        F[1] = m[1];
                                    }
                                }

                                Yr(To, F);

                                window.Measurer('cursorEvent').stop();

                                redrawShapes();

                                return true;
                            }

                            // save drawing
                            function r() {
                                if (Xe && (To != null ? To[q].length : void 0) && Wr(To)) {
                                    Dr();

                                    return (To = null);
                                } else {
                                    return;
                                }
                            }

                            function refreshLoop() {
                                window.Measurer('frameTime').start();

                                window.requestAnimationFrame(function() {
                                    window.Measurer('frameTime').stop();

                                    if (window.stats) {
                                        window.stats.update();
                                    }

                                    refreshLoop();
                                });
                            }

                            if (window.$debug && $is_mobile) {
                                refreshLoop();
                            }

                            var canClearChartInfo = true;

                            function clearChartInfo() {
                                if(!canClearChartInfo) {
                                    return;
                                }

                                isCrosshairInfoVisibleForMobile = false;

                                if (!lockCross) {
                                    Ji = null;
                                    Au = null;

                                    yu.html('');
                                    Su.html('');

                                    yl();
                                }

                                canClearChartInfo = !window.$is_mobile;

                                return true;
                            }

                            function touchEnd(ev) {
                                if (!o && Ye !== ui && Mo) {
                                    r();
                                }

                                Ni = false;

                                return true;
                            };

                            var touchStart = function(event, forceMove) {
                                var r, i;

                                // right click removes last drawn object
                                if (event.button === 2 && Ye !== ui && Mo) {
                                    if (To) {
                                        if (To[q].length === 1) {
                                            Vo.pop();
                                        }

                                        To = null;
                                    }

                                    Vo.pop();

                                    Dr();

                                    redrawShapes();
                                }

                                if (event.eventPhase === 3 || forceMove) {
                                    Ni = true;
                                    o = false;
                                }

                                n(event);

                                Li = Ci + Wi;

                                if (kr && Ye === ui) {
                                    var canAddMoreAlerts = !window.$is_flutter_view || FlutterFunctions.canAddMoreAlerts;

                                    ru([h, xe[st] * wo]);

                                    r = h;

                                    var alertSide = "low";

                                    if (h > xe[st] * wo) {
                                        alertSide = "high";

                                        i = parseFloat(pu.val());

                                        if (i === r) {
                                            r = "";
                                        }

                                        if(r !== "" && !canAddMoreAlerts) {
                                            if (window.$is_flutter_view) {
                                                FlutterFunctions.sendCantAddMoreAlerts(alertSide, r / wo);
                                            }
                                        } else {
                                            pu.val(r);

                                            Ku("" + $symbol + ":alarm:high", r);

                                            if (window.$is_flutter_view) {
                                                try {
                                                    window.FlutterFunctions.updateAlert(alertSide, r / wo);
                                                } catch (err) {
                                                    console.error(err);
                                                }
                                            }
                                        }
                                    } else {
                                        i = parseFloat(vu.val());

                                        if (i === r) {
                                            r = "";
                                        }

                                        if(r !== "" && !canAddMoreAlerts) {
                                            if (window.$is_flutter_view) {
                                                FlutterFunctions.sendCantAddMoreAlerts(alertSide, r / wo);
                                            }
                                        } else {
                                            vu.val(r);

                                            Ku("" + $symbol + ":alarm:low", r);

                                            if (window.$is_flutter_view) {
                                                try {
                                                    window.FlutterFunctions.updateAlert(alertSide, r / wo);
                                                } catch (err) {
                                                    console.error(err);
                                                }
                                            }
                                        }
                                    }

                                    cu = true;
                                }
                            };

                            scaleGraph = function(e, delta) {
                                // todo: check for deltaX for touchpad
                                var offset = e && e.hasOwnProperty('deltaY') ? e.deltaY : delta;

                                if (offset > 0) {
                                    yi += 2;
                                } else {
                                    yi -= 2;
                                }

                                if (yi < 3) {
                                    yi = 1;
                                }

                                if (yi > 27) {
                                    yi = 27;
                                }

                                Ai = Math.round(0.2 * yi);

                                if (Ai < 3) {
                                    Ai = 3;
                                }

                                if (yi === 3) {
                                    Ai = 2;
                                }

                                if (yi === 1) {
                                    Ai = 1;
                                }

                                Ti = yi + Ai;
                                al = (yi - 1) / 2;

                                Ku("system:barWidth", yi);
                                Ku("system:gapWidth", Ai);

                                Fl();

                                return false;
                            };

                            if (!$is_mobile) {
                                Nu.mouseout(clearChartInfo);
                            } else {
                                document.body.addEventListener('touchstart',function(e) {
                                    var touch = e.touches[0];

                                    mouseX = touch.pageX;
                                    mouseY = touch.pageY;

                                    var rect = Nu.get(0).getBoundingClientRect();

                                    if(mouseX < rect.left || mouseX > rect.right || mouseY < rect.top || mouseY > rect.bottom) {
                                        canClearChartInfo = true;
                                        clearChartInfo();
                                    }
                                });
                            }

                            Nu.mousewheel(scaleGraph);

                            Nu.contextmenu(function() {
                                return false;
                            });

                            // double click on chart
                            /*Nu.dblclick(function(evt) {
                                if (!kr) {
                                    if (evt.button === 0) {
                                    //    if (autoScale) {
                                        if (lockCross) {
                                            gl();
                                        } else {
                                            bl();
                                        }
                                    }
                                }

                                return true;
                            });*/

                            if(window.$is_mobile && !window.$is_desktop_mode) {
                                var element = document.getElementById('wrapper');
                                var mc = new Hammer(element);

                                var lastPinchValue = 1;
                                mc.get('pinch').set({enable: true, threshold: 0});

                                mc.on("pinch", function (ev) {
                                    if (Math.abs(1 - ev.scale) <= 0.1) {
                                        lastPinchValue = 1;

                                        return;
                                    }

                                    if (Math.abs(lastPinchValue - ev.scale) > 0.1) {
                                        console.log(ev.scale);

                                        if (ev.scale > lastPinchValue) {
                                            scaleGraph(null, 1);
                                        } else {
                                            scaleGraph(null, -1);
                                        }

                                        lastPinchValue = ev.scale;
                                    }
                                });

                                mc.get('pan').set({ enable: true, direction: Hammer.DIRECTION_ALL });
                                mc.get('tap').set({ enable: true });

                                mc.on("panstart", function(ev) {
                                //    console.log(ev.type);

                                    touchStart(ev, true);
                                });

                                mc.on("panend", function(ev) {
                                //    console.log(ev.type);

                                    touchEnd(ev);
                                });

                                mc.on("panmove", function(ev) {
                                //    console.log(ev.type);

                                    clearChartInfo();

                                    e(ev);
                                });

                                mc.on("tap", function(ev) {
                                //    console.log(ev.type);

                                    drawChartInfo = true;
                                    canClearChartInfo = true;

                                    e(ev);

                                    requestAnimationFrame(function() {
                                        touchStart(ev, true);
                                        touchEnd(ev);
                                    })
                                });
                            } else {
                                // actionStart
                                Nu.bind("mousedown", function(ev) {
                                    touchStart(ev, false);
                                });

                                Nu.bind("touchstart", function(ev) {
                                    if (shouldIgnoreEvent(Mi)) return;

                                    touchStart(ev, false);
                                });

                                // move
                                Nu.bind("mousemove touchstart touchmove", function(ev) {
                                    return e(ev)
                                });

                                // actionEnd
                                Eu.bind('mouseup', function(ev) {
                                    return touchEnd(ev);
                                });

                                Eu.bind('touchend', function(ev) {
                                    if (shouldIgnoreEvent(Mi)) return;

                                    return touchEnd(ev);
                                });
                            }

                            return false;
                        }(),
                        s = !0,
                        ve(Mi, "volume_currency", function(n) {
                            var e, r, o, i, u;
                            if (n) {
                                ru(n);
                                if (n === "base") {
                                    Oo = j;
                                    Bo = H;
                                } else {
                                    Oo = C;
                                    Bo = P;
                                }

                                s = false;

                                setTimeout(function() {
                                    return (s = true);
                                }, 200);

                                u = Ei.childNodes;

                                o = 0;

                                for (i = u.length; i > o; o++) {
                                    e = u[o];
                                    r = e.tx;
                                    if (Bo === H) {
                                        $(".v", e).html(zl(r[lt]));
                                    } else {
                                        $(".v", e).html(zl(r[st] * r[lt]));
                                    }
                                }

                                au = true;

                                return ae;
                            }
                        }),

                    du = [], Lu = [],
                    Gi = null,
                    qi = null,
                    Kt = null,

                    ve(Mi, "depth_range", function() {
                        return dt = !0
                    }),

                    window.world_draw_main = ul = function() {
                        if (window.isDrawing && new Date() - window.$load_time > 10000) return;

                        window.Measurer('draw_main').start();

                        window.isDrawing = true;

                        // check before comitting
                        if (!window.resetIsDrawing) {
                            window.resetIsDrawing = setTimeout(function() {
                                window.isDrawing = false

                                clearTimeout(window.resetIsDrawing);

                                window.resetIsDrawing = null;
                            }, 5000);
                        }

                        // reserve space for volume
                        function r(n) {
                            if (n == null) {
                                n = Rr;
                            }

                            D.y = D.y + D.h - n - 1;

                            bt.push(D.y);

                            ge.strokeStyle = Jo[t.Np];

                            Ee(ge, D.y + 0.5, 0, Si);

                            return (D.y -= n);
                        }

                        function o(n1, n2, n3) {
                            var e, r, o, i, l, a, s, c, f, h, d, p, key;

                            s = U[0];

                            var fillStyle, strokeStyle, volumeStrokeStyle;
                            var drawData = {};

                            fillStyle = Jo[t.SW];
                            strokeStyle = Jo[t.PO];
                            volumeStrokeStyle = Jo[t.PO];

                            p = [];

                            for (it = c = 0, f = U.length; c < f; it = ++c) {
                                lt = U[it];

                                switch (u) {
                                    case "candle_stick_hlc":
                                        a = (h = U[it - 1]) != null ? h : ye[it];
                                        r = U[it];
                                        o = a;
                                        e = r;
                                        break;

                                    default:
                                        a = ye[it],
                                        r = U[it],
                                        o = a,
                                        e = r,
                                        a === r && (
                                            o = null != (d = ye[it - 1]) ? d : ye[it]
                                        )
                                }

                                l = At[it];
                                i = vt[it];

                                if(e > o) {
                                    fillStyle = Jo[t.BC];
                                    strokeStyle = Jo[t.PO];
                                } else if(o > e) {
                                    fillStyle = Jo[t.FR];
                                    strokeStyle = Jo[t.PQ];
                                }

                                if(qt && dl(e, o)) {
                                    fillStyle = Jo[t.SW];
                                }

                                volumeStrokeStyle = strokeStyle;

                                if (u === "ohlc") {
                                    strokeStyle = Jo["OHLC"];
                                }

                                key = "fill_" + fillStyle + "_stroke_" + strokeStyle + "_volume_" + volumeStrokeStyle;

                                if(!drawData.hasOwnProperty(key)) {
                                    drawData[key] = {
                                        fillStyle: fillStyle,
                                        strokeStyle: strokeStyle,
                                        volumeStrokeStyle: volumeStrokeStyle,
                                        data: []
                                    }
                                }

                                drawData[key].data.push([
                                    // index, open, close, low, high
                                    it, a, r, l, i
                                ])

                                p.push(s = lt)
                            }

                            function runDrawData(nCb) {
                                for(var j in bars) {
                                    if(!drawData[key].data.hasOwnProperty(j)) {
                                        continue;
                                    }

                                    var dd = drawData[key].data[j];

                                    nCb(dd[0], dd[1], dd[2], dd[3], dd[4]);
                                }
                            }

                            var usePath2D = false;

                            for(key in drawData) {
                                var barConfig = drawData[key];
                                var bars = barConfig.data;

                                ge.fillStyle = barConfig.fillStyle;
                                ge.strokeStyle = barConfig.strokeStyle;

                                var path;

                                if(usePath2D && window.Path2D) {
                                    path = new Path2D();
                                } else {
                                    path = ge;
                                    path.beginPath();
                                }

                                const styleEqStrokeStyle = barConfig.fillStyle === barConfig.strokeStyle;

                                runDrawData(function(it, a, r, l, i) {
                                    n1(it, a, r, l, i, path, styleEqStrokeStyle);
                                });

                                if(usePath2D && window.Path2D) {
                                    ge.stroke(path);
                                } else {
                                    ge.stroke();
                                }

                                runDrawData(function(it, a, r, l, i) {
                                    n2(it, a, r, l, i, null, styleEqStrokeStyle);
                                });

                                ge.strokeStyle = barConfig.volumeStrokeStyle;

                                runDrawData(function(it, a, r, l, i) {
                                    n3(it, styleEqStrokeStyle);
                                });
                            }

                            // p is array of closing prices

                            return p
                        }

                        var u, l, s, c, f, h, p, b, g, m, F, $, k, _, Z, B, O, R, E, N, L, U, W, C, Q, A, Y, D, K, z, j, G, q, nt, et, rt, ot, it, ut, lt, at, ct, ft, ht, pt, vt, bt, gt, xt, yt, $t, kt, _t, It, Xt, Ot, Rt, Et, Ut, Wt, Qt, At, Dt, zt, jt, Gt, ne, te, oe, ue, le, ae, se, ce, fe, he, de, pe, ve, be, ge, me, ye, $e, we, Te, Ve, ke, Se, _e, Ie, Le, Ue, Ce, Ye, je, Ge, nr, tr, er, rr, ir, ur, lr, sr, cr, fr, hr, pr, br, gr, mr, Fr, yr, $r, Vr, kr, Mr, Xr, Zr, Jr, Pr, Hr, Br, Or, Nr, Lr, Ur, Wr, Cr, Qr, Ar, Yr, Dr, Kr, zr, jr, qr, no, to, io, uo, co, fo, po, vo, bo, go, mo, Fo, xo, yo, $o, To;

                        if (Er) {
                            window.Measurer('main-canvas').start();
                            window.Measurer('main-canvas-p1').start();

                            //    return ml(Ii); // clear main

                            xl();
                            F = $ = Er[w];
                            u = Mi["stick_style"];

                            So = xe && Io < 3600 && Je && Oe && Mi["depth_range"] !== "none";
                            ge = Ii;

                            ml(ge, ge.canvas.width, ge.canvas.height);

                            ut = Yt($, T).length - 1;

                            if (ut > ho && Ci && ho === Ci + $i - 1) {
                                Ci += ut - ho;
                                ho = ut;
                            }

                            if (Ci > ut) {
                                Ci = ut;
                            }

                            if (0 > Ci) {
                                Ci = 0;
                            }

                            Ui = Ci + $i - 1;

                            if (Ui > ut) {
                                Ui = ut;
                            }

                            s = parseFloat(pu.val());
                            c = parseFloat(vu.val());

                            Y = Mi["market"] != null && Mi["market"] !== "none";

                            mr = [];

                            pe = Mi["main_lines"];
                            $e = ie[pe];

                            if (Y) {
                                pe = "none";
                                u = "line";
                            }

                            switch (pe) {
                                case 'mas':
                                    if (Er[pe] == null) {
                                        Er[pe] = (function () {
                                            var e = ie.price_mas.params;
                                            var r = [];
                                            var n = 0;
                                            for (var t = e.length; t > n; n++) {
                                                G = e[n];
                                                r.push(Pt(F, T, G));
                                            }
                                            return r;
                                        })();
                                    }
                                    break;
                                case 'emas':
                                    if (Er[pe] == null) {
                                        Er[pe] = (function () {
                                            var e = ie.price_mas.params;
                                            var r = [];
                                            var n = 0;
                                            for (var t = e.length; t > n; n++) {
                                                G = e[n];
                                                r.push(Zt(F, T, G));
                                            }
                                            return r;
                                        })();
                                    }
                                    break;
                                case 'bbands':
                                case 'sar':
                                    if (Er[pe] == null) {
                                        Er[pe] = $e[t.Lz].apply($e, [F, $e[t.VV]].concat(ao.call($e[t.UB])));
                                    }
                                    break;
                                case 'none':
                                    if (Er[pe] == null) {
                                        Er[pe] = [];
                                    }
                            }

                            switch (((Ie = Er[pe]), (pe = Mi[t.VT]))) {
                                case 'macd':
                                    if (Er[pe] == null) {
                                        Er[pe] = Ht.apply(null, [F, T].concat(ao.call(ie.macd.params)));
                                    }
                                    ne = Er[pe];
                                    xo = Vt(ee, $, Ci, Ui, ne);
                                    rt = xo[0];
                                    et = xo[1];
                                    zt = xo[2];
                                    It = [rt, et];
                                    pt = Sr([rt, et, zt]);
                                    Xt = -pt;
                                    _t = 2 * pt;
                                    break;
                                case 'stoch_rsi':
                                    if (Er[pe] == null) {
                                        Er[pe] = Lt.apply(null, [F, T].concat(ao.call(ie.stoch_rsi.params)));
                                    }
                                    je = Er[pe];
                                    yo = Vt(ee, $, Ci, Ui, je);
                                    Ue = yo[0];
                                    Le = yo[1];
                                    It = [Ue, Le];
                                    Xt = 0;
                                    _t = 100;
                                    break;
                                case 'kdj':
                                    if (Er[pe] == null) {
                                        Er[pe] = Jt.apply(null, [F, [M, I, T]].concat(ao.call(ie.kdj.params)));
                                    }
                                    Rt = Er[pe];
                                    $o = Vt(ee, $, Ci, Ui, Rt);
                                    ji = $o[0];
                                    q = $o[1];
                                    Ot = $o[2];
                                    It = [ji, q, Ot];
                                    ue = _r([ji, q, Ot, [100]]);
                                    ce = Ir([ji, q, Ot, [0]]);
                                    Xt = ce;
                                    _t = ue - ce;
                                    break;
                                case 'bid_ratio':
                                    if (Er[pe] == null) {
                                        Er[pe] = Mt.apply(null, [F, [x, y]].concat(ao.call(ie.bid_ratio.params)));
                                    }
                                    g = Vt(ee, $, Ci, Ui, Er[pe]);
                                    Xt = 0;
                                    _t = 1;
                                    ue = _r(g);
                                    ce = Ir(g);
                                    Xt = 0.5 - Math.max(0.5 - ce, ue - 0.5);
                                    _t = 1 - 2 * Xt;
                                    It = g;
                                    break;
                                case 'none':
                                    break;
                                default:
                                    pe = Mi[t.VT];
                                    $e = ie[pe];
                                    Ve = $e[t.VV];

                                    if (Er[pe] == null) {
                                        Er[pe] = $e[t.Lz].apply($e, [F, Ve].concat(ao.call($e["params"])));
                                    }

                                    Qt = Vt(ee, $, Ci, Ui, Er[pe]);

                                    switch ($e["axis"]) {
                                        case 'symm':
                                            pt = Sr(Qt);
                                            Xt = -pt;
                                            _t = 2 * pt;
                                            break;
                                        case '100':
                                            Xt = 0;
                                            _t = 100;
                                            break;
                                        case 'auto':
                                            ce = Ir(Qt);
                                            ue = _r(Qt);
                                            Xt = ce;
                                            _t = ue - ce;
                                            break;
                                        case 'custom':
                                            ce = $e[t.VR](Qt);
                                            ue = $e[t.Ug](Qt);
                                            Xt = ce;
                                            _t = ue - ce;
                                    }
                                    It = Qt;
                            }

                            to = Vt(ee, $, Ci, Ui, [Bo, X, T, M, I, V, J, x, y]);

                            // $ is candles data?
                            // Ci is probably first visible bar index
                            // Ui is probably the last visible bar index

                            // reason for diff time candles issue: all candles are init by the the time trade arrives, meaning
                            // the open price is already set or
                            // the close price is already set

                            yr = to[0];
                            // 1 is array of opening prices
                            ye = to[1];
                            // 2 is array of closing prices
                            U = to[2];
                            // 3 is array of highs prices
                            vt = to[3];
                            // 4 is array of lows prices
                            At = to[4];
                            // 5 is array of Date()
                            nt = to[5];
                            // 6 is array of unix timestamps
                            ur = to[6];
                            p = to[7];
                            m = to[8];

                            gr = Vt(ee, $, Ci, Ui, mr);
                            // Ie is list of visible main indicator objects (lines, mainly)
                            _e = Vt(ee, $, Ci, Ui, Ie);

                            Po = ur;

                            // get only visible candles
                            ht = Math.floor((Si - xi) / Ti);
                            yt = vt.slice(0, ht);
                            $t = At.slice(0, ht);

                            if (Er[mi] == null) {
                                Er[mi] = Ct($, [P, H, T]);
                            }

                            Xr = Vt(ee, $, Ci, Ui, Er[mi])[0];

                            if (u === "line" || u === "line_o") {
                                switch (Mi["line_style"]) {
                                    case 'm':
                                        if (Er[ii] == null) {
                                            Er[ii] = Bt(F, [X, M, I, T]);
                                        }
                                        U = Vt(ee, $, Ci, Ui, Er[ii])[0];
                                        break;
                                    case 'w':
                                        U = Xr;
                                }
                            }

                            K = $i * Ti;
                            Hi = Si - xi - wi * Ti;

                            D = {x: Hi, y: ki, w: K, h: ki};
                            ot = {x: 0, y: 0, w: $i, h: 0};

                            bt = [];

                            D.h = -16; // place for timestamp

                            b = lo(D, ot);

                            r(0);

                            D.y -= Rr;
                            D.h = -uu;

                            if (Mi[t.VT] === "none") {
                                Gt = null;
                            } else {
                                ot.y = Xt;
                                ot.h = _t;
                                Gt = lo(D, ot);
                                r();
                            }

                            if (gr.length) {
                                ot.y = 0;
                                ot.h = _r([gr, yr]);
                            } else {
                                ot.y = 0;
                                ot.h = _r([yr]);
                            }

                            Fr = lo(D, ot);

                            Me = Gt;
                            Ro = Fr;

                            var mainBottomPadding = window.$is_mobile ? 0 : 8;
                            var volumeTopPadding = Rr;

                            r(volumeTopPadding);

                            D.y -= mainBottomPadding; // main chart lower padding

                        //    window.Measurer('calc-low-high').start();

                            var heatmapsEnabled = (Mi['enable_heatmap'] === "yes") ||
                                ('$heatmap_settings' in window && window.$heatmap_settings.combined);

                            var calcLows = [];
                            var calcHighs = [];

                            if(heatmapsEnabled) {
                                if (_e.length) {
                                    calcHighs = [_e.slice(0), vt];
                                    calcLows  = [_e.slice(0), At];
                                } else {
                                    calcHighs = [[], vt];
                                    calcLows = [[], At];
                                }
                            } else {
                                if (_e.length) {
                                    calcHighs = [_e, vt];
                                    calcLows  = [_e, At];
                                } else {
                                    calcHighs = [vt];
                                    calcLows = [At];
                                }
                            }

                            // can be optimized
                            if(heatmapsEnabled) {
                                var heatmapsRange = 0.03;

                                if(Io <= 180) {
                                    heatmapsRange /= 2;
                                }

                                var index;
                                var vtTo = vt.length;
                                var AtTo = At.length;

                                index = calcHighs[0].length;
                                calcHighs[0][index] = [];

                                for(var vtI = 0; vtI < vtTo; vtI++) {
                                    calcHighs[0][index].push(vt[vtI] * (1 + heatmapsRange));
                                }

                                index = calcLows[0].length;
                                calcLows[0][index] = [];

                                for(var AtI = 0; AtI < AtTo; AtI++) {
                                    calcLows[0][index].push(At[AtI] * (1 - heatmapsRange));
                                }
                            }

                        //    window.Measurer('calc-low-high').stop();

                            // max value of all gt sub-arrays (candles and indicators)
                            ue = _r(calcHighs);
                            // min value of all visible candles and indicators
                            ce = Ir(calcLows);

                            calcHighs = null;
                            calcLows = null;

                            ir = wr(ur);
                            ve = Date.now() / 1000;

                            // ue and ce are equal to high / low visible prices

                            ue = (1 + 0.01) * ue;
                            ce = (1 - 0.01) * ce;

                            while(ue && ue < vt[vt.length - 1]) {
                                ue *= 1.01;
                            }

                            while(ce && ce > At[At.length - 1]) {
                                ce *= .99;
                            }

                            if(dt) {
                                pl("calc depth", function() {
                                    var nCounter = {
                                        1: 0, 2: 0
                                    };

                                    function e(n, nId) {
                                        var t;
                                        var nIndex = nCounter[nId];
                                        if ((t = n[nIndex])) {
                                            nCounter[nId]++;
                                            return [t[0], t[1]];
                                        } else {
                                            return null;
                                        }
                                    }

                                    /*function e(n) {
                                        var t;
                                        if ((t = n.shift())) {
                                            return [t[0], t[1]];
                                        } else {
                                            return null;
                                        }
                                    }*/

                                    var l;
                                    var s;
                                    var f;
                                    var b;
                                    var F;
                                    var y;
                                    var T;
                                    var V;
                                    var k;
                                    var S;
                                    var J;
                                    var P;
                                    var H;
                                    var O;
                                    var E;
                                    var N;
                                    var U;
                                    var W;

                                    dt = false;
                                    du = tt[d].flatten();
                                    Lu = tt[v].flatten().reverse();

                                    var sliceLimit = 800;

                                    switch(Mi["depth_range"]) {
                                        case "pmax":
                                            sliceLimit = 2500;
                                            break;

                                        case "p2":
                                        case "p5":
                                            sliceLimit = 1500;
                                            break;
                                    }

                                    var c = du.slice(0, sliceLimit);
                                    var g = Lu.slice(0, sliceLimit);
                                    var $ = [];
                                    var w = [];
                                    var a = e(c, 1);

                                    for (var p = e(g, 2); a && p; ) {
                                        if (a[1] <= p[1]) {
                                            if (a[1] > 1e-9) {
                                                $.push([a[0], a[1]]);
                                                w.push([p[0], a[1]]);
                                            }
                                            p[1] -= a[1];
                                            a = e(c, 1);
                                        } else if (p[1] <= a[1]) {
                                            if (p[1] > 1e-9) {
                                                $.push([a[0], p[1]]);
                                                w.push([p[0], p[1]]);
                                            }
                                            a[1] -= p[1];
                                            p = e(g, 2);
                                        }
                                    }

                                    var I = 0;
                                    var Z = 0;
                                    var h = [];

                                    f = 0;
                                    Gi = null;
                                    qi = null;

                                    if (Mi["depth_range"] === "pmax") {
                                        S = 2;
                                    } else if (Mi["depth_range"] === "none") {
                                        S = 1;
                                    } else {
                                        S = ((E = Mi["depth_range"]) != null ? E.match(/\d+/).toString() : void 0) >> 0;
                                        S = S / 100 + 1;
                                    }

                                    if (Tr !== S) {
                                        Ne = -100000000;
                                        Qe = 1e8;
                                        Tr = S;
                                        Nt = null;
                                        Tt = null;
                                        St = [];
                                    }

                                    var m = false;
                                    F = null;

                                    var _ = (Date.now() / 1000) >> 0;
                                    _ -= _ % 15;

                                    if (((N = St[0]) != null ? N[0] : void 0) !== _) {
                                        St.unshift([_, 0]);
                                    }

                                    if (St.length > 20) {
                                        St.pop();
                                        k = (function() {
                                            var e = [];
                                            var n = 0;
                                            for (var t = St.length; t > n; n++) {
                                                V = St[n];
                                                e.push(V[1]);
                                            }
                                            return e;
                                        })();
                                        Tt = Math.max.apply(Math, k);
                                        ru(t.VP + Tt);
                                    }

                                    var x = (P = 0);
                                    for (
                                        var L = $.length;
                                        (L >= 0 ? L > P : P > L) &&
                                        ((a = $[x]), (p = w[x]), a != null && p != null);
                                        x = L >= 0 ? ++P : --P
                                    ) {
                                        J = a[1];
                                        l = J;

                                        Gi = a;
                                        qi = p;

                                        if (F != null && Z / 2 + l > F) {
                                            l = F - Z / 2;
                                            m = true;
                                        }
                                        U = [a, p];
                                        H = 0;
                                        for (O = U.length; O > H; H++) {
                                            T = U[H];
                                            I += T[0] * l;
                                            Z += l;
                                        }
                                        f = I / Z;
                                        h.push([f, l, a[0], p[0]]);
                                        J -= l;
                                        Wt = Z / 2;
                                        if (F == null) {
                                            if (
                                                (a != null ? a[0] : void 0) > f * S ||
                                                (p != null ? p[0] : void 0) < f / S
                                            ) {
                                                if (St[0][1] < Wt) {
                                                    ru("refresh limit " + _ + t.Iz + Wt.toPrecision(4));
                                                    St[0][1] = Wt;
                                                }
                                                if (!(Tt > Wt)) {
                                                    break;
                                                }
                                                F = Tt;
                                            }
                                        } else if (m) {
                                            break;
                                        }
                                    }
                                    Tt = Wt;
                                    Nt = Wt;
                                    n[zo] = [];
                                    n[qo] = [];
                                    n[Yo] = [];
                                    n[Do] = [];
                                    n[Ko] = [];
                                    n[jo] = [];
                                    Gu = Wt / (xi - 8);
                                    Kt = Wt;
                                    var M = 0;
                                    var r = 0;
                                    var o = 0;
                                    var u = 0;
                                    x = 0;
                                    var C = [];
                                    var B = 0;
                                    for (R = h.length; R > B; B++) {
                                        W = h[B];
                                        f = W[0];
                                        l = W[1];
                                        s = W[2];
                                        b = W[3];
                                        C.push(
                                            (function() {
                                                var t;
                                                var e;
                                                var i;
                                                var a;
                                                var c;
                                                var f;
                                                var h;
                                                var d;
                                                for (var p = []; l > 1e-8; ) {
                                                    y = Gu > M + l ? l : Gu - M;
                                                    if ((t = n[Yo])[x] == null) {
                                                        t[x] = (f = n[Yo][x - 1]) != null ? f : 0;
                                                    }
                                                    if ((e = n[Do])[x] == null) {
                                                        e[x] = (h = n[Do][x - 1]) != null ? h : 0;
                                                    }
                                                    if ((i = n[qo])[x] == null) {
                                                        i[x] = (d = n[qo][x - 1]) != null ? d : 0;
                                                    }
                                                    if ((a = n[Ko])[x] == null) {
                                                        a[x] = s;
                                                    }
                                                    if ((c = n[jo])[x] == null) {
                                                        c[x] = b;
                                                    }
                                                    r = n[Yo][x] += s * y;
                                                    o = n[Do][x] += b * y;
                                                    u = n[qo][x] += y;
                                                    n[zo][x] = (r + o) / u / 2;
                                                    M += y;
                                                    l -= y;
                                                    if (1e-8 > Gu - M) {
                                                        M = 0;
                                                        p.push(x++);
                                                    } else {
                                                        p.push(void 0);
                                                    }
                                                }
                                                return p;
                                            })()
                                        );
                                    }

                                    //console.log(C)

                                    return C;
                                });
                            } else {
                                Wt = Kt;
                            }

                            at = ue;
                            ct = ce;

                            // chart min range depends on last (or first?) candle time?!
                            if(ve - ir < 86400) {
                                l = 1;
                            } else if(ve - ir < 604800) {
                                l = 1 - (ve - ir) / 86400 / 7;
                            } else {
                                l = 0;
                            }

                            if (Gi != null) {
                                if (Gi[0] > ue) {
                                    at = Gi[0];
                                }
                            }

                            if (qi != null) {
                                if (qi[0] < ce) {
                                    ct = qi[0];
                                }
                            }

                            // Ne & Qe are old values for at and ct accordingly
                            if (Ne / at > 1.02) {
                                Ne = at;
                            }

                            if (ct / Qe > 1.02) {
                                Qe = ct; // it was bugged initially
                            }

                            if (So) {
                                at = Math.max(at, Ne);
                                ct = Math.min(ct, Qe);
                            }

                            // Xr is array of indicators prices? Maybe?
                            // max element of Xr
                            kr = Math.max.apply(Math, Xr);
                            // min element of Xr
                            Mr = Math.min.apply(Math, Xr);

                            R = Mi["chart_range"] === "pmax" ? 100 : 1.05;
                            se = 1 + (kr / Mr - 1) / 3;

                            if (R > se) {
                                se = R;
                            }

                            B = kr * se;
                            O = Mr / se;

                            ue = ue * (1 - l) + at * l;
                            ce = ce * (1 - l) + ct * l;

                            // max price on graph
                            ue = Math.min(B, ue);
                            // min price on graph
                            ce = Math.max(O, ce);

                            if(!autoScale) {
                                ue = 5900;
                                ce = 5000;
                            }

                            // what's the point using old values?
                            // maybe to have smooth scrolling
                            if(Mi['enable_heatmap'] !== "yes" || true) {
                                Ne = at;
                                Qe = ct;
                            }

                            if ((iu = (io = Mi["chart_range"]) != null ? io.match(/\d+/) : void 0)) {
                                Te = (iu[0] >> 0) / 100 + 1;
                                ue = kr * Te;
                                ce = Mr / Te;
                            }

                            // main chart top padding
                            D.h = -D.y + Rr;

                            if(!window.$is_mobile) {
                                D.h += 12 + 12;
                            }

                            ot.y = ce;
                            ot.h = ue - ce;

                            oe = lo(D, ot, Mi["scale"] === "logarithmic");

                            if (wo > 1e7) {
                                oe[wt] = 1e8;
                            }

                            Xe = oe;

                            if (Gt) {
                                hr = Gt;
                                ol = Mi[t.VT];
                                Qt = [];

                                if (ol === "macd") {
                                    tr = oo(hr, 0);
                                    we = zt[0];
                                    it = Jr = 0;
                                    // fixme: slow due constant styles change
                                    for (Br = zt.length; Br > Jr; it = ++Jr) {
                                        lt = zt[it];

                                        if (lt > 0) {
                                            ge.fillStyle = Jo[t.BC];
                                            ge.strokeStyle = Jo[t.PO];
                                        } else {
                                            ge.fillStyle = Jo[t.FR];
                                            ge.strokeStyle = Jo[t.PQ];
                                        }

                                        if (dl(lt, we)) {
                                            ge.fillStyle = Jo[t.SW];
                                        }

                                        Ke(ge, hr, tr, it, lt, yi);
                                        we = lt;
                                    }
                                } else if (ol === t.CQ || ol === t.qx) {
                                    Qt = [20, 80];
                                } else if (ol !== "dmi") {
                                    if (ol === "rsi") {
                                        Qt = [30, 70];
                                    } else if (ol === t.MW) {
                                        Qt = [0];
                                    } else if (ol === t.zs && e) {
                                        tr = oo(hr, 0.5);
                                        Ee(ge, tr + 0.5, 0, Si);
                                    }
                                }

                                Pr = 0;

                                for (Nr = Qt.length; Nr > Pr; Pr++) {
                                    ol = Qt[Pr];
                                    tr = oo(hr, ol);
                                    D = hr[mt];
                                    if (D.y > tr && tr > D.y + D.h) {
                                        Ee(ge, tr + 0.5, 0, Si);
                                    }
                                }
                            }

                            window.Measurer('main-canvas-p1').stop();

                            window.Measurer('main-canvas-p2').start();
                            //window.Measurer('main-canvas-p2-1').start();

                            if(a) {
                                tr = oo(Fr, 0);
                            }
                            // slow part 1
                            // volume
                            //o(function(n) {
                            //   return Ze !== "Coinbase" && yr[n] > 1e-11 ? Ke(ge, Fr, tr, n, yr[n], yi) : void 0
                            //});

                            //window.Measurer('main-canvas-p2-1').stop();
                            window.Measurer('main-canvas-p2-2').start();

                            // slow part 2
                            Re(ge, oe, function () {
                                return o(
                                    function (n, e, r, o, i, linePath, fillAndStroke) {
                                        if (u === "ohlc" || u === "candle_stick" || u === "candle_stick_hlc") {
                                            ze(linePath, oe, n, o, i, al);
                                        }
                                    },
                                    function (n, e, r, o, i, linePath, fillAndStroke) {
                                        var l, a, res;

                                        if (u === "ohlc" || u === "candle_stick" || u === "candle_stick_hlc") {
                                            switch (u) {
                                                case "ohlc":
                                                    l = ro(oe, n);

                                                    a = oo(oe, e);
                                                    Ee(ge, a + .5, l - Ai / 2, l + al);

                                                    a = oo(oe, r);
                                                    return Ee(ge, a + .5, l + al, l + yi + Ai / 2);
                                                case "candle_stick":
                                                    return De(ge, oe, n, e, r, yi, fillAndStroke);
                                                case "candle_stick_hlc":
                                                    return De(ge, oe, n, e, r, yi, fillAndStroke);
                                            }
                                        }
                                    },
                                    function (n, fillAndStroke) {
                                        // draw volume
                                        if (yr[n] > 1e-11) {
                                            Ke(ge, Fr, tr, n, yr[n], yi, fillAndStroke)
                                        }
                                    }
                                );
                            });

                        /*    window.isDrawing = false

                            return;*/

                            window.Measurer('main-canvas-p2-2').stop();
                            window.Measurer('main-canvas-p2-3').start();

                            if (u === "line" || u === "line_o") {
                                Re(ge, oe, function () {
                                    var n, e, r, o, i, l, a, s, c, f, h, d, p, v, b, g, m, F, x;

                                    if (!Y) {
                                        ge.beginPath();
                                        ge.fillStyle = Jo[t.MP];
                                        h = eo(oe, 0, vt[0]);
                                        n = h[0];
                                        e = h[1];
                                        ge.moveTo(n + al, e);
                                        it = r = 0;
                                        for (o = vt.length; o > r; it = ++r) {
                                            lt = vt[it];
                                            d = eo(oe, it, lt);
                                            n = d[0];
                                            e = d[1];
                                            ge.lineTo(n + al, e);
                                        }
                                        for (
                                            it = a = p = U.length - 1;
                                            0 >= p ? 0 >= a : a >= 0;
                                            it = 0 >= p ? ++a : --a
                                        ) {
                                            lt = U[it];
                                            v = eo(oe, it, lt);
                                            n = v[0];
                                            e = v[1];
                                            ge.lineTo(n + al, e);
                                        }
                                        ge.fill();
                                        ge.beginPath();
                                        ge.fillStyle = Jo[t.ut];
                                        b = eo(oe, 0, At[0]);
                                        n = b[0];
                                        e = b[1];
                                        ge.moveTo(n + al, e);
                                        it = s = 0;
                                        for (i = At.length; i > s; it = ++s) {
                                            lt = At[it];
                                            g = eo(oe, it, lt);
                                            n = g[0];
                                            e = g[1];
                                            ge.lineTo(n + al, e);
                                        }
                                        for (
                                            it = c = m = U.length - 1;
                                            0 >= m ? 0 >= c : c >= 0;
                                            it = 0 >= m ? ++c : --c
                                        ) {
                                            lt = U[it];
                                            F = eo(oe, it, lt);
                                            n = F[0];
                                            e = F[1];
                                            ge.lineTo(n + al, e);
                                        }
                                        ge.fill();
                                    }

                                    ge.lineWidth = yi === 1 ? 1.5 : 2;

                                    if (Y) {
                                        ge.lineWidth = 1;
                                        ge.strokeStyle = Jo[t.VB][0];
                                    } else {
                                        ge.strokeStyle = Jo[t.Nj];
                                    }

                                    Ae(ge, oe, U, al + 0.5);

                                    if (u === "line_o") {
                                        ge.fillStyle = Jo[t.SW];
                                        ge.strokeStyle = Jo[t.Nj];
                                        it = f = 0;
                                        for (l = U.length; l > f; it = ++f) {
                                            lt = U[it];
                                            x = eo(oe, it, lt);
                                            n = x[0];
                                            e = x[1];
                                            ge.beginPath();
                                            ge.arc(n + al + 0.5, e, ge.lineWidth, 0, 2 * Math.PI, true);
                                            ge.closePath();
                                            ge.fill();
                                            ge.stroke();
                                        }
                                    }

                                    ge.lineWidth = 1;
                                    vt = U;

                                    return (At = U);
                                });
                            }

                            ge.lineWidth = 1;

                            Z = [
                                [oe, _e, true, Mi["main_lines"]],
                                [Fr, gr, true]
                            ];

                            if (Gt && (Mi[t.VT] !== t.zs || e)) {
                                Z.unshift([Gt, It, true, Mi[t.VT]]);
                            }

                            Hr = 0;

                            for (Lr = Z.length; Lr > Hr; Hr++) {
                                uo = Z[Hr];

                                hr = uo[0];
                                Vr = uo[1];
                                Ye = uo[2];
                                pe = uo[3];

                                if (Ye) {
                                    if (pe === "sar") {
                                        Ce = _e[0];
                                        ge.strokeStyle = Jo[t.VB][0];
                                        it = Dr = 0;

                                        for (Ur = Ce.length; Ur > Dr; it = ++Dr) {
                                            lt = Ce[it];
                                            co = eo(hr, it, lt);
                                            z = co[0];
                                            j = co[1];
                                            z += al + 0.5;
                                            ge.beginPath();
                                            Zr = yi / 2;

                                            if (Zr > 3.5) {
                                                Zr = 3.5;
                                            }

                                            // todo: try with arcTo?
                                            ge.arc(z + 0.5, j, Zr, 0, 2 * Math.PI, true);
                                            ge.stroke();
                                        }

                                    } else {
                                        if ((fo = (po = ie[pe]) != null ? po[t.qQ] : void 0) != null) {
                                            A = fo;
                                        } else {
                                            A = Jo[t.VB];
                                        }

                                        xt = Kr = 0;

                                        for (Wr = Vr.length; Wr > Kr; xt = ++Kr) {
                                            $r = Vr[xt];
                                            ge.strokeStyle = A[xt];

                                            // slowest call here
                                            Ae(ge, hr, $r, al + 0.5);
                                        }
                                    }
                                }
                            }

                            window.Measurer('main-canvas-p2-3').stop();
                            window.Measurer('main-canvas-p2').stop();

                            window.Measurer('main-canvas-p3').start();
                            window.Measurer('main-canvas-p3-0').start();

                            ge.lineWidth = 1;

                            le = 0;
                            ae = 0;
                            xt = zr = 0;

                            for (Cr = yt.length; Cr > zr; xt = ++zr) {
                                ol = yt[xt];
                                if (ol > le) {
                                    le = ol;
                                    ae = xt;
                                }
                            }

                            fe = 1 / 0;
                            he = 0;
                            xt = jr = 0;

                            for (Qr = $t.length; Qr > jr; xt = ++jr) {
                                ol = $t[xt];
                                if (fe > ol) {
                                    fe = ol;
                                    he = xt;
                                }
                            }

                            // fixme: save up to 2ms: cache pointing arrows
                            Re(ge, oe, function () {
                                Xl(ge, oe, ae, le, al);

                                return Xl(ge, oe, he, fe, al);
                            });

                            window.Measurer('main-canvas-p3-0').stop();

                            window.Measurer('main-canvas-p3-1').start();

                            function drawXAxisLabels() {
                                function n(n, e) {
                                    var r = 60 * Fe["getTimezoneOffset"](n);
                                    return (n.getTime() / 1000 - r) % e < Io;
                                }

                                var e, r, o, i, u, l, a, s, c, f, h, d, p, v, g, m, F, x, y, w, T;

                                Gu = Io;

                                o = null;
                                i = null;
                                e = null;
                                r = null;

                                s = {
                                    60: {
                                        cond: n,
                                        key_cond: function(n) {
                                            return Fe["getMinutes"](n) === 0;
                                        },
                                        text: function(n) {
                                            return xr(n);
                                        },
                                        key_text: function(n) {
                                            return dr(n);
                                        },
                                        over: function(n) {
                                            return or(n);
                                        }
                                    },
                                    3600: {
                                        cond: n,
                                        key_cond: function(n) {
                                            return Fe["getHours"](n) === 0 && Fe["getDate"](n) !== e;
                                        },
                                        text: function(n) {
                                            return dr(n);
                                        },
                                        key_text: function(n) {
                                            e = Fe["getDate"](n);
                                            return or(n);
                                        },
                                        over: function(n) {
                                            return or(n);
                                        }
                                    },
                                    86400: {
                                        cond: n,
                                        key_cond: function(n) {
                                            return false;
                                        },
                                        text: function(n) {
                                            return or(n);
                                        },
                                        key_text: function(n) {
                                            return or(n);
                                        },
                                        over: function(n) {
                                            return Fe["getFullYear"](n);
                                        }
                                    },
                                    604800: {
                                        cond: function(n) {
                                            return Fe["getDate"](n) < 8 && Fe["getMonth"](n) !== o;
                                        },
                                        key_cond: function(n) {
                                            return Fe["getMonth"](n) === 0 && Fe["getFullYear"](n) !== i;
                                        },
                                        text: function(n) {
                                            o = Fe["getMonth"](n);
                                            return vr(n);
                                        },
                                        key_text: function(n) {
                                            i = Fe["getFullYear"](n);
                                            o = Fe["getMonth"](n);
                                            return Fe["getFullYear"](n);
                                        },
                                        over: function(n) {
                                            return Fe["getFullYear"](n);
                                        }
                                    }
                                };

                                if (Gu >= 86400) {
                                    a = 604800;
                                    d = 604800;
                                } else {
                                    d = Gu * (80 / Ti);
                                    if (1800 >= d) {
                                        a = 60;
                                        g = [10, 30];
                                    } else if (28800 >= d) {
                                        a = 3600;
                                        g = [1, 2, 3, 6, 8];
                                    } else if (1296000 >= d) {
                                        a = 86400;
                                        g = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15];
                                    } else {
                                        a = 604800;
                                        g = 1;
                                    }
                                    x = 0;
                                    for (F = g.length; F > x; x++) {
                                        xt = g[x];
                                        if (a * xt > d) {
                                            d = a * xt;
                                            break;
                                        }
                                    }
                                }

                                D = b[mt];

                                if ((l = s[a])) {
                                    ge.strokeStyle = Jo[t.Np];
                                    ge.textAlign = t.VN;
                                    ge.textBaseline = "middle";

                                    var textFontObjects = [];
                                    var numbersFontObjects = [];

                                    nt = Yt($, V);
                                    f = null;
                                    h = 0;
                                    v = y = w = Ci - 1;

                                    for (T = Ci + $i; T >= w ? T >= y : y >= T; v = T >= w ? ++y : --y) {
                                        u = nt[v];

                                        if (!u) {
                                            if (!f) {
                                                continue;
                                            }
                                            u = new Date(f);
                                            u.setSeconds(Io);
                                        }

                                        xt = v - Ci;

                                        if (l.cond(u, d)) {
                                            if (l.key_cond(u)) {
                                                m = l.key_text(u);
                                                c = true;
                                            } else {
                                                m = l.text(u);
                                                c = false;
                                            }

                                            z = Hi + xt * Ti + al + 0.5;

                                            if (z > h + 30 || c) {
                                                if(c) {
                                                    textFontObjects.push([m, z, D.y + D.h]);
                                                } else {
                                                    numbersFontObjects.push([m, z, D.y + D.h]);
                                                }

                                                h = z;
                                            }
                                        }

                                        f = u;
                                    }

                                    var tfo = 0,
                                        toTfo = textFontObjects.length,
                                        textContent, textX, textWidth;

                                    if(toTfo) {
                                        ge.fillStyle = Jo[t.Sy];
                                        ge.font = t.VM + qe;

                                        for(; tfo < toTfo; tfo++) {
                                            textContent = textFontObjects[tfo][0];
                                            textX = textFontObjects[tfo][1];
                                            textWidth = textFontObjects[tfo][2];

                                            We(ge, textX, textWidth, textWidth + 4);
                                            ge.fillText(textContent, textX, textWidth + 8.5);
                                        }
                                    }

                                    var nfo = 0, toNfo = numbersFontObjects.length;

                                    if(toNfo) {
                                        ge.fillStyle = Jo[t.Sy];
                                        ge.font = "" + re + t.Iz + qe;

                                        for(; nfo < toNfo; nfo++) {
                                            textContent = numbersFontObjects[nfo][0];
                                            textX = numbersFontObjects[nfo][1];
                                            textWidth = numbersFontObjects[nfo][2];

                                            We(ge, textX, textWidth, textWidth + 4);
                                            ge.fillText(textContent, textX, textWidth + 8.5);
                                        }
                                    }

                                    if (nt[Ci]) {
                                        p = l.over(nt[Ci]);

                                        return $u.text(p);
                                    }
                                }

                            };

                            //if(!window.$is_flutter_view) {
                                drawXAxisLabels();
                            //}

                            window.Measurer('main-canvas-p3-1').stop();
                            window.Measurer('main-canvas-p3-2').start();

                            z = Si - xi;

                            ge.strokeStyle = Jo[t.Np];
                            ge.textAlign = t.uO;
                            ge.textBaseline = "middle";
                            ge.font = "" + re + t.Iz + qe;
                        //    ge.fillStyle = Jo[t.lU];
                        //    ge.fillRect(z, 0, z + xi, ki);
                            ge.fillStyle = Jo[t.Sy];

                            pr = a ? [Gt, oe, Fr] : [Gt, oe];

                            //console.log(JSON.stringify(pr[2][17]), JSON.stringify(pr[2][18]))

                            vo = function () {
                                var n, t, e;
                                for (e = [], t = 0, n = pr.length; n > t; t++) {
                                    hr = pr[t];

                                    if(hr) {
                                        hr = Gr(hr);

                                        hr[mt].w = xi;
                                        hr[mt].x = z;

                                        e.push(hr)
                                    } else {
                                        e.push(void 0);
                                    }
                                }
                                return e
                            }();

                            jt = vo[0];
                            te = vo[1];
                            br = vo[2];

                            // bottom date series (X axis)
                            // up to 3ms
                            for (qr = 0, Ar = bt.length; qr < Ar; qr++) {
                                j = bt[qr];
                                Ee(ge, j + .5, z, z + xi);
                            }

                            window.Measurer('main-canvas-p3-2').stop();
                            window.Measurer('main-canvas-p3-3').start();

                            Pe(ge, te, { hr: "hr" });

                            sr = 0;
                            cr = 0;

                            if(So) {
                                pl("draw_depth_hints", function () {
                                    var e, r, o, i;

                                    ol = Gr(te);

                                    ol[mt].x += 8;
                                    ol[mt].w -= 8;
                                    ol[Ft].x = 0;
                                    ol[Ft].w = Wt;

                                    o = ol[mt].x;
                                    e = o + ol[mt].w;
                                    r = ol[mt].y;
                                    i = r + ol[mt].h;
                                    Gu = Wt / ol[mt].w;

                                    return Re(ge, ol, function () {
                                        var u, l, a, s, c, f, h, d, p, v, b, g, m, F, x, y, $, w;

                                        ge.lineWidth = 1;

                                        c = [
                                            [du, t.MK],
                                            [Lu, t.MJ]
                                        ];

                                        window.Measurer('draw-depth').start();

                                        var useSimplifiedHints = performanceSettings['simplified_orderbook_hints'];

                                        for (b = 0, v = c.length; v > b; b++) {
                                            g = c[b];

                                            s = g[0];
                                            u = g[1];

                                            pl("draw " + u, function () {
                                                var n, l, a, c, f, h, d, p, v, b, g;

                                                if(useSimplifiedHints) {
                                                    if (u === "Green") {
                                                        //    ge.strokeStyle = "#00C900";
                                                        //    ge.strokeStyle = "#14B514";
                                                        ge.strokeStyle = "#129912";
                                                    } else if (u === "Red") {
                                                        //    ge.strokeStyle = "#CA0000";
                                                        //    ge.strokeStyle = "#B61414";
                                                        ge.strokeStyle = "#991111";
                                                    }
                                                } else {
                                                    ge.fillStyle = Jo["" + u + t.bT];
                                                    ge.strokeStyle = Jo["" + u + t.bT];
                                                }

                                                h = Wt;
                                                a = ol[mt].x;
                                                b = [];

                                                l = g = 0;
                                                d = s.length;

                                                if(useSimplifiedHints) {
                                                    ge.beginPath();
                                                }

                                                while(d > g && !(h < 1e-8)) {
                                                    c = s[l];

                                                    if(c[1] < h) {
                                                        n = c[1];
                                                    } else {
                                                        n = h;
                                                    }

                                                    f = c[0];

                                                    p = eo(ol, Wt - h + n, f);

                                                    //console.log(ol)
                                                    //debugger

                                                    z = p[0];
                                                    j = p[1];

                                                    if(a >= o && e >= a && i <= (v = j - 1) && r >= v) {
                                                        if(useSimplifiedHints) {
                                                            ge.moveTo(a, j - 1);
                                                            ge.lineTo(z + 1, j - 1);

                                                            ge.moveTo(a, j);
                                                            ge.lineTo(z + 1, j);
                                                        } else {
                                                            ge.fillRect(a, j - 1, z - a + 1, 2);
                                                        }

                                                    }

                                                    a = z;
                                                    h -= n;
                                                    l = ++g;

                                                    b.push(void 0);
                                                }

                                                if(useSimplifiedHints) {
                                                    ge.stroke()
                                                }

                                                return b
                                            });
                                        }

                                        window.Measurer('draw-depth').stop();

                                        h = Wt;
                                        m = [zo];
                                        y = [];

                                        ge.lineWidth = 1.5;
                                        ge.strokeStyle = Jo[t.MI];
                                        ge.beginPath();

                                        // bid/ask balance line
                                        for ($ = 0, d = m.length; $ < d; $++) {
                                            for (l = m[$], F = n[l], a = w = 0, p = F.length; p > w; a = ++w) {
                                                f = F[a],
                                                x = eo(ol, a * Gu, f),
                                                z = x[0],
                                                j = x[1],
                                                0 === a && ge.moveTo(ol[mt].x, j),
                                                z >= o && e >= z && j >= i && r >= j && ge.lineTo(z, j);
                                            }

                                            y.push()
                                        }

                                        ge.stroke()

                                        return y
                                    })
                                })
                            }

                            if (Gt) switch (be = function (n) {
                                var t, e, r, o, i, u;
                                for (ot = Gt[Ft], r = ot.y, e = ot.y + ot.h, t = [], u = 0, i = n.length; i > u; u++) o = n[u], o >= r && e >= o && t.push(o);
                                return t
                            }, Mi[t.VT]) {
                                case "macd":
                                    He(ge, jt, {
                                        keep: "keep"
                                    });
                                    break;
                                case "bid_ratio":
                                case "cmf":
                                case "obv":
                                    He(ge, jt, {
                                        keep: "keep"
                                    });
                                    break;
                                case "dmi":
                                    Be(ge, jt, be([20, 50]), {
                                        keep: "keep"
                                    });
                                    break;
                                case "rsi":
                                    Be(ge, jt, be([0, 30, 50, 70, 100]), {
                                        keep: "keep"
                                    });
                                    break;
                                default:
                                    Be(ge, jt, be([0, 20, 50, 80, 100]), {
                                        keep: "keep"
                                    })
                            }

                            window.Measurer('main-canvas-p3-3').stop();
                            window.Measurer('main-canvas-p3-4').start();

                            de = Gr(te);

                            if (wo > 1e7) {
                                de[Ft].y *= 1e8;
                                de[Ft].h *= 1e8;
                            }

                            window.Measurer('main-canvas-price-levels').start();

                            // main chart y axis
                            var priceLevels = Pe(ge, de, {text: 'pmax', keep: 'keep'});

                            if(window.Heatmap && window.Heatmap.setPriceLevels) {
                                window.Heatmap.setPriceLevels(priceLevels);
                            }

                            // volume y axis
                            if (a) {
                                Pe(ge, br);
                            }

                            window.Measurer('main-canvas-price-levels').stop();

                            if (So || window.$is_flutter_view) {
                                D = te[mt];

                                (function () {
                                    if(!xe || !xe[st]) {
                                        return;
                                    }

                                    var r = xe[st];
                                    j = oo(oe, r);
                                    z = D.x;
                                    ge.strokeStyle = Jo[t.Lm];
                                    ge.fillStyle = Jo[t.Lm];

                                    if(xe[st] && window.$is_flutter_view) {
                                        Re(ge, br, function () {
                                            const priceText = xe[st].toPrecision(5);
                                            const leftPadding = xi / 2;

                                            iu = ge.measureText(priceText);

                                            ge.textBaseline = "middle";
                                            ge.textAlign = "center";
                                            //ge.font = ge.font.replace(/\d+px/, "10px");
                                            //ge.font = "" + re + t.Iz + qe;
                                            ge.font = "11px " + qe;

                                            // semi-transparent background
                                            ge.fillStyle = Jo[t.Za];
                                            // ge.fillRect(z + leftPadding, j - 6, iu.width, 10);
                                            ge.fillRect(z + leftPadding - iu.width / 2, j - 6, iu.width, 10);

                                            // price label
                                            ge.fillStyle = Jo[t.Lm];
                                            ge.fillText(priceText, z + leftPadding, j);
                                        });
                                    }

                                    var u = te[mt];
                                    var i = u.y;
                                    pt = u.h;
                                    var e = i + pt;
                                    if (j >= e && i >= j) {
                                        Jl(ge, z, j);
                                    }
                                    ge.fillStyle = Jo[t.MI];
                                    j = oo(oe, sr / cr);
                                })()

                                // draw an arrow pointing ar current volume
                                if (a && So) {
                                    D = te[mt];

                                    Re(ge, br, function () {
                                        ge.font = "" + re + t.Iz + qe;
                                        var n = wr(Yt($, Bo));
                                        var e = eo(Fr, Ui - Ci + 1, n);
                                        z = e[0];
                                        j = e[1];
                                        iu = ge.measureText(n.toFixed(5));
                                        ge.fillStyle = Jo[t.Za];
                                        ge.fillRect(D.x + 12, j - 6, iu.width, 12);
                                        ge.fillStyle = Jo[t.MF];
                                        ge.fillText(t.VJ, D.x, j);
                                        return ge.fillText(kl(n, false), D.x + 12, j);
                                    });
                                }
                            }

                            if (!a) {
                                D = Fr[mt];
                                ge.textAlign = t.VN;
                                ge.fillText('Volume is disabled.', Si / 2, D.y + D.h / 2);
                            }

                            if (Gt && Mi[t.VT] === t.zs && !e) {
                                D = Gt[mt];
                                ge.textAlign = t.VN;
                                ge.fillText("" + Ze + t.CU, Si / 2, D.y + D.h / 2);
                            }

                            D = b[mt];
                            Et = Ui - Ci;
                            $r = [[Et, nt[Ui]]];
                            ge.font = t.Ja;
                            no = 0;

                            for (Yr = $r.length; Yr > no; no++) {
                                bo = $r[no];
                                xt = bo[0];
                                q = bo[1];
                                if (q) {
                                    if (xt !== 0 || !(120 / Ti > Et)) {
                                        z = D.x + D.w;
                                        j = D.y + D.h + 8.5;
                                        ge.strokeStyle = Jo[t.Np];
                                        ge.fillStyle = Jo[t.Np];
                                        ge.strokeStyle = Jo[t.Sy];
                                        ge.fillStyle = Jo[t.Sy];
                                        ge.beginPath();
                                        ge.arc(z, j, 2, 0, 2 * Math.PI, true);
                                        ge.closePath();
                                        ge.fillStyle = Jo[t.Sy];
                                        rr = Ci + xt === ut ? t.iG : ar(parseInt((wr(nt) - q) / 1000));
                                        ge.textAlign = t.uO;
                                        iu = ge.measureText(rr);
                                        ge.fillText(rr, z + (xi - iu.width) / 2, j);
                                    }
                                }
                            }

                            window.Measurer('main-canvas-p3-4').stop();
                            window.Measurer('main-canvas-p3-5').start();

                            // console.log(Fr, te, b, Fr == te, te == b, Fr == b);

                            // st: alerts are rendered here

                            D = te[mt];
                            ge.lineWidth = 1;

                            f = [[s, Jo[t.MD], "high"], [c, Jo[t.VX], "low"]];

                            To = 0;

                            for (Or = f.length; Or > To; To++) {
                                go = f[To];

                                Se = go[0];
                                Q = go[1];
                                fr = go[2];

                                if (Se) {
                                    ge.fillStyle = ge.strokeStyle = Q;

                                    j = oo(Xe, Se / wo) + 0.5;

                                    if (D.y + D.h < j && j < D.y) {
                                        ge.beginPath();

                                        ge.font = "12px FontAwesome";
                                        ge.textAlign = "right";
                                        ge.textBaseline = "middle";

                                        rr = '\uf0a2';

                                        if ((fr === "high" && Yi) || (fr === "low" && ou)) {
                                            rr = '\uf0f3';
                                        }

                                        ge.fillText(rr, D.x + D.w - 10, j);

                                        Ee(ge, j, D.x + D.w - 6, D.x + D.w);
                                    }
                                }
                            }

                            // end: alerts are rendered here

                            window.Measurer('main-canvas-p3-5').stop();
                            window.Measurer('main-canvas-p3').stop();
                            window.Measurer('main-canvas').stop();

                            redrawHeatmap(Xe, Po, To, Mo, Ti, Hi, Mi, D);

                            kt = 1;
                            mo = S[i];

                            for (er in mo)
                                if (so.call(mo, er) && (h = mo[er], _ = null != h ? null != (Fo = h[Io]) ? Fo[w] : void 0 : void 0)) {
                                    switch (ft = Math.round(nt[0].getTime() / 1000), lr = Yt(_, J), k = $, C = Yt(_, T), W = Yt(k, T), me = (ft - lr[0]) / Io, Mi["mode"]) {
                                        case "absolute":
                                            Ut = null, $r = function () {
                                                var n, t;
                                                for (n = [], ke = t = Ci; Ui >= Ci ? Ui >= t : t >= Ui; ke = Ui >= Ci ? ++t : --t) E = C[ke + me], null == E ? n.push(null) : n.push(E);
                                                return n
                                            }();
                                            break;
                                        case "relative":
                                            Ut = null, $r = function () {
                                                var n, t;
                                                for (n = [], ke = t = Ci; Ui >= Ci ? Ui >= t : t >= Ui; ke = Ui >= Ci ? ++t : --t) E = C[ke + me], null == E ? n.push(null) : (null == Ut && (Ut = U[ke - Ci] / E), n.push(E * Ut));
                                                return n
                                            }();
                                            break;
                                        case "benchmark":
                                            Ut = null, Ge = 0, nr = 0, $r = function () {
                                                var n, t;
                                                for (n = [], ke = t = Ci; Ui >= Ci ? Ui >= t : t >= Ui; ke = Ui >= Ci ? ++t : --t) N = W[ke], L = C[ke + me], N && L ? (Ge += N, nr += L, n.push(L)) : n.push(null);
                                                return n
                                            }(), Ut = Ge / nr, $r = function () {
                                                var n, t, e;
                                                for (t = [], e = 0, n = $r.length; n > e; e++) E = $r[e], null != E ? t.push(E * Ut) : t.push(E);
                                                return t
                                            }()
                                    }

                                    S[t.Wi] = Ut,
                                    ge.lineWidth = 1 === yi ? 1.5 : 2,
                                    ge.lineWidth = 1,
                                    ge.strokeStyle = Jo[t.VB][1],
                                    Ae(ge, oe, $r, al + .5)
                                }
                            {
                                window.isDrawing = false
                            }

                            //    Zl();

                            window.Measurer('draw_main').stop();

                            return;
                        }
                    };

                    Mo = true;
                    ll = me(150, function() {
                        return Lr(Fl)
                    });
                    _u = $("#loading");
                    Ve = 0;
                    Pu = $("#notify");
                    hu = false;
                    Ho = {};

                    var isCombinedHeatmap = ('$heatmap_settings' in window && window.$heatmap_settings.combined);

                    if(isCombinedHeatmap) {
                        $(".symbol_combined_heatmap").addClass("active");
                    } else {
                        $(".symbol_" + window.$symbol).addClass("active");
                    }

                    fu = 0,
                    function() {
                        var n;
                        return n = 0,
                            function() {
                                function e() {
                                    rr(t.LY + $ssl + t.YT + $host + '/ticker', {
                                        nonce: 0
                                    }, function() {
                                        r = arguments[0];
                                        l = arguments[1];

                                    //    console.log(l, r);

                                        if (l != null) {
                                            u = l.now;
                                            n = Date.now();

                                            for (i in l) {
                                                if (l.hasOwnProperty(i)) {
                                                    o = l[i];

                                                    Nl(i, o);

                                                    if (u - o.date > 60) {
                                                        $('.symbol_' + i).addClass("grey");
                                                    } else {
                                                        $('.symbol_' + i).removeClass("grey");
                                                    }
                                                }
                                            }
                                        }

                                        Kr(20 * 1000, function(n) {
                                            e(n);
                                        });
                                    });
                                }

                                var r, o, i, u, l;

                                setTimeout(function() {
                                    e();
                                }, 500);
                            }(),
                            function() {
                                var e;
                                Kr(3000, function() {
                                    function r() {
                                        e = Date.now() - n;
                                        if (e > 30000) {
                                            $("#pc_to_bw").attr(t.QQ, t.Mk);
                                        } else if (e > 15000) {
                                            $("#pc_to_bw").attr(t.QQ, t.BU);
                                        } else {
                                            $("#pc_to_bw").attr(t.QQ, t.Mi);
                                        }
                                        Kr(1000, function(n) {
                                            r(n);
                                        });
                                    }

                                    r();
                                });
                            }(), null
                    }(),
                    Eu.resize(function() {
                        if(ku) {
                            kuHeight = ku.height();
                        }

                        return lockCross && gl(), ll()
                    }),

                    mu.hover(function() {
                        return mu.height(320)
                    }, function() {
                        return mu.height(32)
                    }),

                    /* desktop view */
                    $(".switch_desktop_version").click(function() {
                        var val = $(this).data('value');

                        console.log("switch_desktop_version", val);

                        // set localStorage
                        Ku("force_desktop", val);

                        // set cookie
                        hl("force_desktop", val, {
                            wildcardDomain: true
                        });

                        setTimeout(function() {
                            window.location.reload();
                        }, 250);

                        return true;
                    }),

                    /*  */
                    $("#switch-theme a").click(function() {
                        var val = $(this).data('theme');

                        // set localStorage
                        Ku("theme", val);

                        // set cookie
                        // hl("theme", val);

                        setTimeout(function () {
                            window.location.reload();
                        }, 250);

                        return true;
                    }),

                    $("#close_ad").click(function() {
                        return Mu.hide(), nu = 0, Fl(), !0
                    }),

                    $('#show_qr').click(function() {
                        var n = $('#qr');
                        if (n.is(':visible')) {
                            $(this).text(t.cr);
                            n.hide();
                        } else {
                            $(this).text(t.Ma);
                            n.show();
                        }
                        return true;
                    }),

                    $("#close_qr").click(function() {
                        $('#show_qr').text(t.cr);
                        $('#qr').hide();
                        return true;
                    }),

                    Fr = $("#indicators_settings"),
                    $("#btn_indicators_settings").click(function() {
                        if (Fr.is(':visible')) {
                            Fr.hide();
                        } else {
                            Fr.show();

                            $("#btn_indicators_settings").parents('.dropdown').removeClass('dropdown-hover');
                        }

                        return true;
                    }),
                    $("#close_indicators_settings").click(function() {
                        if (Fr.is(':visible')) {
                            Fr.hide();
                        } else {
                            Fr.show();
                        }

                        return true;
                    });

                    const FrP = $("#performance_settings");
                    $("#btn_performance_settings").click(function() {
                        if (FrP.is(':visible')) {
                            FrP.hide();
                        } else {
                            FrP.show();

                            $("#btn_performance_settings").parents('.dropdown').removeClass('dropdown-hover');
                        }

                        return true;
                    });
                    $("#close_performance_settings").click(function() {
                        if (FrP.is(':visible')) {
                            FrP.hide();
                        } else {
                            FrP.show();
                        }

                        return true;
                    });

                    Fr2 = $("#heatmap_settings"),
                    $("#btn_heatmap_settings").click(function() {
                        if (Fr2.is(':visible')) {
                            Fr2.hide();
                        } else {
                            Fr2.show();

                            $("#btn_heatmap_settings").parents('.dropdown').removeClass('dropdown-hover');
                        }

                        return true;
                    }),
                    $("#close_heatmap_settings").click(function() {
                        if (Fr2.is(':visible')) {
                            Fr2.hide();
                        } else {
                            Fr2.show();
                        }

                        return true;
                    });

                    Fr3 = $("#aggregated_heatmap_settings"),
                    $("#btn_aggregated_heatmap_settings").click(function() {
                        if (Fr3.is(':visible')) {
                            Fr3.hide();
                        } else {
                            Fr3.show();

                            $("#btn_aggregated_heatmap_settings").parents('.dropdown').removeClass('dropdown-hover');
                        }

                        return true;
                    }),
                    $("#close_aggregated_heatmap_settings").click(function() {
                        if (Fr3.is(':visible')) {
                            Fr3.hide();
                        } else {
                            Fr3.show();
                        }

                        return true;
                    });

                    function initPopupDragging(popupSelector) {
                        var lastPosX = 0;
                        var lastPosY = 0;
                        var isDragging = false;

                        function handleDrag(ev) {
                            // for convience, let's get a reference to our object
                            var elem = $(ev.target).parents('.popup')[0];

                            // DRAG STARTED here,
                            // let's snag the current position
                            // and keep track of the fact that we're dragging
                            if (!isDragging) {
                                isDragging = true;

                                lastPosX = elem.offsetLeft;
                                lastPosY = elem.offsetTop;
                            }

                            // we simply need to determine where the x,y of this
                            // object is relative to where it's "last" known position is
                            // NOTE:
                            //    deltaX and deltaY are cumulative
                            // Thus we need to always calculate 'real x and y' relative
                            // to the "lastPosX/Y"
                            var posX = ev.deltaX + lastPosX;
                            var posY = ev.deltaY + lastPosY;

                            // move our element to that position
                            elem.style.left = posX + "px";
                            elem.style.top = posY + "px";

                            // DRAG ENDED
                            // this is where we simply forget we are dragging
                            if (ev.isFinal) {
                                isDragging = false;
                            }
                        }
                        var eventWrapper = $(popupSelector);

                        if(!eventWrapper.length) {
                            return;
                        }

                        var mc = new Hammer(eventWrapper[0]);

                        mc.add(new Hammer.Pan({direction: Hammer.DIRECTION_ALL, threshold: 0}));
                        mc.on("pan", handleDrag);
                    }

                    initPopupDragging('#heatmap_settings .drag');
                    initPopupDragging('#indicators_settings .drag');

/*
                    var estimateHover = function() {
                        return d.amount > 1000 ? bu.val(parseInt(d.amount)) :
                            bu.val(parseFloat(d.amount.toPrecision(5))),
                            Bo === H ? bu.click_base_currency() : bu.click_quote_currency(),
                            bu.keyup()
                    };

                    d.hover(i);
                    d.click(function() {
                        return $(".link_estimate_trading:first").click(), i()
                    });
                   */

                    sa = function(n, e) {
                        function r() {
                            var t, e, r, o, u, l;
                            for (r = ie[n].params, l = [], t = o = 0, u = i.length; u > o; t = ++o) e = i[t], l.push($(e).val(r[t]));
                            return l
                        }
                        var o, i, u;
                        return e.default_params = e.params, o = e.cookie, i = $(t.Ve + n + t.GL), i.change(function() {
                            var e, r, u, l, a, s;
                            for (u = [], a = 0, s = i.length; s > a; a++) {
                                if (e = i[a], ol = $(e).val(), !ol.match(/^\d+$/)) {
                                    if (n === t.QM && ol === "") continue;
                                    return alert(ol + t.Fx), void 0
                                }
                                u.push(parseInt(ol))
                            }
                            Ku(t.mr + o, u), ie[n].params = u, r = to;
                            for (Gu in Ur) so.call(Ur, Gu) && (l = Ur[Gu], to = Gu, Ur[Gu] = Nr(l), Yl(!1));
                            return to = r, Er = Ur[r]
                        }), $(t.gs + n + t.xH).click(function() {
                            return ie[n].params = ie[n].default_params, r(), $(i[0]).change()
                        }), u = Ku(t.mr + o), ie[n].params = u || ie[n].default_params || [], r()
                    };

                    for (lu in ie) so.call(ie, lu) && (Ki = ie[lu], sa(lu, Ki));
                    te = !0,
                    A = {
                        USD: t.cx,
                        EUR: t.Ho,
                        GBP: t.LN,
                        CNY: t.LM,
                        JPY: t.LM,
                        AUD: t.LL,
                        CAD: t.Qq,
                        BTC: "₿",
                        LTC: "Ł"
                    },
                    _o = {},
                    ve(Mi, "time_zone", function() {
                        var n, e, r, o;

                        var u = Ei.childNodes;

                        for (var i = u.length; i > o; o++) {
                            n = u[o];
                            r = n.tx;
                            e = yr(new Date(1000 * r[ct]));

                            $(".t", n).text(e);
                        }

                        cl();

                        return null;
                    }),
                    ve(Mi, "orderbook_precision", function() {
                        $t = false;

                        ql();

                        $t = true;

                        return true;
                    }),
                    Ce = null,
                    io = {},
                    zt = {},
                    fo = {},
                    Zr = {},
                        // redraw charts every 80ms
                        function() {
                            var lastRequestTime = +new Date();

                            function n() {
                                if('hidden' in document && !document.hidden) {
                                    redrawCross();
                                    redrawMain();
                                } else {
                                    if(cu) {
                                        Il();
                                        ul();
                                        cu = false;
                                    }
                                }

                                Kr(80, function(t) {
                                    n(t)
                                })
                            }

                            n()
                        }(),
                        // run writeTrades
                        function() {
                            function n() {
                                if(su) {
                                //    requestAnimationFrame(function () {
                                        Gl();
                                        su = !1
                                //    });
                                }

                                if(au) {
                                    if(!('hidden' in document) || !document.hidden) {
                                        requestAnimationFrame(function () {
                                            ql();
                                            au = !1
                                        });
                                    }
                                }

                                Kr(200, function(t) { // default value was 120
                                    n(t)
                                })
                            }

                            n()
                        }(),
                // FL IS THE FIRST COMMAND TO CALL FREEZE
                        Fl(),
                        function() {
                            function n(n) {
                                if (n >= 0) {
                                    return t.iM + n.toFixed(2) + t.Ta;
                                } else {
                                    return n.toFixed(2) + t.Ta;
                                }
                            }

                            function e(n, t) {
                                t = t.toString();

                                if (n.text() === t) {
                                    return;
                                } else {
                                    return n.text(t);
                                }
                            }

                            function r() {
                                var r, i, a, c, f, h, p, b, g, m, F, x, y, w, T, k, M, _, I;

                                var u = [["to", d], ["from", v]];
                                var V = 0;

                                for (var S = u.length; S > V; V++) {
                                    _ = u[V];
                                    T = _[0];
                                    i = _[1];

                                    g = tt[i].flatten();

                                    if (i === v) {
                                        g.reverse();
                                    }

                                    x = parseFloat(bu.val());

                                    F = x;

                                    w = 0;
                                    y = 0;

                                    p = $(t.ih + T + t.fj, o);
                                    a = $(t.ih + T + t.zd, o);
                                    b = $(t.ih + T + t.cy, o);
                                    c = $(t.ih + T + t.TI, o);
                                    f = $(t.ih + T + t.Qy, o);
                                    h = $(t.ih + T + t.WK, o);

                                    h.html(l);

                                    k = 0;

                                    for (M = g.length; M > k; k++) {
                                        I = g[k];
                                        m = I[0];
                                        r = I[1];
                                        if (s === j) {
                                            if (!(F > r)) {
                                                w += m * F;
                                                y += F;
                                                break;
                                            }
                                            w += m * r;
                                            y += r;
                                            F -= r;
                                        } else {
                                            if (!(F > m * r)) {
                                                w += F;
                                                y += F / m;
                                                break;
                                            }
                                            w += m * r;
                                            y += r;
                                            F -= m * r;
                                        }
                                    }
                                    if (
                                        xe &&
                                        m &&
                                        !isNaN(x) &&
                                        ((s === j && Math.abs(y - x) < 0.000001) || Math.abs(w - x) < 0.000001)
                                    ) {
                                        e(p, m);
                                        e(b, n((m / xe[st]) * 100 - 100));
                                        e(a, parseFloat((w / y).toPrecision(6)));
                                        e(c, n((w / y / xe[st]) * 100 - 100));

                                        if (s === j) {
                                            e(f, parseFloat(w.toPrecision(6)));
                                        } else {
                                            e(f, parseFloat(y.toPrecision(6)));
                                        }
                                    } else {
                                        p.text("out of orderbook");
                                        a.text("out of orderbook");
                                        f.text("");
                                        h.text("");
                                        b.text("");
                                        c.text("");
                                    }
                                }
                                return true;
                            }

                            var u, s;
                            var o = $('#dlg_estimate_trading');

                            bu = $('.amount', o);

                            bu.keyup(r);

                            bu.click_base_currency = function() {
                                return $(".unit:first", o).click();
                            };

                            bu.click_quote_currency = function() {
                                return $($('.unit', o)[1]).click();
                            };

                            if (f.indexOf(t.ni) === -1) {
                                if (j === "DOGE") {
                                    bu.val(5e5);
                                } else {
                                    bu.val(100);
                                }
                            } else {
                                bu.val(10);
                            }

                            s = j;

                            var l = Pr;
                            var c = $('.unit', o);

                            var a = c[0];
                            var i = c[1];

                            $(a).text(j);
                            $(i).text(Pr);

                            $('.unit', o).click(function() {
                                if (!$(this).hasClass("selected")) {
                                    return;
                                }

                                $('.unit', o).removeClass("selected");
                                $(this).addClass("selected");

                                s = $(this).text();

                                var n = $(t.Lp, o).text();

                                if (n !== "") {
                                    $('.amount').val(parseFloat(parseFloat(n).toPrecision(5)));
                                }

                                if (s === j) {
                                    l = Pr;

                                    $(".to", o).text("Cost");
                                    $(".from", o).text("Receive");
                                    $(".mode", o).text("Buy");
                                    $(".to_text", o).text("Buy");
                                    $(".from_text", o).text("Sell");
                                } else {
                                    l = j;

                                    $(".to", o).text("Receive");
                                    $(".from", o).text("Sell");
                                    $(".mode", o).text("Sell");
                                    $(".to_text", o).text("Spend");
                                    $(".from_text", o).text("ToReceive");
                                }
                                r();
                            });

                            (u = function() {
                                r();
                                return setTimeout(u, 1000);
                            })();

                            return 0;
                        }();

                        $(".mode").click(function() {
                            return ua($(this).attr("mode"))
                        }),

                        $(t.VZ).click(function() {});

                        ke = window.localStorage;

                        if (
                            (Fa = ke != null ? ke.mode : void 0) === 'cross' ||
                            Fa === 'draw_line' ||
                            Fa === "draw_fhline" ||
                            Fa === "draw_fhlineex" ||
                            Fa === "draw_ffan" ||
                            Fa === "draw_fline" ||
                            Fa === "draw_hline"
                        ) {
                            ua(ke.mode);
                        } else {
                            ua('cross');
                        }

                        $(document).on('keydown', function(n) {
                            var o = n.which;

                            // https://developer.mozilla.org/en-US/docs/Web/API/KeyboardEvent/keyCode

                            var keyCodes = {
                                SHIFT: 16,
                                ESCAPE: 27,
                                FOUR: 52,
                                EQUALS: 61,
                                E: 69,
                                L: 76,
                                T: 84,
                                ADD_NUMPAD: 107,
                                SUBTRACT_NUMPAD: 109,
                                ADD_DOM: 171,
                                SUBTRACT_DOM: 173,
                                SUBTRACT: 187,
                                DASH: 189,
                                DELETE: 46
                            };

                            if (
                                (n.target.tagName.toLowerCase() === 'input' ||
                                    n.target.tagName.toLowerCase() === 'textarea') &&
                                o !== keyCodes.ESCAPE
                            ) {
                                return;
                            }

                            if (o === keyCodes.SHIFT) {
                                ti = true;
                            }

                            if ($(".dialog:visible").length) {
                                if (o === keyCodes.ESCAPE) {
                                    $(".dialog:visible").hide();
                                }

                                return true;
                            }

                            if (o === keyCodes.T) {
                                Mo = !Mo;
                                cu = true;
                            }

                            if (o === keyCodes.EQUALS || o === keyCodes.SUBTRACT || o === keyCodes.ADD_NUMPAD || o === keyCodes.FOUR || o === keyCodes.ADD_DOM) {
                                scaleGraph(null, 1);
                                return true;
                            }

                            if (o === keyCodes.SUBTRACT_NUMPAD || o === keyCodes.DASH || o === keyCodes.SUBTRACT_DOM) {
                                scaleGraph(null, -1);
                                return true;
                            }

                            switch (n.which) {
                                /*case keyCodes.E:
                                    ia('estimate_trading');
                                    break;*/

                                case keyCodes.L:
                                    ua("draw_line", false);
                                    break;

                                default:
                                    return true;
                            }
                            return true;
                        }),
                        $(document).on("keyup", function(n) {
                            if (n.which === 16) {
                                ti = false;
                            }

                            return true;
                        }),
                        la()
                })
            }(), ce = ae = fe = le = he = de = se = null, u = i = l = o = null,
            function() {
                function n() {
                    var n = {};

                    n[v] = {};
                    n[g] = {};

                    return n;
                }

                function e(n, e) {
                    var r;

                    if ((r = n[b]) == null) {
                        return;
                    } else {
                        return r.send(JSON.stringify(["c" + F++, "connect", "trades:" + e]));
                    }
                }

                function r(n, e) {
                    var r;

                    delete n[v][e];
                    delete n[g][e];

                    if ((r = n[b]) == null) {
                        return;
                    } else {
                        return r.send(JSON.stringify(["c" + F++, "disconnect", "trades:" + e]));
                    }
                }

                function a(n) {
                    var t, e;
                    var o = n[g];

                    for (t in o) {
                        if (o.hasOwnProperty(t)) {
                            e = o[t];

                            r(n, t);
                        }
                    }

                    return this;
                }

                function s(n, t) {
                    var e, o;
                    var i = n[g];

                    for (e in i) {
                        if (i.hasOwnProperty(e)) {
                            o = i[e];

                            if (e !== t) {
                                r(n, e);
                            }
                        }
                    }

                    return this;
                }

                function c(n) {
                    var t, r;
                    var o = n[g];

                    for (t in o) {
                        if (o.hasOwnProperty(t)) {
                            r = o[t];

                            e(n, t);
                        }
                    }

                    return n;
                }

                function f(n, r, o, i) {
                    function u() {
                        e(n, r);

                        i(l);
                    }

                    var l, a, c, f;

                    o = parseInt(o);

                    if ((f = n[v][r]) != null ? f[o] : void 0) {
                        u();
                    } else {
                        n[p][0]("Loading...");

                        rr(
                            "http" + $ssl + "://" + window.$host + "/period",
                            {
                                symbol: r,
                                step: o
                            },
                            function() {
                                var e, c;

                                l = arguments[0];
                                a = arguments[1];

                                n[p][1]();

                                if (l) {
                                    (function() {
                                        n[p][0]("Load failed.");
                                        Kr(5000, function() {
                                            return n[p][1]();
                                        });
                                    })();

                                    return i(l);
                                } else {
                                    a = nr(a, o);

                                    s(n, r);

                                    ((e = n[v])[r] != null ? (e = n[v])[r] : (e[r] = {}))[o] = Br(o, a);
                                    ((c = n[g])[r] != null ? (c = n[g])[r] : (c[r] = {}))[o] = true;

                                    u();

                                    return;
                                }
                            }
                        );
                    }
                }

                function h(n, t, e) {
                    var r, o, i, u;

                    if ((o = (u = n[v]) != null ? u[t] : void 0)) {
                        for (i in o) {
                            if (so.call(o, i)) {
                                r = o[i];

                                Pr(r, e);
                            }
                        }

                        return this;
                    }
                }

                function d(n) {
                    var t;
                    var r = n[g];

                    for (t in r) {
                        if (r.hasOwnProperty(t)) {
                            r[t];

                            return true;
                        }
                    }

                    return false;
                }

                var p, v, b, g, F, x;

                x = m(4);

                b = x[0];
                v = x[1];
                g = x[2];
                p = x[3];

                F = 1;

                ce = n;
                ae = f;
                fe = c;
                le = h;
                he = r;
                de = a;
                se = d;

                u = b;
                i = v;
                l = g;

                return (o = p);
            }(),
            function() {
                // no #connection
                return $('#connection').hover(
                    function() {
                        var e = $(this);
                        var r = e.offset();
                        var n = $("#help_connection");

                        n.css({
                            left: r.left - (n.width() - e.width()) / 2,
                            top: r.top - n.outerHeight()
                        });

                        return n.show();
                    },
                    function() {
                        var n = $("#help_connection");
                        return n.hide();
                    }
                );
            }(),
            // performance settings
            function() {
                const container = $("#performance_settings");
                const cacheKey = "performance_settings_";
                const default_performance_settings = window.$default_performance_settings;

                if(default_performance_settings) {
                    for(let k in default_performance_settings) {
                        performanceSettings[k] = default_performance_settings[k];
                    }
                }

                const inputs = container.find("input");

                inputs.each(function(key, input) {
                    const el = $(this);
                    const name = el.attr("name");

                    el.change(function() {
                        const val = el.is(":checked");

                        performanceSettings[name] = val

                        localStorage.setItem(cacheKey + name, val)
                    });

                    if(localStorage.getItem(cacheKey + name) !== null) {
                        performanceSettings[name] = localStorage.getItem(cacheKey + name) === "true"
                    }

                    el.prop('checked', performanceSettings[name]);
                })
            }()
    }).call(this);
}).call(this);